import { SnapshotOut } from 'mobx-state-tree'
import { useStores } from '../../../../hooks/useStores'
import { FacilityModel } from '../../../../state/models/facility'

export const useUserReachedMaxBookings = (
  unitId: string,
  facility: SnapshotOut<typeof FacilityModel>
): boolean => {
  const { bookingsStore } = useStores()

  if (!facility) {
    return false
  }

  if (facility?.rules === 'unlimited') {
    return false
  }

  const futureFacilityBookings =
    bookingsStore.futureBookingsForUnitsAndFacility([unitId], facility._id)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const maxNumberOfBookings = facility?.getRuleAsInteger()

  return futureFacilityBookings.length >= maxNumberOfBookings
}
