import React from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { Link } from 'react-router-dom'
import { atcb_action as atcbAction } from 'add-to-calendar-button'
import { capitalize } from '../../../helpers/string'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { EventModel } from '../../../state/models/event'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../../common/Icon'
import {
  formatDateWithFormat,
  ignoreTimezoneDate,
  sameDay,
} from '../../../helpers/date'
import { Button, ButtonVariant } from '../../common/Button'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { useAddToCalendarConfig } from '../../../hooks/useAddToCalendarConfig'
import { EventAttendanceButtons } from '../../../views/society/EventDetailView/EventAttendanceButtons'

interface PostEventProps {
  event: SnapshotOut<typeof EventModel>
  disabled?: boolean
}

export const PostEvent = ({ event, disabled }: PostEventProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const config = useAddToCalendarConfig(event)
  const getDate = (startDate: string, endDate: string): string[] => {
    if (sameDay(new Date(endDate), new Date(startDate))) {
      return [startDate]
    }

    return [startDate, endDate]
  }

  const getDateString = (): string => {
    return capitalize(
      getDate(event.startDate, event.endDate)
        .map((_date) =>
          formatDateWithFormat(
            ignoreTimezoneDate(new Date(_date)),
            'eee d MMM yyyy'
          )
        )
        .join(' - ')
    )
  }

  const getTimeString = (): string => {
    if (event.isAllDay) {
      return translate('post.event.allDay')
    }

    return [event.startDate, event.endDate]
      .map((_date) =>
        formatDateWithFormat(ignoreTimezoneDate(new Date(_date)), 'HH:mm')
      )
      .join(' - ')
  }

  const onAddToCalendar = (): void => {
    atcbAction(config)
  }

  return (
    <div className="flex flex-col gap-4">
      <p style={theme.textVariants.lead} className="break-words">
        {translate('post.event.prefix')}: {event.title}
      </p>
      <div className="flex flex-col gap-3">
        <div className="flex items-start gap-2">
          <Icon icon={IconChoices.CALENDAR} />
          <p style={theme.textVariants.base}>{getDateString()}</p>
        </div>
        <div className="flex items-start gap-2">
          <Icon icon={IconChoices.CLOCK} />
          <p style={theme.textVariants.base}>{getTimeString()}</p>
        </div>
        {event.location && (
          <div className="flex items-start gap-2">
            <Icon icon={IconChoices.LOCATION} />
            <p style={theme.textVariants.base}>{event.location}</p>
          </div>
        )}
      </div>
      {event.attendanceEnabled && (
        <EventAttendanceButtons event={event} boardRoomEvent={false} />
      )}
      <div className="flex flex-wrap gap-3">
        <Link
          to={reverseUrl('society:calendar-detail', {
            id: event.societyId,
            eventId: event._id,
          })}
        >
          <Button
            label={translate('post.event.goToEvent')}
            variant={ButtonVariant.PRIMARY}
            disableTabIndex
            disabled={disabled}
          />
        </Link>
        <Button
          label={translate('post.event.addToCalendar')}
          onClick={onAddToCalendar}
          variant={ButtonVariant.DEFAULT}
          icon={IconChoices.CALENDAR}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
