/* eslint-disable max-len */
import Translation from './types'

const en: Translation = {
  common: {
    like: 'Like',
    likes: 'Likes',
    makeComment: 'Comment',
    comment: 'Comment',
    comments: 'Comments',
    settings: 'Settings',
    yes: 'Yes',
    no: 'No',
    options: 'Options',
    active: 'Active',
    in: 'in',
    time: 'Time',
    description: 'Description',
    profile: 'Profile',
    loading: 'Loading...',
    newFeature: 'New feature!',
    actions: {
      save: 'Save',
      edit: 'Edit',
      change: 'Change',
      delete: 'Delete',
      cancel: 'Cancel',
      send: 'Send',
      publish: 'Publish',
      update: 'Update',
      confirm: 'Confirm',
      create: 'Create',
      add: 'Add',
      addMore: 'Add more',
      remove: 'Remove',
      accept: 'Accept',
      deny: 'Deny',
      continue: 'Continue',
      leave: 'Leave',
      join: 'Join',
      skip: 'Skip',
      select: 'Select',
      done: 'Done',
      close: 'Close',
      show: 'Show',
      tryAgain: 'Try again',
      later: 'Later',
      logout: 'Log out',
      back: 'Back',
      invite: 'Invite',
      message: 'Message',
      upload: 'Upload',
      activate: 'Activate',
      copyText: 'Copy text',
      report: 'Report',
      order: 'Order',
      pay: 'Pay',
      book: 'Book',
      bookAsAdmin: 'Book as admin',
      saveChanges: 'Save changes',
      clear: 'Clear',
      download: 'Download',
    },
    form: {
      labels: {
        name: 'Name',
        surname: 'Surname',
        email: 'Email address',
        message: 'Message',
        entrance: 'Address',
        entranceSamf: 'Street',
        unitBrf: 'Apartment number',
        unitSamf: 'Property unit designation',
        unitRole: 'Role',
        active: 'Active',
        organisationNumber: 'Organisation number',
        reference: 'Reference',
        society: 'Society',
        password: 'Password',
        role: 'Role',
        addressCity: 'City',
        addressZip: 'Zip code',
        addressStreet: 'Address',
        addressCareOf: 'C/o',
        type: 'Type',
        from: 'From',
        to: 'To',
        discount: 'Discount',
        gender: 'Gender',
        phoneNumber: 'Phone number',
      },
      helperTexts: {
        calculatedAutomatically: 'Automatically calculated',
      },
      note: {
        required: '*Required',
        locked: 'Locked',
      },
    },
    fee: {
      annually: 'Annually',
      discountViaString: 'Discount via {{ partnerName }}',
    },
    boardDisabledFeature: 'The board has chosen to disable this feature.',
    copiedToClipboard: 'Copied',
    unit: {
      unassigned: 'Unassigned',
    },
    // TODO: Update translations
    emptyState: {
      title: 'Nothing to see here.',
      subtitle: 'There is currently nothing to display here!',
    },
    platform: {
      webapp: 'Web app',
      mobileApp: 'Mobile app',
    },
    eventActions: {
      attendance: {
        comment: 'Comment',
        attending: 'Attending',
        notAttending: 'Unable to attend',
        updateComment: 'Update comment',
      },
    },
    contactActions: {
      call: 'Call',
      email: 'Email',
      website: 'Website',
    },
    contact: {
      phoneNumber: 'Phone number',
      email: 'Email address',
      website: 'Website',
    },
    days: {
      singular: 'day',
      plural: 'days',
    },
    button: {
      save: 'Save',
      send: 'Send',
      delete: 'Delete',
      next: 'Next',
      previous: 'Previous',
      close: 'Close',
      addMore: 'Add more',
      toPayment: 'To payment',
    },
    callout: {
      namedSocietyLocked: {
        title: '{{ societyName }} is locked',
      },
    },
    other: 'Other',
    none: 'None',
    weekDays: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    languages: {
      english: 'English',
      swedish: 'Swedish',
    },
    prefixes: {
      you: 'You',
    },
    notificationSettings: {
      label: 'Notify community',
      labelBoard: 'Notify board',
      none: 'No',
      feed: 'Feed',
      notificationAndFeed: 'Notification & feed',
    },
    user: {
      deletedUserName: 'Deleted user',
      admin: 'Admin',
      masterAdmin: 'Master Admin',
    },
    numberOfunits: {
      apartmentBuilding: 'Number of apartments',
      boatClub: 'Number of members',
      economicAssociation: 'Number of properties',
      homeownersAssociatoon: 'Number of properties',
      housingCooperative: 'Number of apartments',
      housingCooperativeConstruction: 'Number of apartments',
      neighbourhood: 'Number of properties',
      nonProfitAssociation: 'Number of properties',
      shared: 'Number of users',
    },
    errorViews: {
      userNotInSociety: {
        title: 'Not a member of a society',
        subtitle:
          'Apply to join your society under "Society" to be able to chat with your neighbours.',
      },
      pendingRequest: {
        title: 'Awaiting approval from the board',
      },
    },
    singularPlural: {
      years: {
        singular: 'Year',
        plural: 'Years',
      },
      unit: {
        singular: 'Apartment',
        plural: 'Apartments',
        singularSamf: 'Property',
        pluralSamf: 'Properties',
      },
      like: {
        singular: 'Like',
        plural: 'Likes',
      },
      connected: {
        singular: 'connected',
        plural: 'connected',
      },
      person: {
        singular: 'Person',
        plural: 'People',
      },
    },
    terms: {
      user: 'User Agreement',
      service: 'Terms of Service',
      dataProcessing: 'Data Processing Agreement',
    },
  },
  blockedCallout: {
    message:
      'You do not have permission to publish content in {{ societyNames }}.',
  },
  societyType: {
    housingCooperative: 'Housing cooperative',
    housingCooperativeConstruction: 'Project (real-estate developer)',
    homeOwnersAssociation: 'Homeowners association',
    nonProfitAssociation: 'Non-profit association',
    economicAssociation: 'Economic association',
    neighbourhood: 'Neighbourhood',
  },
  serviceView: {
    title: 'Offers',
    subtitle: "Here you can find advantageous offers from Boappa's partners",
  },
  accordionSection: {
    defaultItemsEmptyText: 'This section is empty',
  },
  select: {
    noOptions: 'No options',
  },
  offlineView: {
    title: 'You are offline.',
    subtitle:
      'Check your internet connection and try again by refreshing the page.',
  },
  notFoundView: {
    title: "Oops! You've visited a page that does not exist.",
    subtitle: 'Sorry about that. Please go back and try again.',
    buttonLabel: 'Go back',
  },
  unauthorizedView: {
    title: 'You do not have the permission to view this page.',
    subtitle:
      'This is because you do not have the required role to view the content.',
    buttonLabel: 'Go back',
  },
  chatView: {
    title: 'Chat',
  },
  chatMessageReadBy: {
    readBy: 'Read by',
    modalTitle: 'Message read by',
  },
  societyInformationView: {
    title: 'Information about society',
    formSections: {
      societyPicture: 'Society picture',
      societyTitle: 'Society',
      addressTitle: 'Address',
    },
    labels: {
      updateSocietyPicture: 'Update picture',
    },
  },
  societyContractsView: {
    title: 'Contracts',
    emptyState: {
      title: 'No contracts',
      subtitle: 'No contracts have been added to society.',
      noSearchResultsTitle: 'No contracts',
      noSearchResultsSubtitle:
        'No search results. Change your search string and search again.',
    },
  },
  societyContractDetailView: {
    emptyState: {
      title: 'Contract could not be found',
      subtitle: 'You tried to access a contract that does not exist.',
    },
  },
  societyStatisticsExportsView: {
    title: 'Statistics & Exports',
    tabBar: {
      statistics: 'Statistics',
      exports: 'Exports',
    },
    statistics: {
      headerTitle: 'Activity',
      numberLogins: 'Total logged in users',
      numberPosts: 'Total feed posts',
      numberLikes: 'Total feed likes',
      numberComments: 'Total feed comments',
    },
    exports: {
      residentList: {
        headerTitle: 'Residents',
        residentListDownload: 'Resident list',
      },
      bookingsList: {
        headerTitle: 'Facility bookings',
      },
    },
  },
  contactsView: {
    title: 'Contacts',
    emptyState: {
      title: 'No contacts',
      subtitle: 'No contacts have been added to society.',
      noSearchResultsTitle: 'No contacts',
      noSearchResultsSubtitle:
        'No search results. Change your search string and search again.',
    },
  },
  societyView: {
    title: 'Society',
    emptyState: {
      noSociety: {
        title: 'Missing society',
        subtitle:
          'You are not connected to a society. Get started with Boappa by finding your society.',
        buttonTitle: 'Find society',
      },
      pendingRequest: {
        title: 'Pending request',
        subtitle:
          'Here you will be able to view information related to your society.',
        buttonTitle: 'Find society',
      },
    },
  },
  residentListView: {
    title: 'Residents',
    joined: 'Joined',
  },
  adminRightsView: {
    title: 'Admin rights',
    makeMasterAdminTitle: 'Make Master Admin',
    flashMessage: {
      adminRemoveSuccess: 'Admin removed',
      adminRemoveFailure: 'Admin could not be removed',
      masterAdminChangedSuccess: 'Master Admin changed',
      masterAdminChangedFailure: 'Master Admin could not be changed',
    },
    alert: {
      deleteAdminTitle: 'Delete admin',
      deleteAdminText: 'Are you sure you want to delete the admin?',
      updateMasterAdminTitle: 'Update master admin',
      updateMasterAdminText:
        'Are you sure you want to change the master admin?',
    },
    emptyState: {
      title: 'No admins',
      subtitle: 'There are currently no assigned admins',
      noSearchResultsTitle: 'No admins',
      noSearchResultsSubtitle:
        'No search results. Change your search string and search again.',
    },
  },
  boardContactsView: {
    title: 'Board contacts',
    emptyState: {
      title: 'No contacts',
      subtitle: 'No contacts have been added to society.',
      noSearchResultsTitle: 'No contacts',
      noSearchResultsSubtitle:
        'No search results. Change your search string and search again.',
    },
  },
  boardCalendarView: {
    title: 'Board events',
    emptyState: {
      title: 'No events',
      subtitle: 'No events have been added.',
    },
  },
  contactCard: {
    flashMessage: {
      deleteContactSuccess: 'Contact deleted',
      deleteContactFailure: 'Contact could not be deleted',
    },
    alert: {
      deleteContactTitle: 'Delete contact',
      deleteContactText: 'Are you sure you want to delete the contact?',
    },
    call: 'Phone:',
    email: 'Email:',
    website: 'Website:',
  },
  marketplaceListView: {
    title: 'Marketplace',
    subtitle: 'Buy, sale, rent or borrow',
    emptyState: {
      title: 'No listing created yet',
      subtitle:
        'Add a listing to get the marketplace started. You can remove your listing easily if you change your mind.',
      subtitleMissingUnit:
        'To create a listing you need to belong to a household in the society.',
    },
    noListingFound: {
      title: 'No listing matched your search',
      subtitle:
        'Try another keyword or remove your search to browse all listings.',
    },
    buttons: {
      createListing: 'New listing',
    },
    item: {
      free: 'Free',
    },
    flashMessage: {
      deleteResourceSuccess: 'Listing deleted',
      deleteResourceFailure: 'Listing could not be deleted',
    },
  },
  postView: {
    errorView: {
      removed: {
        title: 'Post removed.',
        subtitle:
          'The post has been removed. Click on the button above to return to the feed.',
      },
    },
  },
  post: {
    important: 'Important message',
    importantMobile: 'Important',
    showMoreComments: 'Show more comments',
    commentInputPlaceholder: 'Write a comment...',
    editedPrefix: 'Edited',
    event: {
      prefix: 'Event',
      allDay: 'All day',
      goToEvent: 'Go to event',
      addToCalendar: 'Add to calendar',
    },
    alert: {
      postDeleteTitle: 'Delete post',
      postDeleteText:
        'Are you sure you want to delete the post? This action can not be undone and the post will be deleted for all users in the society.',
      commentDeleteTitle: 'Delete comment',
      commentDeleteText: 'Are you sure you want to delete the comment?',
    },
    flashMessage: {
      deletePostSuccess: 'Post deleted',
      deletePostFailure: 'Post could not be deleted',
      deleteCommentSuccess: 'Comment deleted',
      deleteCommentFailure: 'Comment could not be deleted',
    },
    newMarketPlaceItemText: 'New marketplace listing',
    newFacilityText: 'A new facility has been added',
    newContactText: 'A new contact has been added',
    newLocalOfferText: 'A new local offer has been added',
    tooltip: {
      visibility: {
        base: 'Post visible for',
        admins: 'administrators',
        board: 'board members',
        groups: 'groups',
      },
    },
    seenBy: 'Seen by',
  },
  previewPost: {
    title: 'Preview post',
    preview: 'Preview',
  },
  pendingRequest: {
    title: 'Great!',
    subtitle:
      'You get access to the society when the board accepts your request.',
    titleAdvice: 'Tip: You can already check out the web app.',
    whatHappensNextTitle: 'What happens next?',
    notificationText:
      'When your request is accepted, you will get a notification in the web app and an email. \nFollow the instructions in the email or open the web app and start to Boappa!',
    assignmentText:
      'If your request is not accepted - contact the board of your society.',
    checkOutTheApp: 'Check out the web app!',
  },
  requestJoinSocietyForm: {
    sendRequest: 'Send request',
    titleBrf: 'Choose your apartment',
    titleSamf: 'Choose your property',
    flashMessage: {
      sendRequestSuccess: 'Request sent',
      sendRequestFailure: 'Request could not be sent',
    },
    tooltip: {
      unit: 'The 4 first numbers in your apartment number is given by Lantmäteriet \n\nIf the numbers are inside parenthesis ( ), your society has entered the number',
      role: 'Member: You who owns the condominium and therefore is a member of the society \nLodger: If you are for example live-in partner, partner, friend, lodger, child to the owner of the apartment, \nTenant: you are renting the apartment secondarily. \nRelative: Person that represents the member.',
    },
  },
  findSociety: {
    labels: {
      newSociety: 'New society',
      userMemberOfSociety: 'Logged in',
      hasPendingRequest: 'Pending request',
      search: 'Search for name, address or org.nr',
      brfDeveloper: 'Real-estate developer?',
      cannotFindSociety:
        'Are you not able to find your society or do you want to add a new neighbourhood?',
    },
  },
  inactiveSociety: {
    title: 'The society is not active',
    subtitle:
      'You have to be a board member to activate the society. You can join the society as a member to test, but you will not have access to all the functions in the app.',
    tabs: {
      board: 'Board member',
      member: 'Member',
    },
    board: {
      boardTitle: 'Option 2: Join as board member',
      boardSubtitle:
        'Sitter du i styrelsen men önskar att ej bli administratör kan du välja att enbart gå med i föreningen. Du kommer inte ha tillgång till all funktionalitet i appen.',
      adminTitle: 'Option 1: Join as administrator',
      adminSubtitle:
        'Activate your society and get full functionality in the app. You are then assigned the role main administrator for your society and you can subsequently assign more administrators.',
    },
    member: {
      title: 'Join society',
      subtitle:
        'The society is not yet activated, but feel free to join it regardless if you are a member or board member.',
    },
    flashMessage: {
      joinSocietySuccess: 'Joined society',
      joinSocietyFailure: 'Could not join society',
      activateSocietyFailure: 'Society could not be activated',
      activateSocietySuccess: 'Society activated',
    },
  },
  findSocietyConfirmActivationForm: {
    roleTitle: 'What is your role in the board?',
    inviteBoardMembersTitle:
      'Invite your colleagues in the board to test Boappa together (optional).',
    numberOfApartmentsTitle: 'Total amount of apartments',
    numberOfApartmentsTitleSamf: 'Total amount of properties',
    numberOfApartmentsDescription:
      'Add the total number of apartments in the society. Make sure you include commercial spaces and apartments for rent.',
    numberOfApartmentsDescriptionSamf:
      'Add the total number of properties in the society. Make sure you include commercial properties for rent.',
    enterEmail: 'Enter email address',
    flashMessage: {
      invitesSentSuccess: 'Invites sent',
    },
  },
  createSocietyView: {
    title: 'Create new society',
    tabs: {
      housingCooperative: 'The board in my housing cooperative',
      homeownersAssociation: 'The board in my homeowners association',
      realEstateDeveloper: 'Real-estate developer',
    },
  },
  createSocietyForm: {
    createSocietyTitle: 'Create society',
    realEstateDeveloperTitle: 'Create new society',
    neighbourhoodTitle: 'Neighbourhood',
    samfTitle: 'Create your homeowners association',
    brfTitle: 'Create your housing cooperative',
    societyOrProjectTitle: 'Society/project',
    societyNameTooltipTitle: 'Standard abbreviations',
    societyNameTooltipText:
      'Anläggningssamfällighet = As\nBostadsförening = Bf\nBostadsrättsförening = Brf\nEkonomisk förening = Ek för\nGemensamhetsanläggning = Ga\nSamfällighetsförening = Samf\nUtan personligt ansvar = Upa\nVägsamfällighet = Vägsam\nVägförening = Vägf',
    headers: {
      realEstateDeveloper: 'Real-estate developer',
      society: 'Society/project',
      societyAlreadyExists: 'Your society already exists in Boappa',
    },
    labels: {
      numberOfBillingUnits: 'Number of apartments',
      numberOfBillingUnitsSamf: 'Number of properties',
      societyName: 'Society name',
    },
    errors: {
      societyAlreadyActivated: 'Society is already activated',
    },
    modal: {
      title: 'Your society already exists in Boappa',
      organisationNumberShort: 'Org nr',
      alreadyMemberOfSociety: 'You are already a member of this.',
      societyExistsButInactive: 'Society exists but is inactive',
    },
    flashMessage: {
      createSocietySuccess: 'Society has been created',
      createSocietyFailure: 'Society could not be created',
    },
  },
  findSocietyConfirmActivationView: {
    title: 'Soon done!',
    subtitle:
      'We need some more information from you before you can start to use Boappa.',
  },
  activatedSocietyConfirmationView: {
    title: 'The society is activated!',
    subtitle:
      'You are now registered as main administrator and can invite the board members to get started with Boappa.',
    buttonTitle: 'Börja Boappa!',
  },
  findSocietyView: {
    title: 'Add new society',
  },
  findFriendsView: {
    title: 'Get to know your neighbours',
    description:
      'Find a workout buddy from the house next door or have company during trolley walks with your neighbour down the road.',
    extra:
      'Fill in your interests in your profile and see which neighbours share them.',
    updateYourProfile: 'Update your profile',
    errorView: {
      noMatches: {
        title: 'You have no matches',
        subtitle:
          'Update your profile to find people in your society with common interests',
      },
    },
  },
  functionSettingsView: {
    title: 'Functions',
    subtitle:
      'Enable or disable functions displayed under the "for residents" in the sidebar.',
    table: {
      titles: {
        function: 'Function',
        visible: 'Displayed for residents',
      },
    },
    buttons: {
      addFunction: 'Add function',
    },
    form: {
      labels: {
        function: 'Function',
        title: 'Title',
        link: 'Link',
        content: 'Content',
      },
      widgetTypeOptions: {
        link: 'Link',
        text: 'Text',
      },
    },
    modal: {
      createTitle: 'Create function',
      editTitle: 'Edit function',
    },
    confirmationModal: {
      deleteTitle: 'Delete function',
      deleteDescription: 'Are you sure you want to delete the function?',
    },
    flashMessage: {
      deleteWidgetSuccess: 'Function removed',
      deleteWidgetFailure: 'Function could not be removed',
      updateWidgetSuccess: 'Function updated',
      updateWidgetFailure: 'Function could not be updated',
      createWidgetSuccess: 'Function created',
      createWidgetFailure: 'Function could not be created',
    },
  },
  societyBlockedUsersView: {
    title: 'Blocked users',
    subtitle: `When a user is blocked, they are restricted to read-only access. This means they can view content but are unable to create new posts, comment on existing posts or use the chat.
      
This action should be reserved for exceptional cases, and a dialogue with the user should precede the decision.`,
    flashMessage: {
      userBlockAddSuccess: 'Block added',
      userBlockAddFailure: 'Block could not be added',
      userBlockRemoveSuccess: 'Block removed',
      userBlockRemoveFailure: 'Block could not be removed',
    },
    alert: {
      deleteBlockTitle: 'Delete block',
      deleteBlockText: 'Are you sure you want to delete the block?',
    },
    emptyState: {
      title: 'No blocks',
      subtitle: 'There are currently no blocked users',
    },
  },
  societySettingsView: {
    title: 'Settings',
    feedSettings: {
      title: 'Feed options',
      disablePostingTitle: 'Disable resident posts and comments',
      disablePostingDisclaimerText:
        'This option should only be considered in rare circumstances since it is hindering your residents from constructive and value adding communication.',
      marketplaceDisablePostingTitle:
        'Disable posts and notification for marketplace',
    },
    chatSettings: {
      title: 'Chat options',
      disableOneOnOneTitle: 'Disable one on one chat feature',
      disableInterestsTitle: 'Disable group chat feature',
      disableAskBoardTitle: 'Disable "Ask board" feature',
      chatAskBoardDisabledResidentMessageTitle: 'Message to residents',
      chatAskBoardDisabledResidentMessagePlaceholder:
        'Please contact the board via email at info@...',
    },
    updateSettingsSuccess: 'Updated the settings',
  },
  supportView: {
    title: 'Support',
  },
  societyWebsiteView: {
    title: 'Our website',
    subTitle:
      'The information you enter here will be shown on your public society website. It is you as a society that are responsible for the content. Read more ',
    subTitleLinkText: 'here',
    visitWebsite: 'Visit website',
    tabBar: {
      generalInformation: 'Name and description',
      images: 'Images',
      documents: 'Documents',
      questionsAndAnswers: 'Questions and answers',
      contact: 'Contact',
    },
  },
  societyWebsiteGeneralInformation: {
    form: {
      displayName: 'Name',
      description: 'Presentation',
    },
    flashMessage: {
      updateSuccess: 'The information about the website was updated',
      updateFailure: 'The information about the website could not be updated',
    },
  },
  societyWebsiteContact: {
    form: {
      recipientId: 'Board member',
      recipientLabel: 'Recipient',
      addMore: 'Add more',
      copy: 'Copy',
      copyTooltip:
        'Here you can add the email addresses that should be sent a copy from the contact form on the website',
    },
    flashMessage: {
      updateSuccess: 'Contact updated',
      updateFailure: 'Contact could not be updated',
    },
    confirmationModal: {
      removeEmailTitle: 'Delete email address',
      removeEmailText: 'Are you sure you want to delete the email address?',
    },
  },
  societyInviteModal: {
    title: 'We have found your home!',
    buttons: {
      accept: "Yes, that's correct!",
      deny: 'Decline invite',
    },
    flashMessage: {
      acceptPreApprovedSuccess: 'Society has been added',
      acceptPreApprovedFailure: 'Society could not be added',
      denyPreApprovedSuccess: 'Invite declined',
      denyPreApprovedFailure: 'Invite could not be declined',
    },
  },
  societyWebsiteQuestionsAndAnswers: {
    flashMessage: {
      updateSuccess: 'Questions and answers updated',
      updateFailure: 'Questions and answers could not be updated',
    },
  },
  societyWebsite: {
    showOnWebsite: 'Show on website',
  },
  societyWebsiteDocuments: {
    addNewDocument: 'Upload document',
    addNewFolder: 'Create new folder',
    emptyState: {
      title: 'You have nothing to read',
      emptyFolderSubtitle:
        'Right now there are no documents published in "{{folderName}}".',
      noDocumentsSubtitle:
        'Right now there are no published folders or documents.',
    },
  },
  supportContact: {
    infoText: 'Do you have a question or a suggestion? Send us a message!',
    form: {
      subject: 'Subject',
      subjectPlaceholder: 'Describe your matter with a short subject',
      message: 'Message',
      messagePlaceholder: 'Describe your matter',
      send: 'Send message',
      whichPlatform: 'Which platform did you use when the problem occurred?',
    },
  },
  bostadsratterna: {
    bostadsratternaBenefitsView: {
      title: 'Medlemsförmåner',
      benefitsTitle: 'Medlemsförmåner',
      benefitsText:
        'För dig som medlem ingår en rad olika medlemsförmåner hos oss (inloggning krävs).',
    },
    bostadsratternaSchoolView: {
      title: 'Bostadsrättsskolan',
      coursesTitle: 'Kurser',
      coursesText: 'Utbildningar om allt ni behöver veta om styrelsearbetet.',
      seminarsTitle: 'Seminarier',
      seminarsText: 'Kortare utbildningar för ditt uppdrag i föreningen.',
      webAndVideoCoursesTitle: 'Webb- och videokurser',
      webAndVideoCoursesText:
        'Nya interaktiva webbkurser som gör det kul och enkelt att lära.',
    },
    bostadsratternaStatutesView: {
      title: 'Mönsterstadgar',
      statutesTitle: 'Stadgemall',
      statutesText:
        'Som medlem får du fri tillgång till våra mönsterstadgar (inloggning krävs).',
    },
    bostadsratternaGuidanceView: {
      title: 'Styrelserådgivning',
      guidanceText: 'Kostnadsfri rådgivning vardagar 09:00-16:30',
    },
    bostadsratternaFormsView: {
      title: 'Blanketter',
      formsTitle: 'Praktiska blanketter',
      formsText:
        'Blanketter för avtal, ansökningar och annat som behövs i föreningen (inloggning krävs).',
    },
  },
  samfalligheterna: {
    samfalligheternaSchoolView: {
      title: 'Utbildningar',
      description:
        'Det finns en mängd detaljerade regler för en samfällighetsförening: om medlemsavgifter, hur beslut ska fattas och vad en samfällighet får ägna sig åt. Har ni fått nya personer i styrelsen, eller är osäkra på vad som gäller, är det en bra idé att gå någon av våra utbildningar.',
      coursesTitle: 'Kurser',
      coursesText:
        'På våra utbildningar lär du dig allt du behöver veta för att sköta en samfällighetsförening (inloggning krävs).',
    },
    samfalligheternaBenefitsView: {
      title: 'Förmåner och rabatter',
      description:
        'Vårt mål är att styrelsearbetet i din förening ska vara så smidigt och glädjefullt som möjligt. I kraft av vår storlek har vi fixat bra rabatter och förmåner hos några av Sveriges mest välkända företag. Så att ni kommer undan lite billigare när ni ska köpa något som hör föreningen till.',
      benefitsTitle: 'Förmåner och rabatter',
      benefitsText:
        'Exklusiva erbjudanden till föreningar med trygghetsavtal (inloggning krävs).',
    },
    samfalligheternaGuidanceView: {
      title: 'Styrelserådgivning',
      guidanceDescription:
        'Få kostnadsfri rådgivning hos våra jurister, lantmätare, byggnadstekniker, geotekniker, energi-, vvs- och trädgårdsrådgivare.',
      guidanceText:
        'Kostnadsfri rådgivning 08:30-16:30 måndag-torsdag, 08:30-14:00 fredag',
    },
  },
  managementPartnerContactForm: {
    writeQuestionTitle: 'Skriv din fråga',
    writeQuestionPlaceholder: 'Meddelande',
    sendQuestionTitle: 'Skicka fråga',
    shareInformationTitle: 'Dela information',
    shareInformationTooltipBostadsratterna:
      'Kontaktuppgifter till dig och din förening delas med bostadsrätterna',
    shareInformationTooltipSamfalligheterna:
      'Kontaktuppgifter till dig och din förening delas med samfälligheterna',
    helperText: 'Vår målsättning är att svara inom 24 timmar.',
    messageSendFailure: 'Message could not be sent',
    messageSendSuccess: 'Message sent',
  },
  questionsAndAnswersView: {
    title: 'Questions & Answers',
    emptyState: {
      title: 'There are no questions and answers',
      subtitle: 'The board has not added any questions and answers yet.',
      noQuestionsAndAnswersText:
        'There are no questions and answers for this section yet.',
    },
  },
  societyCalendarListView: {
    title: 'Calendar',
    buttons: {
      create: 'New event',
    },
    activeTabBar: {
      previousTitle: 'Previous',
      upcomingTitle: 'Upcoming',
    },
    noCalendarEvents: {
      title: 'Lean back, there is nothing in the calendar.',
      subtitle: 'There are currently no calendar events.',
    },
  },
  eventDetailView: {
    errors: {
      notFound: {
        title: 'Calendar event could not be found',
        subtitle: 'You tried to access a calendar event that does not exist.',
      },
    },
    tabBar: {
      information: 'Information',
      attendance: 'Attendance',
    },
    headers: {
      date: 'Date',
      attendants: 'Attendants',
    },
    attendance: {
      titles: {
        attending: 'Attending',
        notAttending: 'Unable to attend',
        noReply: 'No reply',
      },
      list: 'Attendants',
    },
    buttons: {
      seeAllAttendants: 'See all attendants',
    },
  },
  createUpdateEvent: {
    addToCalendar: 'Add to calendar',
    modal: {
      createTitle: 'Create event',
      editTitle: 'Edit event',
    },
    confirmationModal: {
      deleteTitle: 'Delete event',
      deleteDescription: 'Are you sure you want to delete the event?',
    },
    form: {
      labels: {
        title: 'Activity',
        location: 'Location',
        isAllDay: 'All day',
        startDate: 'Start time',
        endDate: 'End time',
        startDay: 'Start day',
        endDay: 'End day',
        description: 'Description',
        attendanceEnabled: 'Attendance',
      },
    },
    flashMessage: {
      updateEventSuccess: 'Event updated',
      updateEventFailure: 'Event could not be updated',
      createEventSuccess: 'Event created',
      createEventFailure: 'Event could not be created',
      deleteEventSuccess: 'Event deleted',
      deleteEventFailure: 'Event could not be deleted',
    },
  },
  facilitiesView: {
    title: 'Bookings & facilities',
    tabBar: {
      newBooking: 'New booking',
      myBookings: 'My bookings',
    },
    emptyState: {
      noFacilitiesTitle: 'There is nothing to book',
      noFacilitiesSubtitle:
        'The board has not added any common facilities for digital booking. If you have any suggestions on areas or items that are bookable, feel free to inform the board via the chat and the tab "Ask board".',
      noBookingsTitle: 'You have no bookings',
      noBookingsSubtitle:
        'Go to "New Booking" och choose the facility you want to book. Thereafter your booking will appear here.',
    },
    buttons: {
      create: 'Create facility',
    },
  },
  facilityView: {
    tabBar: {
      calendar: 'Calendar',
      information: 'Information',
      bookings: 'Bookings',
    },
    calendar: {
      sections: {
        chooseUnit: {
          title: 'Choose apartment',
          titleSamf: 'Choose property',
        },
        chooseDate: {
          title: 'Choose date',
        },
        chooseSlot: {
          title: 'Choose time',
        },
        bookingMessage: {
          title: 'Booking message',
        },
        yourBooking: {
          title: 'Your bookings',
        },
      },
      fullDay: 'Full day',
      booked: 'Booked',
      alert: {
        unitMaxNumberBookings:
          'You have reached the active booking limit for the facility.',
        noTimeSlots: 'There are no available time slots for the chosen day.',
        noUnit: 'You must be assigned to an apartment to create a booking.',
        noUnitSamf: 'You must be assigned to a property to create a booking.',
        bookingDisabledText: 'The facility can not be booked.',
      },
      flashMessage: {
        createBookingSuccess: 'Booking created',
        createBookingFailure: 'Booking could not be created',
      },
    },
    information: {
      sections: {
        description: {
          title: 'Description',
          noDescriptionText: 'The facility has no description.',
        },
      },
    },
    bookings: {
      buttons: {
        previousMonth: 'Previous month',
        nextMonth: 'Next month',
        deleteBooking: 'Delete booking',
      },
      errorView: {
        noBookings: {
          title: 'There are no bookings',
          subtitle: 'Go to the "Calendar" tab to book this facility.',
        },
      },
      alert: {
        deleteTitle: 'Delete booking',
        deleteDescription: 'Are you sure you want to delete the booking?',
        deleteOtherUserBookingDescription:
          'Are you sure you want to delete booking created by {{ name }}?',
        deleteBookingButtonLabel: 'Delete booking',
      },
      flashMessage: {
        deleteBookingFailure:
          'Booking could not be removed. Time might be in the past',
        deleteBookingSuccess: 'Booking removed',
      },
    },
  },
  createUpdateFacility: {
    editFacilityTitle: 'Edit facility',
    createFacilityTitle: 'Create facility',
    form: {
      labels: {
        booking: 'Can be booked',
        coverPhoto: 'Cover photo',
        name: 'Title',
        description: 'Description',
        bookingGranularity: 'Booking interval',
        rules: 'Rules',
        bookableFrom: 'Bookable from',
        bookableTo: 'Bookable until',
        bookableUntilFurtherNotice: 'Until further notice',
        visibility: 'Visibility',
        bookingMessage: 'Booking message',
        notificationEmails: 'New booking and cancel notifications',
        blockedDates: 'Blocked dates',
        weeklySchedule: 'Weekly schedule',
      },
      helperText: {
        coverPhoto: 'Recommended minimum image size: 1280x800 pixels',
      },
      headers: {
        general: 'General',
        details: 'Booking details',
        notificationSettings: 'Notification settings',
        recipient: 'Mottagare',
        cc: 'Kopia',
        day: 'Day',
        startTime: 'Start',
        endTime: 'End',
        active: 'Active',
      },
      warnings: {
        bookingGranularity: {
          cantEdit:
            'You can not change the booking schedule when the facility has active bookings.',
        },
      },
      options: {
        visibility: {
          all: 'All',
          adminOnly: 'Only admins',
        },
        bookingGranularity: {
          oneHour: 'One hour',
          twoHours: 'Two hours',
          threeHours: 'Three hours',
          fourHours: 'Four hours',
          fiveHours: 'Five hours',
          fullDay: 'Entire day',
        },
        rules: {
          oneActive: 'One active booking',
          twoActive: 'Two active bookings',
          threeActive: 'Three active bookings',
          fourActive: 'Four active bookings',
          fiveActive: 'Five active bookings',
          unlimited: 'Unlimited active bookings',
        },
      },
    },
    flashMessage: {
      updateFacilityFailure: 'Facility could not be updated',
      updateFacilitySuccess: 'Facility updated',
      createFacilityFailure: 'Facility could not be created',
      createFacilitySuccess: 'Facility created',
      deleteFacilitySuccess: 'Facility removed',
      deleteFacilityFailure: 'Facility could not be removed',
    },
    confirmationModal: {
      deleteTitle: 'Delete facility',
      deleteDescription: 'Are you sure you want to delete the facility?',
    },
    weeklyScheduleDescription:
      'Press the numbers in the schedule to change start time or end time. To activate full day bookings, change the schedule to 00:00 - 00:00.',
  },
  houseRulesView: {
    title: 'House rules',
    emptyState: {
      title: 'There are no house rules',
      subtitle: 'When the board adds new rules they will show up here.',
    },
  },
  reportProblemView: {
    title: 'Report a problem',
    form: {
      labels: {
        space: 'Where has the problem occured?',
        message: 'Describe the problem',
        images: 'Images',
      },
    },
    flashMessage: {
      createReportSuccess: 'Report sent',
      createReportFailure: 'Report could not be sent',
    },
    helperTexts: {
      contactInformation: 'Your contact details are automatically attached.',
    },
    errorView: {
      title: 'Email is missing for report',
      subtitle: 'Set an email to enable report',
    },
  },
  reportProblemSettings: {
    modal: {
      title: 'Settings',
    },
    form: {
      headers: {
        recipient: 'Recipient',
        cc: 'Copy',
      },
    },
  },
  localOffersView: {
    title: 'Local offers',
    emptyState: {
      title: 'No local offers',
      subtitle: 'Society has not added any local offers yet.',
    },
    modal: {
      create: 'Create local offer',
      update: 'Update local offer',
    },
    form: {
      labels: {
        benefit: 'Offer',
        body: 'Description',
        companyName: 'Company name',
      },
      placeholders: {
        discount: '20% discount',
      },
      headers: {
        contactDetails: 'Contact details',
      },
    },
    flashMessage: {
      createLocalOfferSuccess: 'Local offer created',
      createLocalOfferFailure: 'Local offer could not be created',
    },
  },
  localOfferView: {
    title: 'Local offer',
    confirmationModal: {
      deleteTitle: 'Delete local offer',
      deleteDescription: 'Are you sure you want to delete the local offer?',
    },
    errors: {
      notFound: {
        title: 'Local offer could not be found',
        subtitle: 'You tried to access a local offer that does not exist.',
      },
    },
    flashMessage: {
      updateLocalOfferSuccess: 'Local offer updated',
      updateLocalOfferFailure: 'Local offer could not be updated',
      deleteLocalOfferSuccess: 'Local offer deleted',
      deleteLocalOfferFailure: 'Local offer could not be deleted',
    },
  },
  marketplaceDetailView: {
    labels: {
      published: 'Published',
      createdBy: 'Created by',
    },
    confirmationModal: {
      deleteTitle: 'Delete listing',
      deleteDescription: 'Are you sure you want to delete the listing?',
    },
    errors: {
      notFound: {
        title: 'Listing could not be found',
        subtitle: 'You tried to access a listing that does not exist.',
      },
    },
    flashMessage: {
      updateListingSuccess: 'Listing updated',
      updateListingFailure: 'Listing could not be updated',
      deleteListingSuccess: 'Listing deleted',
      deleteListingFailure: 'Listing could not be deleted',
    },
  },
  createUpdateHouseRuleForm: {
    labels: {
      subject: 'Subject',
      rule: 'Rule',
    },
  },
  invoiceView: {
    title: 'Invoicing',
    subtitle: 'Manage your subscription and payment details',
    tabBar: {
      payment: 'Payment',
      details: 'Details',
    },
    neighbourhood: {
      title: 'Neighbourhoods use Boappa for free!',
    },
    details: {
      title: 'Billing Details',
      alert: 'Invoices are sent via email',
      form: {
        labels: {
          recipient: 'Recipient',
          billingStreet: 'Invoice address',
        },
      },
    },
    basic: {
      remember: 'Remember!',
      payYourAnnualFee: 'Pay your annual fee',
      locked: {
        title: 'Society is locked.',
        subtitle: 'Pay your annual fee to continue using Boappa.',
      },
    },
    premium: {
      great: 'Great!',
      annualFeePayed: 'Your society is active.',
      paymentPlan: 'Payment plan',
      invoiceSentTo: 'Invoice sent to',
      untilNextPayment: 'until next payment',
    },
    commonCard: {
      annualFee: 'Annual fee',
      excludingVat: 'Excluding VAT',
      buttons: {
        useCode: 'Use code',
      },
      missingEmail: {
        title: 'Missing email address.',
        description: 'Before paying you must',
        link: 'update your email address',
      },
    },
    accordions: {
      title: 'Related questions',
      premium: {
        sections: {
          discountCode: {
            title: 'Have you used a discount code?',
            text: 'The discount code is valid until further notice and as long as the collaboration with the partner is active.',
          },
        },
      },
      basic: {
        sections: {
          bostadsratterna: {
            title: 'Member of Bostadsrätterna',
            text: 'The current discount code is available when you log in on Bostadsrätternas website. The discount is provided as long as the society is a member of Bostadsrätterna.',
          },

          samfalligheterna: {
            title: 'Samfälligheterna',
            text: 'Send an email to samfalligheter@villaagarna.se to receive your discount code.',
          },
        },
      },
      common: {
        sections: {
          paymentInstructions: {
            title: 'Payment instructions',
            preLinkText: 'Read the guide with',
          },
          termsOfService: {
            title: 'Terms of Service',
            preTermsText: 'The service is delivered in accordance with our',
            preDataProcessingText: 'and',
          },
          cancelSubscription: {
            title: 'Cancel subscription',
            text: 'Contact support@boappa.se to cancel your subscription.',
          },
        },
      },
    },
    modals: {
      payment: {
        title: 'Payment',
      },
      discountCode: {
        title: 'Discount code',
        informationSection: {
          title: 'Where can I find my discount code?',
          textBrf:
            'To use Boappa with a discount the society must be a member of Bostadsrätterna. You can find your code when you log in on Bostadsrätternas website',
          textSamf:
            'To receive a 50% discount in Boappa, your society must have signed a Security Agreement with Samfälligheterna (a service from Villaägarna). Email samfalligheter@villaagarna.se to receive your discount code.',
        },
      },
    },
    forms: {
      payment: {
        sections: {
          invoiceDetails: 'Invoice details',
          orderInformation: 'Order information',
        },
        labels: {
          yearlyCost: 'Annual fee',
          validity: 'Validity',
        },
        cost: {
          totalCost: 'Total cost incl. VAT',
          vat: 'VAT',
        },
        agreement: {
          preUserAgreement: 'I represent my community and approve the',
          postUserAgreement: 'including attachment',
          userAgreement: 'User Agreement',
          termsOfService: 'Terms of Service',
          personalDataAgreement: 'Data Processing Agreement',
        },
        errors: {
          upgradeErrored:
            'Something went wrong when upgrading. Please contact support@boappa.se',
        },
      },
      discountCode: {
        labels: {
          discountCode: 'Discount code',
        },
        errors: {
          discountCode: 'Invalid discount code',
        },
        placeholders: {
          discountCode: 'Write the code here...',
        },
      },
    },
    flashMessage: {
      discountCodeApplied: 'Discount code applied',
      upgradeSuccess: 'Society upgraded',
    },
  },
  createUpdateHouseRule: {
    addNewHouseRuleTitle: 'Create new rule',
    editHouseRuleTitle: 'Edit rule',
    flashMessage: {
      updateHouseRuleSuccess: 'Rule updated',
      updateHouseRuleFailure: 'Rule could not be updated',
      createHouseRuleSuccess: 'Rule created',
      createHouseRuleFailure: 'Rule could not be created',
      deleteHouseRuleSuccess: 'Rule deleted',
      deleteHouseRuleFailure: 'Rule could not be deleted',
    },
    confirmationModal: {
      title: 'Delete house rule',
      description: 'Are you sure you want to delete the rule?',
    },
  },
  createUpdateQuestionsAndAnswers: {
    addNewQuestionTitle: 'Create new question',
    editQuestionTitle: 'Edit question',
    addNewSectionTitle: 'Create new section',
    editSectionTitle: 'Edit section',
    flashMessage: {
      updateQuestionSuccess: 'Question updated',
      updateQuestionFailure: 'Question could not be updated',
      createQuestionSuccess: 'Question created',
      createQuestionFailure: 'Question could not be created',
      deleteQuestionSuccess: 'Question deleted',
      deleteQuestionFailure: 'Question could not be deleted',
      updateSectionSuccess: 'Section updated',
      updateSectionFailure: 'Section could not be updated',
      createSectionSuccess: 'Section created',
      createSectionFailure: 'Section could not be created',
      deleteSectionSuccess: 'Section deleted',
      deleteSectionFailure: 'Section could not be deleted',
    },
    confirmationModal: {
      title: 'Delete question',
      description: 'Are you sure you want to delete the question?',
    },
    removeSectionConfirmationModal: {
      title: 'Delete section',
      description: 'Are you sure you want to delete the section?',
    },
  },
  createUpdateQuestionsAndAnswersItemForm: {
    labels: {
      question: 'Question',
      answer: 'Answer',
    },
  },
  createUpdateQuestionsAndAnswersSectionForm: {
    labels: {
      section: 'Title',
    },
  },
  feedView: {
    title: 'Feed',
    newPost: 'Write a post...',
    modalTitle: 'New post',
    create: 'New post',
    filterFeed: {
      title: 'Filter posts',
      subtitle: 'Choose what to see in your feed',
      primaryButtonTitle: 'Confirm',
      postsFilterTitle: 'Posts',
      postsFilterAllOptionValue: 'Show all',
      postsFilterBoardOptionValue: 'Board posts',
      postsFilterNeighboursOptionValue: 'Neighbour posts',
      postsFilterImportantOptionValue: 'Important messages',
      societiesFilterTitle: 'Filter societies',
      societiesFilterAllOptionValue: 'Show all',
    },
    emptyFilter: {
      title: 'No posts found',
      subtitle: 'Change your filter or create a new post',
    },
    emptyState: {
      noSociety: {
        title: 'Missing society',
        subtitle:
          'You are not connected to a society. Get started with Boappa by finding your society.',
        buttonTitle: 'Find society',
      },
      pendingRequest: {
        title: 'Pending request',
        subtitle:
          'Here you will be able to like, comment, create posts and take part of important information related to your society.',
        buttonTitle: 'Find society',
      },
      emptyFeed: {
        title: 'There are no posts in your feed',
        subtitle:
          'Here you will be able to like, comment, create posts and take part of important information related to your society.',
        buttonTitle: 'Create post',
      },
    },
    formatText: {
      title: 'Enter the following to format text',
      label: 'Text formatting',
      bold: 'Bold',
      italic: 'Italic',
      header: 'Header',
      bulletPoints: 'Bullet points',
      list: 'List',
      horizontalLine: 'Horizontal line',
    },
  },
  userView: {
    dateTitle: 'Member since',
    interestsTitle: 'Interests',
    educationTitle: 'Education',
    occupationTitle: 'Occupation',
    presentationTitle: 'About {{name}}',
  },
  registerView: {
    title: 'Register',
    upload: 'Upload',
    tabBar: {
      entrances: 'Addresses',
      entrancesSamf: 'Streets',
      unit: 'Apartment numbers',
      unitSamf: 'Properties',
      members: 'Members',
      nonMembers: 'Other users',
      groups: 'Groups',
    },
    unit: {
      labels: {
        entrance: 'Address',
        entranceSamf: 'Street',
        unit: 'Apartment number',
        unitSamf: 'Property',
        title: 'Apartment number (society)',
        titleSamf: 'Address',
        titleLegal: 'Apartment number (lantmäteriet)',
        titleLegalSamf: 'Property unit designation',
        floor: 'Floor',
      },
      warnings: {
        noResidents: 'There are no residents for this',
      },
    },
    group: {
      unitsSingular: 'apartment',
      unitsPlural: 'apartments',
      membersSingular: 'member',
      membersPlural: 'members',
    },
    emptyState: {
      member: {
        title: 'No members have been added.',
        noSearchResults: 'No search results found.',
      },
    },
    alert: {
      removeUserTitle: 'Remove resident',
      removeUserText: 'Do you want to remove {{ name }} from the society?',
    },
    flashMessage: {
      removeUserSuccess: 'User has been removed from society',
      removeUserFailure: 'User could not be removed from society',
    },
  },
  registerEntrance: {
    modal: {
      create: 'Create address',
      createSamf: 'Create street',
      update: 'Update address',
      updateSamf: 'Update street',
    },
    form: {
      labels: {
        addressStreet: 'Address',
        addressStreetSamf: 'Street',
      },
    },
    numberOfUnits: {
      brf: 'There are {{ number }} {{ unitText }} on this address',
      samf: 'There are {{ number }} {{ unitText }} on this street',
    },
    confirmationDialog: {
      deleteTitle: 'Delete address',
      deleteDescription: 'Are you sure you want to delete the address?',
      deleteSamfTitle: 'Delete street',
      deleteSamfDescription: 'Are you sure you want to delete the street?',
    },
    warnings: {
      unableDeleteWithUnits: 'Addresses with apartments can not be deleted.',
      unableDeleteWithUnitsSamf: 'Streets with properties can not be deleted.',
      unableToDeleteWithUnassigned:
        'Cannot remove this address. It contains the option for unassigned apartments, that can be used for users that do not have a given address',
      unableToDeleteWithUnassignedSamf:
        'Cannot remove this street. It contains the option for unassigned properties, that can be used for users that do not have a given property',
    },
    flashMessage: {
      createEntranceFailure: 'Address could not be created',
      createEntranceSuccess: 'Address created',
      updateEntranceFailure: 'Address could not be updated',
      updateEntranceSuccess: 'Address updated',
      deleteEntranceFailure: 'Address could not be removed',
      deleteEntranceSuccess: 'Address removed',
      createEntranceSamfFailure: 'Street could not be created',
      createEntranceSamfSuccess: 'Street created',
      updateEntranceSamfFailure: 'Street could not be updated',
      updateEntranceSamfSuccess: 'Street updated',
      deleteEntranceSamfFailure: 'Street could not be removed',
      deleteEntranceSamfSuccess: 'Street removed',
    },
  },
  preApproved: {
    title: 'Pending invites',
    subtitle: 'Here you can see all pending invites',
    buttons: {
      remindAllTitle: 'Remind all',
    },
    confirmationDialog: {
      deleteTitle: 'Revoke invite',
      deleteDescription: 'Do you want to revoke the invite?',
      remindTitle: 'Send reminder',
      remindDescription: 'Do you want to send an invite reminder?',
      remindAllTitle: 'Send reminder',
      remindAllDescription:
        'Do you want to send an invite reminder to all pending?',
    },
    flashMessage: {
      sendReminderFailure: 'Reminder could not be sent',
      sendReminderSuccess: 'Reminder sent',
      remindAllFailure: 'Reminders could not be sent',
      remindAllSuccess: 'All reminders sent',
      deletePreApprovedSuccess: 'Invite revoked',
      deletePreApprovedFailure: 'Invite could not be revoked',
    },
  },
  registerUnit: {
    modal: {
      create: 'Create apartment number',
      createSamf: 'Create property',
      update: 'Update apartment number',
      updateSamf: 'Update property',
      residents: 'Residents',
    },
    confirmationDialog: {
      deleteTitle: 'Delete apartment number',
      deleteDescription:
        'Are you sure you want to delete the apartment number?',
      deleteSamfTitle: 'Delete property',
      deleteSamfDescription: 'Are you sure you want to delete the property?',
    },
    flashMessage: {
      createUnitFailure: 'Apartment number could not be created',
      createUnitSuccess: 'Apartment number created',
      updateUnitFailure: 'Apartment number could not be updated',
      updateUnitSuccess: 'Apartment number updated',
      deleteUnitFailure: 'Apartment number could not be removed',
      deleteUnitSuccess: 'Apartment number removed',
      createUnitSamfFailure: 'Property could not be created',
      createUnitSamfSuccess: 'Property created',
      updateUnitSamfFailure: 'Property could not be updated',
      updateUnitSamfSuccess: 'Property updated',
      deleteUnitSamfFailure: 'Property could not be removed',
      deleteUnitSamfSuccess: 'Property removed',
    },
  },
  registerRequest: {
    modal: {
      title: 'Member requests',
    },
    flashMessage: {
      approveRequestSuccess: 'Request approved',
      approveRequestFailure: 'Request could not be approved',
      denyRequestSuccess: 'Request denied',
      denyRequestFailure: 'Request could not be denied',
    },
  },
  registerExcelImport: {
    modal: {
      entranceUnitTitle: 'Apartment register',
      entranceUnitTitleSamf: 'Property register',
      memberTitle: 'Member register',
      entranceUnitSubtitle: 'Update apartment register',
      entranceUnitSubtitleSamf: 'Update property register',
      entranceUnitDescription:
        'The best way to update many apartments at once is to download the register, make changes in the excel sheet, and upload it again to Boappa.',
      entranceUnitDescriptionSamf:
        'The best way to update many properties at once is to download the register, make changes in the excel sheet, and upload it again to Boappa.',
      memberSubtitle: 'Update member register',
      memberDescription:
        '1. Download the file and populate member names and email addresses\n\n2. Upload the excel file and press Save\n\n3. The members will be invited to the society automatically and connected to their respective units. Members who are already invited will not receive a new invite',
    },
    flashMessage: {
      uploadRegisterSuccess: 'Register uploaded',
      uploadRegisterFailure: 'Register could not be uploaded',
    },
  },
  registerInvite: {
    modal: {
      inviteMember: 'Invite member',
      inviteNonMember: 'Invite user',
    },
    form: {
      unit: 'Apartment number',
      unitSamf: 'Property',
    },
    flashMessage: {
      inviteSentFailure: 'Invite could not be sent',
      inviteSentSuccess: 'Invite sent',
    },
  },
  registerGroup: {
    modal: {
      create: 'Create group',
      update: 'Update group',
    },
    form: {
      labels: {
        name: 'Group name',
      },
      options: {
        type: {
          unit: 'Apartment',
          unitSamf: 'Property',
          user: 'User',
        },
      },
    },
    confirmationDialog: {
      deleteTitle: 'Delete group',
      deleteDescription: 'Are you sure you want to delete the group?',
      changeTypeTitle: 'Change type',
      changeTypeDescription:
        'Choices for current group type will be deleted. Are you sure you want to update the group type?',
    },
    flashMessage: {
      deleteSuccess: 'Group deleted',
      deleteFailure: 'Group could not be deleted',
      createSuccess: 'Group created',
      createFailure: 'Group could not be created',
      updateSuccess: 'Group updated',
      updateFailure: 'Group could not be updated',
    },
  },
  gdprView: {
    title: 'GDPR',
    content:
      'På Boappa håller vi dataskydd och personlig integritet högt. Vi arbetar ständigt för att våra användares personuppgifter hanteras på ett korrekt sätt och vi hjälper er som förening att skydda era medlemmars personuppgifter. \n\nDet legala ramverket för personuppgiftsbehandling är GDPR (dataskyddsförordningen). \n\nNedan finner ni information, tips och kontaktuppgifter till Boappas dataskyddsansvarig.',
    moreAbout: {
      title: 'Mer om GDPR',
    },
    personalDataAdvice: {
      title: 'Tips för er personuppgiftsbehandling',
    },
    boappaVendor: {
      title: 'Underleverantörer',
      description:
        'Det här är en lista över de underleverantörer som Boappa AB använder för att leverera tjänsten Boappa. Samtliga underleverantörer har anpassat sig för att följa GDPR.',
      vendors: {
        exoscale: {
          name: 'Exoscale',
          description: 'molntjänst för lagring av data med server inom EU/EES.',
        },
        billogram: {
          name: 'Billogram',
          description: 'tjänst som används för att skicka fakturor.',
        },
        mailchimp: {
          name: 'Mailchimp',
          description: 'tjänst för utgående mejlutskick.',
        },
        mixpanel: {
          name: 'Mixpanel',
          description:
            'statistiktjänst som används för att mäta aktiviteten i appen och förbättra tjänsten.',
        },
        firebase: {
          name: 'Google Firebase',
          description:
            'tjänst som används för att skicka notiser i mobilappen.',
        },
        cloudflare: {
          name: 'Cloudfare',
          description:
            'tjänst som används leverera innehåller i tjänsten till appen.',
        },
      },
    },
    dataProtectionOfficer: {
      title: 'Dataskyddsansvarig',
    },
  },
  notifications: {
    title: 'Notifications',
    emptyState:
      'This is where notifications of what is happening in the app show up. Stay tuned!',
    newGroupChat:
      'The group chat {% roomName %} has been created. Check it out.',
    newPost: `{% userName %} posted: "{% message %}"`,
    newComment: '{% userName %} commented on post: "{% comment %}"',
    newCommentYourPost:
      '{% userName %} commented on your post: "{% comment %}"',
    newLike: '{% userName %} liked your post: "{% message %}"',
    newProblemReport: 'A "Report a problem" email has been sent to your email',
    societyActivated: '{% societyName %} was activated in Boappa!',
    societyInvite: 'You have been invited to {% societyName %}',
    societyRequest: '{% userName %} is requesting to join {% societyName %}',
    societyRequestAccepted: 'You have been granted access to {% societyName %}',
    societyRequestAcceptedAdmin:
      '{% userName %} has been accepted to {% societyName %}',
    newInspiration: "Check out this month's new inspirations.",
    chatNewChannel:
      'The group chat {% roomName %} has been created. Check it out.',
    contractMonitoring:
      'The monitoring date for contract {% contractTitle %} has occured.',
  },
  searchBar: {
    placeholder: {
      search: 'Search...',
      filter: 'Filter...',
    },
  },
  dropZone: {
    dragFileHere: 'Drag file here or',
    clickToSelect: 'click to select a file',
    acceptedFormats: 'Accepted formats',
  },
  createUpdateContactModal: {
    addNewContactTitle: 'Create new contact',
    editContactTitle: 'Edit contact',
    flashMessage: {
      updateContactSuccess: 'Contact updated',
      updateContactFailure: 'Contact could not be updated',
      createContactSuccess: 'Contact created',
      createContactFailure: 'Contact could not be created',
    },
  },
  createUpdateContractModal: {
    addNewContractTitle: 'Create new contract',
    editContractTitle: 'Edit contract',
    flashMessage: {
      updateContractSuccess: 'Contract updated',
      updateContractFailure: 'Contract could not be updated',
      createContractSuccess: 'Contract created',
      createContractFailure: 'Contract could not be created',
    },
  },
  createUpdateContractForm: {
    labels: {
      title: 'Title',
      company: 'Company',
      description: 'Description',
      running: 'Running',
      startDate: 'Start date',
      endDate: 'End date',
      notificationDate: 'Monitoring date',
      status: 'Status',
      documents: 'Documents',
      contactName: 'Name',
      contactEmail: 'Email address',
      contactPhone: 'Phone number',
      contactWebsite: 'Website',
    },
    headers: {
      contactDetails: 'Contact details',
      documents: 'Documents',
    },
    status: {
      active: 'Active',
      terminated: 'Terminated',
    },
  },
  createUpdateDocument: {
    addNewDocumentTitle: 'Upload document',
    editDocumentTitle: 'Edit document',
    flashMessage: {
      updateDocumentSuccess: 'Document updated',
      updateDocumentFailure: 'Document could not be updated',
      createDocumentPluralSuccess: 'Documents created',
      createDocumentPluralFailure: 'The documents could not be created',
      createDocumentSingularSuccess: 'Document created',
      createDocumentSingularFailure: 'Document could not be created',
      deleteDocumentSuccess: 'Document deleted',
      deleteDocumentFailure: 'Document could not be deleted',
    },
    removeDocumentConfirmationModal: {
      title: 'Delete document',
      description: 'Are you sure you want to delete the document?',
    },
  },
  createUpdateDocumentForm: {
    labels: {
      name: 'Name',
    },
    uploadedFiles: 'Uploaded files',
  },
  documentsList: {
    allDocuments: 'All documents',
    emptyState: {
      noSearchResultSubtitle:
        'No search results. Change your search string and try again.',
      title: 'You have nothing to read',
      emptyFolderSubtitle:
        'Right now there are no documents published in "{{folderName}}". When the board shares reports, notes or relevant documentation it will show up here.',
      noDocumentsSubtitle:
        'Right now there are no published folders or documents.',
      emptyFolderSubtitleBoard:
        'Right now there are no documents published in "{{folderName}}".',
      noDocumentsSubtitleBoard:
        'Right now there are no published folders or documents.',
    },
    document: {
      folderSingular: 'folder',
      folderPlural: 'folders',
      documentSingular: 'document',
      documentPlural: 'documents',
    },
  },
  createUpdateFolder: {
    addNewFolderTitle: 'Create new folder',
    editFolderTitle: 'Edit folder',
    flashMessage: {
      updateFolderSuccess: 'Folder updated',
      updateFolderFailure: 'Folder could not be updated',
      createFolderSuccess: 'Folder created',
      createFolderFailure: 'Folder could not be created',
      deleteFolderSuccess: 'Folder deleted',
      deleteFolderFailure: 'Folder could not be deleted',
    },
    removeFolderConfirmationModal: {
      title: 'Delete folder',
      description: 'Are you sure you want to delete the folder?',
    },
  },
  createUpdateFolderForm: {
    labels: {
      name: 'Name',
    },
  },
  deleteContract: {
    flashMessage: {
      deleteContractSuccess: 'Contract deleted',
      deleteContractFailure: 'Contract could not be deleted',
    },
    removeContractConfirmationModal: {
      title: 'Delete Contract',
      description: 'Are you sure you want to delete the Contract?',
    },
  },
  widgetView: {
    emptyState: {
      title: 'Function could not be found',
      subtitle: 'You tried to access a function that does not exist.',
    },
  },
  createUpdateAdminModal: {
    addNewAdminTitle: 'Add admin',
    flashMessage: {
      adminAddSuccessSingular: 'Admin added',
      adminAddFailureSingular: 'Admin could not be added',
      adminAddSuccessPlural: 'Admins added',
      adminAddFailurePlural: 'Admins could not be added',
    },
  },
  createUpdateBoardMemberModal: {
    addNewBoardMemberTitle: 'Add board member',
    editBoardMemberTitle: 'Edit board member',
    flashMessage: {
      createRoleSuccess: 'Role has been created',
      createRoleFailure: 'Role could not be created',
      updateRoleSuccess: 'Role has been updated',
      updateRoleFailure: 'Role could not be updated',
    },
  },
  boardListView: {
    title: 'Board members',
    addBoardMember: 'Add board member',
    alert: {
      deleteBoardMemberTitle: 'Delete board member',
      deleteBoardMemberText:
        'Are you sure you want to delete the board member?',
    },
    emptyState: {
      title: 'No board members',
      subtitle: 'No board members have been added to society.',
    },
    flashMessage: {
      deleteBoardMemberSuccess: 'Board member deleted',
      deleteBoardMemberFailure: 'Board member could not be deleted',
    },
  },
  boardListItem: {
    dropdown: {
      editRole: 'Edit role',
    },
  },
  createUpdateAdminForm: {
    labels: {
      users: 'Users',
    },
    error: {
      minItems: 'Need to select at least one user',
    },
  },
  createUpdateBlockedUserModal: {
    title: 'Add blocked user',
  },
  createUpdateBlockedUserForm: {
    labels: {
      users: 'Users',
    },
    error: {
      minItems: 'Select at least one user',
    },
  },
  createUpdateBoardMemberForm: {
    labels: {
      role: 'Role',
      user: 'User',
    },
  },
  findFriends: {
    numberMatchesSingular: 'Match',
    numberMatchesPlural: 'Matches',
    categories: {
      interests: 'Interests',
      occupationAndEducation: 'Occupation & Education',
    },
  },
  createUpdateContactForm: {
    labels: {
      coverPhoto: 'Cover photo',
      name: 'Name',
      title: 'Title',
      description: 'Description',
      phoneNumber: 'Phone number',
      emailAddress: 'Email address',
      website: 'Website',
    },
    headers: {
      contactDetails: 'Contact details',
    },
  },
  adminWebinar: {
    options: {
      gettingStarted: 'Get-started',
      intro: 'Intro',
      newBoard: 'New boardmember',
    },
  },
  adminView: {
    title: 'Admin',
    tabBar: {
      users: 'Users',
      societies: 'Societies',
      actions: 'Actions',
      discountCodes: 'Discount codes',
      logInAsUser: 'Log in as user',
      pendingInvoices: 'Pending invoices',
      extractData: 'Extract data',
      auditLogs: 'Audit logs',
      webinars: 'Webinars',
    },
    searchBar: {
      placeholders: {
        users: 'Search using email or id',
        societies: 'Search using name or id',
        auditLogs: 'Serach using society id',
      },
    },
    searchResult: {
      emptyResult: 'No search results.',
    },
  },
  manageHousehold: {
    modalTitle: 'Invite household members',
    title: 'Invite household members',
    subtitle:
      'This is where you can invite family members or a tenant who lives in your household but are not legal members of the cooperative.',
    form: {
      headers: {
        household: 'People in your household',
        pendingInvites: 'Pending invites',
      },
    },
    buttons: {
      addPerson: 'Add person',
    },
    reminder: {
      confirmationModal: {
        title: 'Send reminder',
        description: 'Do you want to send an invite reminder to {{ email }}?',
      },
    },
    errorViews: {
      noSociety: {
        title: "You're not part of a society",
        subtitle:
          'To manage your household you have to be part of a society and be connected to a unit.',
      },
    },
    flashMessage: {
      sendInviteSuccess: 'Person invited',
      sendInviteFailure: 'Invite could not be sent',
      userReminderFailure: 'Reminder could not be sent',
      userReminderSuccess: 'Reminder sent',
    },
  },
  postCreateUpdateBase: {
    form: {
      placeholders: {
        message: 'Hello dear neighbours....',
        society: 'Choose society',
      },
    },
    media: {
      attach: 'Attach',
      images: 'Images',
      documents: 'Documents',
    },
    attachments: {
      pictures: 'Your pictures',
      gallery: 'Gallery',
      document: 'Document',
      event: 'Event',
      poll: 'Poll',
    },
    roleSheet: {
      admin: 'Admin',
      board: 'Board',
    },
    visibilitySheet: {
      all: 'All',
      board: 'Board members',
      admin: 'Administrators',
    },
    navigationHeaders: {
      polls: 'Polls',
      documents: 'Documents',
      events: 'Events',
      images: 'Images',
    },
    alert: {
      imageDeleteTitle: 'Delete image',
      imageDeleteText: 'Are you sure you want to delete the image?',
      documentDeleteTitle: 'Delete document',
      documentDeleteText: 'Are you sure you want to delete the document?',
      eventDeleteTitle: 'Delete event',
      eventDeleteText: 'Are you sure you want to delete the event?',
      pollDeleteTitle: 'Delete poll',
      pollDeleteText: 'Are you sure you want to delete the poll?',
    },
    flashMessage: {
      updatePostSuccess: 'Post updated',
      updatePostFailure: 'Post could not be updated',
      createPostSuccess: 'Post created',
      createPostFailure: 'Post could not be created',
    },
  },
  society: {
    roles: {
      admin: 'Admin',
      board: 'Board',
    },
    boardRoles: {
      advisor: 'Advisor',
      alternate: 'Alternate',
      chairman: 'President',
      member: 'Member',
      nominatingCommittee: 'Nominating committee',
      secretary: 'Secretary',
      treasury: 'Treasury',
      viceChairman: 'Vice President',
    },
    postedBy: 'by',
  },
  authenticationSignInView: {
    title: 'Sign in',
    welcome: 'Welcome to Boappa',
    form: {
      labels: {
        email: 'Email address',
        password: 'Password',
      },
      placeholders: {
        email: 'Enter your email adress',
      },
    },
    signInButtonTitle: 'Sign in',
    createAccountButtonTitle: 'Create account',
    forgotPasswordButtonTitle: 'I forgot my password',
    flashMessage: {
      signInFailure: 'Wrong password or email',
    },
  },
  authenticationSignUpView: {
    title: 'Create account',
    subTitle: 'Create your account to get started',
    form: {
      labels: {
        name: 'Firstname',
        surname: 'Lastname',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm password',
      },
      placeholders: {
        name: 'Enter your firstname',
        surname: 'Enter your lastname',
        email: 'Enter your personal email adress',
      },
      checkboxText: {
        legalAge: "I'm 18 years of age or have parental approval",
        termsOfService:
          'I accept Boappas {{termsOfUse}} and have read Boappas {{privacyPolicy}}',
        termsOfUseTitle: 'Terms of use',
        privacyPolicyTitle: 'Privacy policy',
      },
    },
    signUpButtonTitle: 'Create account',
    loginButtonTitle: 'Do you already have an account?',
    loginCTAButtonTitle: 'Sign in',
    forgotPasswordButtonTitle: 'I forgot my password',
  },
  authenticationResetPasswordView: {
    title: 'Reset password',
    form: {
      labels: {
        email: 'Email adress',
        emailConfirmation: 'Repeat email adress',
      },
    },
    resetPasswordButtonTitle: 'Reset password',
    confirmationScreen: {
      title: 'Email sent',
      subtitle: 'Follow the instructions in the email to reset your password',
    },
    flashMessage: {
      accountDoesNotExist: 'No account for email adress exists',
      passwordResetSuccess: 'Email to reset password has been sent',
      passwordResetFailure: 'Password could not be reset',
    },
  },
  authenticationRetrievePasswordView: {
    title: 'Reset password',
    form: {
      labels: {
        password: 'New password',
        passwordConfirmation: 'Repeat password',
      },
    },
    retrievePasswordButtonTitle: 'Change password',
    problemsWithLink: 'Do you have problems changing your password?',
    sendNewLink: 'Send new link',
    flashMessage: {
      invalidToken: 'Invalid password reset link',
      passwordResetSuccess: 'Password has been changed',
      passwordResetFailure: 'Something went wrong, please try again',
    },
  },
  societyCreateUpdateContactScreens: {
    flashMessage: {
      updateContactSuccess: 'Contact updated',
      updateContactFailure: 'Contact could not be updated',
      createContactSuccess: 'Contact created',
      createContactFailure: 'Contact could not be created',
    },
  },
  societyCreateUpdateResourceScreens: {
    flashMessage: {
      updateResourceSuccess: 'Listing updated',
      updateResourceFailure: 'Listing could not be updated',
      createResourceSuccess: 'Listing created',
      createResourceFailure: 'Listing could not be created',
    },
  },
  createUpdateResourceModal: {
    title: 'Create new listing',
    labels: {
      media: 'Image',
      title: 'Title',
      description: 'Description',
      cost: 'Price',
    },
    placeholders: {
      description: 'Describe the object you are listing',
    },
  },
  settingsView: {
    title: 'My account',
    tabBar: {
      tabs: {
        myInfo: 'My info',
        changePassword: 'Change password',
        notificationSettings: 'Notifications',
      },
    },
  },
  profileSettings: {
    title: 'Account',
    profilePictureTitle: 'Profile picture',
    labels: {
      updateProfilePicture: 'Update profile picture',
    },
    confirmationModal: {
      title: 'Delete profile picture',
      description: 'Are you sure you want to delete the profile picture?',
    },
    flashMessage: {
      updateProfilePictureSuccess: 'Profile picture updated',
      updateProfilePictureFailure: 'Profile picture could not be updated',
      deleteProfilePictureSuccess: 'Profile picture removed',
      deleteProfilePictureFailure: 'Profile picture could not be removed',
    },
    deleteAccount: {
      title: 'Delete account',
      text: 'Follow the upcoming steps to permanently delete your account',
    },
  },
  changeLanguage: {
    title: 'Language',
  },
  changeEmail: {
    title: 'Change email address',
    labels: {
      currentEmail: 'Current email address',
      newEmail: 'New email address',
      repeatNewEmail: 'Repeat email address',
    },
    flashMessage: {
      updateEmailFailure: 'Email address could not be updated',
      updateEmailSuccess: 'Email address updated',
    },
  },
  changePassword: {
    title: 'Change password',
    labels: {
      currentPassword: 'Current password',
      newPassword: 'New password',
      newPasswordConfirmation: 'Repeat new password',
    },
    flashMessage: {
      updatePasswordFailure: 'Password could not be updated',
      updatePasswordSuccess: 'Password updated',
    },
  },
  myInfo: {
    sections: {
      titles: {
        general: 'General information',
        work: 'Work',
        education: 'Education',
        about: 'About me',
        interests: 'Interests',
      },
    },
  },
  boappaSuperadmin: {
    title: 'Support tools',
    userTitle: 'User',
    logout: 'Log out as user',
    signin: 'Log in as user',
    labels: {
      search: 'Search for email or id',
      searchButton: 'Search',
      matches: 'Matches',
      firstname: 'First name:',
      lastname: 'Last name:',
      verified: 'Verified email:',
      email: 'Email:',
    },
  },
  notificationSettings: {
    options: {
      mobile: 'Mobile',
      email: 'Email',
    },
    sections: {
      all: {
        title: 'All notifications',
        allNotificationsTitle: 'Turn all notifications on or off',
      },
      admin: {
        title: 'Admin',
        membershipRequestTitle: 'Membership requests',
      },
      feed: {
        title: 'Feed',
        boardNewPostTitle: 'Posts from the board',
        boardNewCommentTitle: 'Comments on posts from the board',
        neighbourNewPostTitle: 'Posts from neighbours',
        neighbourNewCommentTitle: 'Comments on posts from neighbours',
      },
      chat: {
        title: 'Chat',
        neighbourNewMessageTitle: "Neighbours' chat",
        groupNewMessageTitle: "Group's chat",
        boardNewMessageTitle: "Board's chat",
        questionsNewMessageTitle: "Answer residents' chat",
      },
    },
  },
  form: {
    requiredNote: '*Required',
    lockedNote: 'Locked',
    errors: {
      required: 'Field is required',
      unknown: 'Invalid input',
      invalidEmail: 'Invalid email address',
      invalidWebsite: 'Invalid web url',
      passwordConfirmation: 'Passwords must match',
      emailConfirmation: 'Email addresses must match',
      minLength: 'Must be at least {{minLength}} characters',
      minNumber: 'Must be at least {{minNumber}}',
      emailAlreadyInUse: 'Email adress already in use.',
      matches: 'Invalid format',
      organisationNumber:
        'The organisation number should consist of 10 digits. For example: 123456-7890',
    },
  },
  flashMessage: {
    changesSaved: 'Changes saved',
    changesNotSavedError: 'Changes could not be saved',
    somethingWentWrongError: 'Something went wrong',
    cannotOpenEmail: 'Unable to open email client. Email address: {% email %}',
    chatSocketError:
      'Something went wrong when sending message. Try refreshing the page or try again later.',
  },
  errorBoundary: {
    errorFallbackTitle: 'Oops, something went wrong!',
    errorFallbackSubtitle:
      'The error has been reported to our developers and will be fixed as soon as possible.\n\nTo help us identify the error, please submit a bug report.',
    reportBugButtonTitle: 'Report bug',
  },
  user: {
    name: 'Name',
    surname: 'Surname',
    gender: 'Gender',
    phoneNumber: 'Phone number',
    birthDate: 'Date of Birth',
    aboutInfo: {
      presentation: 'Presentation',
      presentationPlaceholder: 'Describe yourself...',
      interestCategories: 'Categories',
      occupation: {
        company: 'Company',
        title: 'Title',
        industry: 'Industry',
      },
      education: {
        level: 'Level',
        country: 'Country',
        institute: 'Institute',
        field: 'Field',
        degree: 'Degree',
      },
    },
  },
  education: {
    level: {
      highSchool: 'High School',
      professionalSchool: 'Professional School',
      university: 'University',
    },
    degree: {
      bachelor: 'B.Sc.',
      master: 'M.Sc',
      phd: 'PhD',
    },
    field: {
      airPilot: 'Air pilot',
      animalKeeper: 'Animal Keeper',
      architecture: 'Architecture',
      bachelorOfEngineeering: 'Bachelor of Engineering',
      bioMedicine: 'Biomedicine',
      business: 'Business',
      civilEngineering: 'Civil Engineering',
      dentalHygiene: 'Dental Hygiene',
      dentist: 'Dentist',
      dietetics: 'Dietetics',
      engineering: 'Engineering',
      environmentalScience: 'Environmental Science',
      finance: 'Finance',
      fireScience: 'Fire Science',
      forestry: 'Forestery',
      forestEngineering: 'Forest engineering',
      healthScience: 'Health science',
      hippology: 'Hippology',
      humanities: 'Humanities',
      humanResources: 'Human Resources',
      journalism: 'Journalism',
      law: 'Law',
      libraryAndInformationScience: 'Library and Information Science',
      logistics: 'Logistics',
      marineScience: 'Marine Science',
      mediaAndCommunicationScience: 'Media and Communication Science',
      medicalDoctor: 'Medical doctor',
      natureScience: 'Nature Science',
      nursing: 'Nursing',
      occupationalTherapy: 'Occupational Therapy',
      optometry: 'Optometry',
      pharmacist: 'Pharmacist',
      physiotherapy: 'Physiotherapy',
      police: 'Police',
      psychology: 'Psychology',
      realEstate: 'Real Estate',
      socialAndBehaviouralScience: 'Social and Behavioural Science',
      socialScience: 'Social Science',
      socialWorkEducation: 'Social Work Education',
      softwareDeveloper: 'Software Developer',
      statistics: 'Statistics',
      systemsEngineering: 'Systems Engineering',
      teacher: 'Teacher',
      theology: 'Theology',
      veterinary: 'Veterinary',
    },
  },
  occupation: {
    industry: {
      marketing: 'Sales, Marketing, Advertising',
      adminOfficeManagement: 'Administration, Office management',
      healthcare: 'Healthcare',
      customerSupport: 'Customer support',
      humanResourcesLegal: 'Human resources, Legal',
      serviceRetailTourism: 'Service, Retail, Tourism',
      financeBankingInsurance: 'Finance, Banking, Insurance',
      education: 'Education',
      industryProduction: 'Industry, Production',
      itData: 'IT, Data',
      engineering: 'Engineering',
      logisticsPurchasing: 'Logistics, Purchasing',
      management: 'Management',
      environment: 'Environmental care',
      construction: 'Construction, Building',
      cultureMedia: 'Culture, Media',
    },
  },
  interests: {
    actionSports: 'Action sports',
    animals: 'Animals',
    art: 'Art',
    boardGames: 'Board games',
    cars: 'Cars',
    cooking: 'Cooking',
    dancing: 'Dancing',
    design: 'Design',
    electronicsSmartHomes: 'Electronics/Smart homes',
    entrepreneurship: 'Entrepreneurship',
    family: 'Family',
    film: 'Film',
    fitness: 'Fitness',
    gardening: 'Gardening',
    handicraft: 'Handcrafts',
    literature: 'Literature',
    motorcycles: 'Motorcycles',
    music: 'Music',
    nature: 'Nature',
    outdoors: 'Outdoors',
    painting: 'Painting',
    politics: 'Politics',
    reading: 'Reading',
    religion: 'Religion',
    sports: 'Sports',
    startups: 'Startups',
    stockTrading: 'Stock trading',
    tech: 'Tech',
    toddlers: 'Toddlers',
    travel: 'Travels',
  },
  genders: {
    male: 'Male',
    female: 'Female',
    other: 'Other',
  },
  societySideBar: {
    sections: {
      resident: {
        title: 'Resident',
        neighborhoodTitle: 'Neighborhood',
        residentFunctionsTitle: 'For residents',
      },
      board: {
        title: 'Management portal',
        managementTitle: 'Management tools',
        adminTitle: 'Admin',
      },
    },
    searchBar: {
      placeholder: 'Search in menu...',
    },
    items: {
      labels: {
        leaveSociety: 'Leave society',
      },
    },
    callouts: {
      incompleteRegister: {
        title: 'Incomplete apartment register',
        titleSamf: 'Incomplete property register',
      },
      societyLocked: {
        title: 'Society locked',
      },
      societyTrial: {
        titleDays: '{{ days }} day left on trial',
        titleDaysPlural: '{{ days }} days left on trial',
      },
    },
  },
  chatRoomsSidebar: {
    startNewChat: 'Start new',
    startNewInterestsChat: 'Start new',
    startNewAskBoardChat: 'Ask question',
    startNewBoardChat: 'Start new',
    joinGroupChat: 'Join',
    findQuestionsChat: 'Archive',
    activeTabBar: {
      resident: 'Resident',
      board: 'Board',
    },
    tabs: {
      oneOnOneAndInterestsTitle: 'Chat',
      questionsTitle: 'Ask the board',
      boardTitle: 'Board chat',
      answerResidentsTitle: 'Answer residents',
    },
    createNewMessage: {
      title: 'New message',
    },
    createNewMessageForm: {
      labels: {
        sendTo: 'Send to:',
      },
      search: {
        placeholder: 'Select name',
      },
      error: {
        minItems: 'Need to select at least one user',
      },
    },
  },
  chatRooms: {
    chatDisabled: {
      title: 'Chat disabled',
      subtitle: 'The board has disabled the chat between neighbours',
    },
    userHasNoChats: {
      title: 'No chat started yet',
      subtitle:
        'Hint: Has the milk run out for your coffee, or have you forgotten the time for the barbecue? Take the opportunity to chat and ask your neighbours for help.',
    },
    userHasNoGroupChats: {
      subtitle:
        'Start an open or closed group chat with your neighbours, or check "Join" to see if there is any existing chat you are interested in joining.',
    },
    noBoardQuestions: {
      title: 'No question asked yet',
      subtitle:
        'Hint: Do you have a question about how to be put on the waiting list for a garage spot in your society? Ask your questions to the board here.',
    },
    noBoardChats: {
      title: 'No board chat started yet',
      subtitle:
        'Hint: Here you can discuss upcoming projects and board meetings.',
    },
    noBoardAnswerResidentChats: {
      title: 'No question asked yet',
      subtitle: 'Here you can answer questions from residents.',
    },
    pendingRequest: {
      subtitle:
        'Here you will be able to chat with your neighbours as soon as your request is approved.',
    },
  },
  chatSettings: {
    actions: {
      add: 'Add:',
    },
    actionSheet: {
      changeChatRoomImage: 'Change chat image',
      deleteChatRoomImage: 'Remove chat image',
    },
    form: {
      title: 'Group members',
      remove: 'Delete chat',
      groupChatName: 'Name',
      chatCreator: 'Creator',
      society: 'Society',
      type: 'Type',
      closedGroupChat: 'Closed group chat',
      openGroupChat: 'Open group chat',
      members: 'Members',
      showMembers: 'Show group members',
      leave: 'Leave chat',
      archive: 'Archive',
    },
    tables: {
      titles: {
        groupChatInfo: 'Group information',
        addMembers: 'Add group members',
        actions: 'Actions',
      },
    },
    alert: {
      chatRoomDeleteTitle: 'Delete chat',
      chatRoomDeleteText: 'Are you sure you want to delete the chat?',
      leaveChatRoomTitle: 'Leave group chat',
      leaveChatRoomText: 'Are you sure you want to leave the group chat?',
      archiveChatRoomTitle: 'Archive chat',
      archiveChatRoomText: 'Are you sure you want to archive the chat?',
      chatRoomMemberDeleteTitle: 'Remove from group chat',
      chatRoomMemberDeleteText:
        'Are you sure you want to remove the member from the group chat?',
    },
    flashMessage: {
      deleteChatRoomSuccess: 'Chat deleted',
      deleteChatRoomFailure: 'Chat could not be deleted',
      leaveChatRoomSuccess: 'Left group chat',
      leaveChatRoomFailure: 'Could not leave group chat',
      archiveChatRoomSuccess: 'Chat archived',
      archiveChatRoomFailure: 'Could not archive chat',
      deleteChatRoomMemberSuccess: 'Group chat member deleted',
      deleteChatRoomMemberFailure: 'Group chat member could not be deleted',
    },
  },
  startNewChat: {
    openGroupChat: 'Open group chat',
    closedGroupChat: 'Closed group chat',
    changeToGroupChat: 'Convert to group chat',
    groupChatInputLabel: 'Group name:',
    boardChatInputLabel: 'Subject:',
  },
  managementDocumentsView: {
    title: 'Board documents',
    addNewFolder: 'Add folder',
    addNewDocument: 'Upload document',
    bostadsratternaFolderStructure: 'Folder structure',
    flashMessage: {
      createBostadsratternaFolderStructureSuccess: 'Folder structure created',
      createBostadsratternaFolderStructureFailure:
        'Folder structure could not be created',
    },
  },
  documentsView: {
    title: 'Documents',
    addNewFolder: 'Add folder',
    addNewDocument: 'Upload document',
  },
  unitRoles: {
    member: 'Resident (owner)',
    resident: 'Resident',
    tentant: 'Tenant',
    relative: 'Relative',
    propertyManager: 'Property Manager',
    customerRepresentative: 'Customer Representative',
    other: 'Other',
  },
  joinGroupChat: {
    joinHeader: 'Join group chat',
    join: 'Join',
    details: 'Details',
    backToGroupChats: 'Back to group chats',
    errorViews: {
      noChatRooms: {
        title: 'There are no chats to join',
        subtitle:
          'Here you will be able to join chats that you are not a member of yet.',
      },
    },
    flashMessage: {
      joinGroupChatSuccess: 'Group chat joined',
      joinGroupChatFailure: 'Could not join group chat',
    },
  },
  findArchivedChats: {
    header: 'Activate archived chat',
    errorViews: {
      noChatRooms: {
        title: 'There are no archived chats',
        subtitle:
          'Here you will be able to activate your archived chats again.',
      },
    },
    flashMessage: {
      activateArchivedChatSuccess: 'Archived chat activated',
      activateArchivedChatFailure: 'Could not activate archived chat',
    },
  },
  chatAskBoardNewMessage: {
    title: 'Ask board',
    form: {
      labels: {
        subject: 'Subject',
        message: 'Message',
      },
    },
    media: {
      attachImageButtonLabel: 'Attach image',
      imageHeaderTitle: 'Images',
    },
    flashMessage: {
      sendQuestionSuccess: 'Question sent',
      sendQuestionFailure: 'Could not send question',
    },
  },
  chatUpload: {
    flashMessage: {
      uploadImageLimitReached:
        'You may only upload a total of 10 images per chat message',
      uploadDocumentLimitReached:
        'You may only upload a total of 10 documents per chat message',
    },
  },
  societyManagementWebsiteImages: {
    title: 'Images',
    headers: {
      primaryImageTitle: 'Primary image',
      societyImagesTitle: 'Images shown on website',
    },
    addImagesButton: 'Add images',
    addImageDescription: 'Add description',
    showImagesLabel: 'Show',
    recommendedPrimaryImageSizeText:
      'Recommended minimum image size: 1280x800 pixels',
  },
  mediaGallery: {
    imageGalleryPositionLabel: 'Image',
    imageDownloadSuccessText: 'Image downloaded',
    downloadImageButtonText: 'Download image',
  },
  leaveSociety: {
    confirmationModal: {
      title: 'Leave society',
      description: 'Are you sure you want to leave the society?',
    },
    unableToLeaveModal: {
      title: 'Unable to leave society',
      description:
        'To leave the society you first have to assign someone else as master admin.',
    },
    flashMessage: {
      leaveSocietySuccess: 'Society left',
      leaveSocietyFailure: 'Could not leave society',
    },
  },
  reportProblemSpace: {
    apartment: 'Apartment',
    building: 'My building',
    surroundings: 'Surroundings',
    commonAreas: 'Common Areas',
    other: 'Other',
  },
  pollEditor: {
    form: {
      labels: {
        title: 'Question',
        type: 'Poll type',
      },
      placeholders: {
        option: 'Option',
      },
      headers: {
        options: 'Options',
      },
      buttons: {
        addMoreOptionsButtonTitle: 'Add more options',
      },
    },
    label: {
      perUser: 'Per user',
      perHousehold: 'Per apartment',
    },
    flashMessage: {
      updatePollSuccess: 'Poll updated',
      updatePollFailure: 'Poll could not be updated',
      createPollSuccess: 'Poll created',
      createPollFailure: 'Poll could not be created',
    },
    pollDescription: 'Poll',
  },
  poll: {
    votesSingular: 'Vote',
    votesPlural: 'Votes',
    showResults: 'Show results',
    vote: 'Vote',
    regretVote: 'Regret vote',
    householdAlreadyVoted: 'Your household has already voted',
    userAlreadyVoted: 'You have already voted',
  },
  createSocietyTypeSelectionView: {
    title: 'Create society',
    labels: {
      housingCooperative: 'Housing cooperative',
      homeownersAssociation: 'Homeowners association',
      realEstateDeveloper: 'Real-estate developer project',
      neighbourhood: 'Neighbourhood',
    },
  },
  societyInviteBanner: {
    invited: 'You are invited to {{societyName}}',
    details: 'See invite',
  },
  reportPost: {
    title: 'Report post',
    subtitle:
      'Boappa aims to help create a positive atmosphere among neighbours. No harassment is allowed. Report a post to alert the administrator.',
    form: {
      labels: {
        message: 'Message',
      },
    },
    flashMessage: {
      reportSuccess: 'Post reported',
      reportFailure: 'Post could not be reported',
    },
    buttons: {
      submitTitle: 'Report',
    },
  },
  societyRoot: {
    subtitle: 'Here you can find shortcuts to different tools in your society',
    shortcutTitle: 'Shortcuts',
    offersTitle: 'Offers',
    titles: {
      residentList: 'Resident list',
      boardDocuments: 'Board documents',
      register: 'Register',
      societyWebsite: 'Society website',
    },
    subtitles: {
      residentList: 'See a list of your neighbours',
      boardDocuments: 'Create folders and upload documents',
      register:
        'Invite your neighbours, manage addresses and apartment numbers',
      societyWebsite:
        'Customize your new society website after your own wishes',
    },
    onboardingButtonTitle: 'You can easily find back to the guide here',
  },
  onboardingView: {
    title: 'Welcome to {{societyName}}!',
    subtitle: 'Get started with your society',
    explanationTitle:
      'This guide gives you an insight into how the navigation works under the society tab',
    explanationText:
      'We will go through documents, Register, Questions & Answers and Society website. You can easily revisit the guide by clicking the button on the society start page.',
    skipText: 'Done? Click here to skip the guide',
    onboardingSections: {
      documents: {
        title: 'Styrelsedokument',
        subtitle:
          'Ladda upp dokument som delas i din förening. För att skapa er egna första mapp klicka på “skapa mapp” eller “ladda upp dokument” för att komma igång.',
      },
      register: {
        title: 'Register',
        subtitle:
          'Bjud in era styrelsekollegor och grannar. Klicka på knappen “Bjud in medlemmar” inne på register för att påbörja inbjudningsprocessen. Ni kan även använda en excel-fil för att ladda upp flera adresser åt gången.',
      },
      faq: {
        title: 'Frågor och svar',
        subtitle:
          'Lägg upp bra och nyttig information som era grannar i föreningen kan ta del av. Här kan ni till exempel ange information kring balkong, yttre- och inre utrymmen som är bra att veta.',
      },
      societyWebsite: {
        title: 'Föreningshemsida',
        subtitle:
          'Ange information och bilder till er helt egna föreningshemsida. Här kan ni ladda upp årsredovisningar, dokument och bilder som är relevant för just er förening. Detta kan även underlätta för mäklare när de behöver hitta information om just er förening.',
      },
    },
  },
  onboardingSection: {
    step: 'Step {{step}}',
    nextStep: 'Next step',
    previousStep: 'Previous step',
    lastStep: 'Finished! Close guide',
  },
  verifyEmailView: {
    title: 'Verify email address',
    subtitle:
      'To start using Boappa you need to verify your email address. A link has been sent to your email:',
    cantFindEmail:
      "Can't find any email in your inbox? First check your spam, otherwise click on the button below to resend the email.",
    sendAgain: 'Send the link again',
    preTimeLeftUntilResend: 'You can request a new link in',
    invalidEmail: 'Did you enter an invalid email address?',
    flashMessage: {
      resendVerifyEmailSuccess: 'Email has been sent',
      resendVerifyEmailFailure: 'Email could not be sent',
    },
    startOverButtonTitle: 'Start over',
  },
  deleteAccountModal: {
    title: 'Delete account',
    warningTitle: 'Are you sure?',
    warningText:
      'Your data will be permanently deleted, and there is no way to undo this operation. If you have moved to another society, you can choose to "leave society" instead.',
    warningSubtitle: 'Your account will be deleted permanently.',
    secondWarningTitle: 'Delete account',
    secondWarningText:
      'The account will be deleted permanently and can not be recreated. Confirm your choice by clicking on the continue button',
    lastWarningTitle:
      'Enter your password and click "Delete account" to delete your account',
    canNotDeleteTitle: 'Your account can not be deleted',
    canNotDeleteText:
      'You must assign the master admin role to someone else in the societies listed below before you can delete your account.',
    alert: {
      deleteAccountTitle: 'Delete account',
      deleteAccountText:
        'Are you sure you want to delete your account permanently? Confirm your choice.',
    },
    flashMessage: {
      confirmPasswordFailure: 'Invalid password',
      deleteAccountSuccess: 'Your account has been deleted',
    },
  },
  uploadImage: {
    invalidImageType:
      'Invalid image type, try another image or change the image type',
  },
}

export default en
