import { types } from 'mobx-state-tree'
import { timestampTypes } from '../types/common'

export const DocumentSectionType = types.enumeration('DocumentSectionType', [
  'feed',
  'society',
  'board',
  'society-website',
])

export const DocumentModel = types.model('Document', {
  _id: types.identifier,
  ...timestampTypes,
  type: types.maybe(
    // interfaces says it can't be undefined, but it can.
    types.enumeration('DocumentType', [
      '',
      'doc',
      'docx',
      'dotx',
      'pdf',
      'txt',
      'csv',
      'zip',
      'odt',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'folder',
      'png',
      'jpeg',
      'jpg',
      'webp',
      'svg',
      'tif',
      'tiff',
      'key',
      'numbers',
      'pages',
      'xlsm',
    ])
  ),
  filename: types.string,
  name: types.maybe(types.string),
  url: types.maybeNull(types.string),
  societyId: types.maybe(types.string),
  parentId: types.maybeNull(types.string),
  section: types.maybe(DocumentSectionType),
})
