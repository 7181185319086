import { addSeconds } from 'date-fns'
import { types } from 'mobx-state-tree'
import { formatDateWithFormat, ignoreTimezoneDate } from '../../helpers/date'
import { timestampTypes } from '../types/common'

export const BookingModel = types
  .model('Booking', {
    _id: types.identifier,
    ...timestampTypes,
    startDate: types.string,
    endDate: types.string,
    facilityId: types.string, // Booking with reference to 404 facility exists
    societyId: types.string,
    unitId: types.string,
    userId: types.string,
    isAdmin: types.maybe(types.boolean),
  })
  .views((self) => ({
    getTimeSpanString() {
      return `${formatDateWithFormat(
        ignoreTimezoneDate(new Date(self.startDate)),
        'HH:mm'
      )}-${formatDateWithFormat(
        // Backend stores the value as 15:59:59 instead of 16:00.
        addSeconds(ignoreTimezoneDate(new Date(self.endDate)), 1),
        'HH:mm'
      )}`
    },
  }))
