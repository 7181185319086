import React, { useCallback } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { BookingModel } from '../../../../state/models/booking'
import { DeleteBookingConfirmationModal } from '../DeleteBookingConfirmationModal'
import { Button, ButtonVariant } from '../../../common/Button'
import { useModal } from '../../../../hooks/useModal'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { Card } from '../../../common/Card'
import { Avatar } from '../../../common/Avatar'
import { TimeSlotTime } from '../TimeSlotTime'
import { theme } from '../../../../theme/theme'
import { FacilityModel } from '../../../../state/models/facility'
import { capitalize } from '../../../../helpers/string'
import { formatDateWithFormat } from '../../../../helpers/date'
import { Badge } from '../../../common/Badge'
import { useStores } from '../../../../hooks/useStores'

interface FacilityCalendarBookingCardProps {
  type: 'booking' | 'timeSlot'
  booking?: SnapshotOut<typeof BookingModel>
  facility: SnapshotOut<typeof FacilityModel>
  createBooking?: (isAdmin?: boolean) => void
  startDate: Date
  endDate: Date
  loading: boolean
}

export const FacilityCalendarBookingCard = ({
  type,
  booking,
  facility,
  createBooking,
  startDate,
  endDate,
  loading,
}: FacilityCalendarBookingCardProps): JSX.Element | null => {
  const { translate } = useAppTranslation()
  const { bookingsStore, unitStore, societyStore, authenticationStore } =
    useStores()
  const {
    show: showDeleteBookingModal,
    open: openDeleteBookingModal,
    close: closeDeleteBookingModal,
  } = useModal()

  const isBooking = type === 'booking'

  const userUnitsForSociety = unitStore.userUnits(
    authenticationStore.userId as string,
    societyStore?.selectedSociety?._id as string
  )

  const userHasReachedMaxNumberOfBookings = useCallback((): boolean => {
    if (!facility) {
      return false
    }
    if (facility?.rules === 'unlimited') {
      return false
    }

    const futureFacilityBookings =
      bookingsStore.futureBookingsForUnitsAndFacility(
        userUnitsForSociety.map((_unit) => _unit._id),
        facility._id
      )
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const maxNumberOfBookings = facility?.getRuleAsInteger()

    return futureFacilityBookings.length >= maxNumberOfBookings
  }, [bookingsStore, userUnitsForSociety, facility])

  if (isBooking && !booking) {
    return null
  }

  const dateTitle = isBooking
    ? capitalize(formatDateWithFormat(new Date(startDate), 'eeee d MMMM'))
    : capitalize(formatDateWithFormat(startDate, 'eeee d MMMM'))

  return (
    <Card minWidth={400} className="grow xl:max-w-md">
      {{
        body: (
          <div className="flex shrink items-center gap-4 p-4">
            {facility.coverPhotoId && (
              <Avatar mediaId={facility.coverPhotoId} size={50} />
            )}
            <div className="flex grow flex-col items-start justify-center">
              {booking?.isAdmin && (
                <Badge
                  padding="px-1.5"
                  colorClass="bg-brandGreen/10"
                  borderColorClass="border-brandGreen"
                  textColorClass="text-black"
                  text="Admin"
                  className="justify-self-start mb-1"
                />
              )}
              <p style={theme.textVariants.base} className="text-neutral-40">
                {dateTitle}
              </p>
              {facility.bookingGranularity === 'full-day' ? (
                <p style={theme.textVariants.base}>
                  {translate('facilityView.calendar.fullDay')}
                </p>
              ) : (
                <TimeSlotTime
                  startDate={isBooking ? new Date(startDate) : startDate}
                  endDate={isBooking ? new Date(endDate) : endDate}
                  prefix="Kl."
                />
              )}
            </div>
            {!isBooking && createBooking && (
              <Button
                wrapperClassName="shrink justify-self-end"
                label={translate('common.actions.book')}
                variant={ButtonVariant.PRIMARY}
                onClick={() => createBooking(false)}
                disabled={userHasReachedMaxNumberOfBookings()}
                loading={loading}
              />
            )}
            {!isBooking && createBooking && (
              <Button
                wrapperClassName="shrink justify-self-end"
                label={translate('common.actions.bookAsAdmin')}
                variant={ButtonVariant.PRIMARY}
                onClick={() => createBooking(true)}
                loading={loading}
              />
            )}
            {isBooking && booking && (
              <>
                <Button
                  wrapperClassName="shrink justify-self-end"
                  label={translate(
                    'facilityView.bookings.buttons.deleteBooking'
                  )}
                  variant={ButtonVariant.DANGER}
                  onClick={openDeleteBookingModal}
                  loading={loading}
                />

                <DeleteBookingConfirmationModal
                  showModal={showDeleteBookingModal}
                  closeModal={closeDeleteBookingModal}
                  booking={booking}
                />
              </>
            )}
          </div>
        ),
      }}
    </Card>
  )
}
