import { Instance } from 'mobx-state-tree'
import { useEffect, useState } from 'react'
import { UserModel } from '../../../state/models/user'
import { useStores } from '../../../hooks/useStores'
import { EventAttendantModel } from '../../../state/models/event-attendant'

export interface UserAttendance {
  user: Instance<typeof UserModel>
  status: 'attending' | 'not_attending'
  comment?: string
}

export const useEventUserAttendance = (
  attendants: Instance<typeof EventAttendantModel>[] | undefined
): { attending: UserAttendance[]; notAttending: UserAttendance[] } => {
  const { userStore } = useStores()
  const [attending, setAttending] = useState<UserAttendance[]>([])
  const [notAttending, setNotAttending] = useState<UserAttendance[]>([])

  useEffect(() => {
    const setAttendance = async (): Promise<void> => {
      if (!attendants) {
        return
      }
      const userAttendances = await Promise.all(
        attendants.map(async (attendant) => {
          const user = userStore.users.get(attendant.userId)
          if (!user) {
            return null
          }
          return {
            user,
            status: attendant.status,
            comment: attendant.comment,
          }
        })
      )

      const nonNullUsersAttendances = userAttendances.filter(
        (attendance) => attendance !== null
      ) as UserAttendance[]

      const sortedUserAttendances = nonNullUsersAttendances.sort((a, b) =>
        a.user.fullName.localeCompare(b.user.fullName)
      )

      setAttending(
        sortedUserAttendances.filter(
          (attendance) => attendance.status === 'attending'
        )
      )
      setNotAttending(
        sortedUserAttendances.filter(
          (attendance) => attendance.status === 'not_attending'
        )
      )
    }
    setAttendance()
  }, [attendants, userStore.users])

  return { attending, notAttending }
}
