import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { SnapshotOut } from 'mobx-state-tree'
import { saveAs } from 'file-saver'
import { convertArrayToCSV } from 'convert-array-to-csv'
import { AxiosResponse } from 'axios'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { useStores } from '../../../hooks/useStores'
import { useCurrentSociety } from '../../../hooks/useCurrentSociety'
import { useModal } from '../../../hooks/useModal'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../helpers/society'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { reverseDocumentTitle } from '../../../navigation/reverseDocumentTitle'
import { IllustrationChoices } from '../../../components/common/Illustration'
import { ErrorBoundary } from '../../../components/common/ErrorBoundary'
import { ViewBase } from '../../../components/common/ViewBase'
import { EventModel } from '../../../state/models/event'
import { BoardRoomEventModel } from '../../../state/models/boardroom-event'
import { RemoveEventModal } from '../../../components/society/Event/RemoveEventModal'

import { CreateUpdateEventModal } from '../../../components/society/Event/CreateUpdateEventModal'
import { IconChoices } from '../../../components/common/Icon'
import { ButtonVariant } from '../../../components/common/Button'
import { reverseUrl } from '../../../navigation/reverseUrl'
import { useEventTabs } from './useEventTabs'
import { RouteErrorView } from '../../error/RouteErrorView'
import { EventDetailInformation } from './EventDetailInformation'
import { EventDetailAttendants } from './EventDetailAttendants'
import { getEventAttendanceList } from '../../../api/events'
import { NEvents } from '../../../interfaces/services/events.interfaces'
import { getBoardroomEventAttendanceList } from '../../../api/boardroom-events'
import { NBoardRoomEvents } from '../../../interfaces/services/boardroom-events.interfaces'

export enum EventDetailViewTabs {
  INFORMATION = 0,
  ATTENDANTS = 1,
}

interface EventDetailViewProps {
  boardRoomEvent?: boolean
}

export const EventDetailView = observer(
  ({ boardRoomEvent }: EventDetailViewProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { eventStore, boardRoomEventStore } = useStores()
    const { society } = useCurrentSociety()
    const location = useLocation()
    const navigate = useNavigate()
    const { getTabUrl, getTabState } = useEventTabs(boardRoomEvent)

    const [activeTab, setActiveTab] = useState<EventDetailViewTabs>(
      getTabState(location.pathname)
    )
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { eventId } = useParams()

    const {
      show: showEditModal,
      open: openEditModal,
      close: closeEditModal,
    } = useModal()
    const {
      show: showDeleteConfirmationModal,
      open: openDeleteConfirmationModal,
      close: closeDeleteConfirmationModal,
    } = useModal()

    const userId = useAuthenticatedUserId() as string

    const userIsSocietyAdmin = isUserAdminInSociety(society, userId as string)
    const userIsBoardMember = isUserBoardMemberInSociety(society, userId)

    const exportAttendaceList = async (): Promise<void> => {
      if (eventId) {
        let resp:
          | AxiosResponse<NEvents.NGetAttendanceList.IResponse>
          | AxiosResponse<NBoardRoomEvents.NGetAttendanceList.IResponse>
        if (boardRoomEvent) {
          resp = await getBoardroomEventAttendanceList(eventId)
        } else {
          resp = await getEventAttendanceList(eventId)
        }
        const data = resp.data as NEvents.NGetAttendanceList.IResponse
        const csv = data.data.list.map((m) => ({
          Namn: m.name,
          Epost: m.email,
          Status:
            m.status === 'attending'
              ? translate(`eventDetailView.attendance.titles.${m.status}`)
              : translate(`eventDetailView.attendance.titles.notAttending`),
          Bostad: m.units,
          Kommentar: m.comment,
        }))

        saveAs(
          new Blob([convertArrayToCSV(csv)]),
          `${data.data.event.title}-deltagarlista.csv`
        )
      }
    }

    const getEvent = ():
      | SnapshotOut<typeof EventModel>
      | SnapshotOut<typeof BoardRoomEventModel>
      | undefined => {
      if (!eventId) {
        return undefined
      }

      if (boardRoomEvent) {
        return boardRoomEventStore.events.get(eventId)
      }

      return eventStore.events.get(eventId)
    }

    const event = getEvent()

    useEffect(() => {
      if (eventId && !event) {
        if (boardRoomEvent) {
          boardRoomEventStore.getEvent(eventId)
        } else {
          eventStore.getEvent(eventId)
        }
      }
    }, [eventId, event, boardRoomEventStore, eventStore, boardRoomEvent])

    // When a new activeTab is set, we change the navigation to that tab as well
    useEffect(() => {
      const url = getTabUrl(activeTab)
      if (location.pathname !== url) {
        navigate(url)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    useDocumentTitle(
      reverseDocumentTitle(
        boardRoomEvent
          ? 'management:calendar-detail'
          : 'society:calendar-detail',
        {
          '{{ eventName }}': event?.title,
          '{{ societyName }}': society.name,
        }
      )
    )

    const loading = boardRoomEvent
      ? boardRoomEventStore.fetchingEvents === 'pending'
      : eventStore.fetchingEvents === 'pending'

    const buttons =
      userIsSocietyAdmin || (boardRoomEvent && userIsBoardMember)
        ? [
            {
              label: `${translate('common.actions.download')} 
                ${translate('eventDetailView.attendance.list').toLocaleLowerCase()}`,
              icon: IconChoices.DOCUMENT,
              onClick: exportAttendaceList,
            },
            {
              label: translate('common.actions.edit'),
              icon: IconChoices.EDIT,
              onClick: openEditModal,
            },

            {
              label: translate('common.actions.delete'),
              icon: IconChoices.DELETE_TRASH,
              variant: ButtonVariant.DANGER,
              onClick: openDeleteConfirmationModal,
            },
          ]
        : undefined

    const errorView = !event
      ? {
          title: translate('eventDetailView.errors.notFound.title'),
          subtitle: translate('eventDetailView.errors.notFound.subtitle'),
          illustration: IllustrationChoices.EMPTY,
        }
      : undefined

    const tabs = [
      {
        title: translate('eventDetailView.tabBar.information'),
      },
      ...(event?.attendanceEnabled
        ? [
            {
              title: translate('eventDetailView.tabBar.attendance'),
            },
          ]
        : []),
    ]

    return (
      <ErrorBoundary>
        <ViewBase
          title={
            event?.title ||
            translate(
              boardRoomEvent
                ? 'boardCalendarView.title'
                : 'societyCalendarListView.title'
            )
          }
          showBackButton
          backUrl={reverseUrl(
            boardRoomEvent ? 'management:calendar' : 'society:calendar',
            {
              id: event?.societyId,
            }
          )}
          buttons={buttons}
          errorView={errorView}
          loading={loading}
          tabBar={{ tabs, setActiveTab, activeTab }}
        >
          {event && (
            <Routes>
              <Route
                path="information"
                element={
                  <EventDetailInformation
                    event={event}
                    boardRoomEvent={boardRoomEvent}
                    setActiveTab={setActiveTab}
                  />
                }
                errorElement={<RouteErrorView />}
              />
              <Route
                path="attendants"
                element={
                  <EventDetailAttendants
                    event={event}
                    boardRoomEvent={boardRoomEvent}
                  />
                }
                errorElement={<RouteErrorView />}
              />
            </Routes>
          )}
        </ViewBase>
        {showEditModal && (
          <CreateUpdateEventModal
            show={showEditModal}
            close={closeEditModal}
            event={event as SnapshotOut<typeof EventModel>}
            boardRoomEvent={boardRoomEvent}
          />
        )}
        {showDeleteConfirmationModal && (
          <RemoveEventModal
            title={`${translate(
              'createUpdateEvent.confirmationModal.deleteTitle'
            )}`}
            show={showDeleteConfirmationModal}
            close={closeDeleteConfirmationModal}
            event={event as SnapshotOut<typeof EventModel>}
            deleteMode
            boardRoomEvent={boardRoomEvent}
          />
        )}
      </ErrorBoundary>
    )
  }
)
