/* eslint-disable max-len */
import Translation from './types'

const sv: Translation = {
  common: {
    like: 'Gilla',
    likes: 'Gillar',
    makeComment: 'Kommentera',
    comment: 'Kommentar',
    comments: 'Kommentarer',
    settings: 'Inställningar',
    yes: 'Ja',
    no: 'Nej',
    options: 'Alternativ',
    active: 'Aktiv',
    in: 'i',
    time: 'Tid',
    description: 'Beskrivning',
    profile: 'Profil',
    loading: 'Laddar...',
    newFeature: 'Nyhet!',
    actions: {
      save: 'Spara',
      edit: 'Redigera',
      change: 'Ändra',
      delete: 'Radera',
      cancel: 'Avbryt',
      send: 'Skicka',
      publish: 'Publicera',
      update: 'Uppdatera',
      confirm: 'Bekräfta',
      create: 'Skapa',
      add: 'Lägg till',
      addMore: 'Lägg till fler',
      remove: 'Ta bort',
      accept: 'Acceptera',
      deny: 'Neka',
      continue: 'Fortsätt',
      leave: 'Lämna',
      join: 'Gå med',
      skip: 'Hoppa över',
      select: 'Välj',
      done: 'Klar',
      close: 'Stäng',
      show: 'Visa',
      tryAgain: 'Försök igen',
      later: 'Senare',
      logout: 'Logga ut',
      back: 'Tillbaka',
      invite: 'Bjud in',
      message: 'Meddelande',
      upload: 'Ladda upp',
      activate: 'Aktivera',
      copyText: 'Kopiera text',
      report: 'Rapportera',
      order: 'Beställ',
      pay: 'Betala',
      book: 'Boka',
      bookAsAdmin: 'Boka som admin',
      saveChanges: 'Spara ändringarna',
      clear: 'Rensa',
      download: 'Ladda ner',
    },
    form: {
      labels: {
        name: 'Namn',
        surname: 'Efternamn',
        email: 'Mejladress',
        message: 'Meddelande',
        entrance: 'Adress',
        entranceSamf: 'Väg',
        unitBrf: 'Lägenhetsnummer',
        unitSamf: 'Fastighetsbeteckning',
        unitRole: 'Roll',
        active: 'Aktiv',
        organisationNumber: 'Organisationsnummer',
        reference: 'Referens',
        society: 'Förening',
        password: 'Lösenord',
        role: 'Roll',
        addressCity: 'Postort',
        addressZip: 'Postnummer',
        addressStreet: 'Gatuadress',
        addressCareOf: 'C/o',
        type: 'Typ',
        from: 'Från',
        to: 'Till',
        discount: 'Rabatt',
        gender: 'Kön',
        phoneNumber: 'Telefonnummer',
      },
      helperTexts: {
        calculatedAutomatically: 'Beräknas automatiskt',
      },
      note: {
        required: '*Obligatoriskt',
        locked: 'Låst',
      },
    },
    fee: {
      annually: 'Årsvis',
      discountViaString: 'Rabatt via {{ partnerName }}',
    },
    boardDisabledFeature:
      'Föreningen har valt att avaktivera den här funktionen.',
    copiedToClipboard: 'Kopierat',
    unit: {
      unassigned: 'Icke tilldelad',
    },
    // TODO: Update translations
    emptyState: {
      title: 'Det finns inget att visa.',
      subtitle: 'Det finns för närvarande inget att visa på denna vy!',
    },
    platform: {
      webapp: 'Webbapp',
      mobileApp: 'Mobilapp',
    },
    eventActions: {
      attendance: {
        comment: 'Kommentar',
        attending: 'Kommer',
        notAttending: 'Kan inte komma',
        updateComment: 'Uppdatera Kommentar',
      },
    },
    contactActions: {
      call: 'Ring',
      email: 'Mejla',
      website: 'Hemsida',
    },
    contact: {
      phoneNumber: 'Telefonnummer',
      email: 'Mejladress',
      website: 'Hemsida',
    },
    days: {
      singular: 'dag',
      plural: 'dagar',
    },
    callout: {
      namedSocietyLocked: {
        title: '{{ societyName }} är låst',
      },
    },
    button: {
      save: 'Spara',
      send: 'Skicka',
      delete: 'Radera',
      next: 'Nästa',
      previous: 'Föregående',
      close: 'Stäng',
      addMore: 'Lägg till fler',
      toPayment: 'Till betalning',
    },
    other: 'Annat',
    none: 'Ingen',
    weekDays: {
      monday: 'Måndag',
      tuesday: 'Tisdag',
      wednesday: 'Onsdag',
      thursday: 'Torsdag',
      friday: 'Fredag',
      saturday: 'Lördag',
      sunday: 'Söndag',
    },
    languages: {
      english: 'Engelska',
      swedish: 'Svenska',
    },
    prefixes: {
      you: 'Du',
    },
    notificationSettings: {
      label: 'Informera boende',
      labelBoard: 'Informera styrelse',
      none: 'Nej',
      feed: 'Flöde',
      notificationAndFeed: 'Notiser & flöde',
    },
    user: {
      deletedUserName: 'Borttagen användare',
      admin: 'Admin',
      masterAdmin: 'Huvudadministratör',
    },
    numberOfunits: {
      apartmentBuilding: 'Antal lägenheter',
      boatClub: 'Antal medlemmar',
      economicAssociation: 'Antal fastigheter',
      homeownersAssociatoon: 'Antal fastigheter',
      housingCooperative: 'Antal lägenheter',
      housingCooperativeConstruction: 'Antal lägenheter',
      neighbourhood: 'Antal fastigheter',
      nonProfitAssociation: 'Antal fastigheter',
      shared: 'Antal nyttjare',
    },
    errorViews: {
      userNotInSociety: {
        title: 'Inte medlem i en förening',
        subtitle:
          'Ansök om att gå med i din förening under fliken "Förening" för att kunna chatta med dina grannar.',
      },
      pendingRequest: {
        title: 'Inväntar bekräftelse från styrelsen',
      },
    },
    singularPlural: {
      years: {
        singular: 'År',
        plural: 'År',
      },
      unit: {
        singular: 'Lägenhet',
        plural: 'Lägenheter',
        singularSamf: 'Fastighet',
        pluralSamf: 'Fastigheter',
      },
      like: {
        singular: 'Gillar',
        plural: 'Gillar',
      },
      connected: {
        singular: 'kopplad',
        plural: 'kopplade',
      },
      person: {
        singular: 'Person',
        plural: 'Personer',
      },
    },
    terms: {
      user: 'Användarvillkor',
      service: 'Tjänstevillkor',
      dataProcessing: 'Personuppgiftsbehandling',
    },
  },
  blockedCallout: {
    message:
      'Du har inte behörighet att publicera innehåll i {{ societyNames }}.',
  },
  societyType: {
    housingCooperative: 'Bostadsrättsförening',
    housingCooperativeConstruction: 'Projekt (bostadsutvecklare)',
    homeOwnersAssociation: 'Samfällighet',
    nonProfitAssociation: 'Ideell förening',
    economicAssociation: 'Ekonomisk förening',
    neighbourhood: 'Grannskap',
  },
  serviceView: {
    title: 'Erbjudanden',
    subtitle: 'Här hittar du förmånliga erbjudanden från partners till Boappa',
  },
  accordionSection: {
    defaultItemsEmptyText: 'Denna sektion är tom',
  },
  select: {
    noOptions: 'Inga alternativ',
  },
  offlineView: {
    title: 'Du är offline.',
    subtitle:
      'Se över din internetanslutning och försök igen genom att ladda om sidan.',
  },
  notFoundView: {
    title: 'Ojdå! Du har besökt en sida som inte finns.',
    subtitle:
      'Vi ber om ursäkt. Vänligen klicka på gå tillbaka och försök igen.',
    buttonLabel: 'Gå tillbaka',
  },
  unauthorizedView: {
    title: 'Du har inte tillåtelse att se denna sida.',
    subtitle:
      'Detta beror på att du inte har den roll som krävs för åtkomst av innehållet.',
    buttonLabel: 'Gå tillbaka',
  },
  chatView: {
    title: 'Chatt',
  },
  chatMessageReadBy: {
    readBy: 'Läst av',
    modalTitle: 'Meddelande läst av',
  },
  registerView: {
    title: 'Register',
    upload: 'Ladda upp register',
    tabBar: {
      entrances: 'Adresser',
      entrancesSamf: 'Vägar',
      unit: 'Lägenhetsnummer',
      unitSamf: 'Fastigheter',
      members: 'Medlemmar',
      nonMembers: 'Övriga användare',
      groups: 'Grupper',
    },
    unit: {
      labels: {
        entrance: 'Adress',
        entranceSamf: 'Väg',
        unit: 'Lägenhetsnummer',
        unitSamf: 'Fastighet',
        title: 'Lägenhetsnummer (föreningen)',
        titleSamf: 'Adress',
        titleLegal: 'Lägenhetsnummer (lantmäteriet)',
        titleLegalSamf: 'Fastighetsbeteckning',
        floor: 'Våning',
      },
      warnings: {
        noResidents: 'Det finns ingen boende kopplad till denna',
      },
    },
    group: {
      unitsSingular: 'lägenhetsnummer',
      unitsPlural: 'lägenhetsnummer',
      membersSingular: 'Medlem',
      membersPlural: 'Medlemmar',
    },
    emptyState: {
      member: {
        title: 'Inga användare tillagda.',
        noSearchResults: 'Inga sökresultat hittades.',
      },
    },
    alert: {
      removeUserTitle: 'Ta bort boende',
      removeUserText: 'Vill du ta bort {{ name }} från föreningen?',
    },
    flashMessage: {
      removeUserSuccess: 'Personen har tagits bort från föreningen',
      removeUserFailure: 'Personen kunde inte tas bort från föreningen',
    },
  },
  registerEntrance: {
    modal: {
      create: 'Lägg till address',
      createSamf: 'Lägg till väg',
      update: 'Uppdatera adress',
      updateSamf: 'Uppdatera väg',
    },
    form: {
      labels: {
        addressStreet: 'Adress',
        addressStreetSamf: 'Väg',
      },
    },
    numberOfUnits: {
      brf: 'Det finns {{ number }} {{ unitText }} {{connectText}} till denna adress',
      samf: 'Det finns {{ number }} {{ unitText }} {{connectText}} till denna väg',
    },
    confirmationDialog: {
      deleteTitle: 'Radera adress',
      deleteDescription: 'Är du säker på att du vill radera adressen?',
      deleteSamfTitle: 'Radera väg',
      deleteSamfDescription: 'Är du säker på att du vill radera vägen?',
    },
    warnings: {
      unableDeleteWithUnits:
        'Adresser med kopplade lägenhetsnummer kan inte raderas.',
      unableDeleteWithUnitsSamf:
        'Vägar med kopplade fastigheter kan inte raderas.',
      unableToDeleteWithUnassigned:
        'Kan inte ta bort denna adress. Den innehåller alternativet för otilldelat lägenhetsnummer, som kan användas för användare som inte har en angiven adress.',
      unableToDeleteWithUnassignedSamf:
        'Kan inte ta bort denna väg. Den innehåller alternativet för otilldelad fastighet, som kan användas för användare som inte har en angiven fastighet.',
    },
    flashMessage: {
      createEntranceFailure: 'Adressen kunde inte skapas',
      createEntranceSuccess: 'Adressen skapad',
      updateEntranceFailure: 'Adressen kunde inte uppdateras',
      updateEntranceSuccess: 'Adressen uppdaterad',
      deleteEntranceFailure: 'Adressen kunde inte tas bort',
      deleteEntranceSuccess: 'Adress borttagen',
      createEntranceSamfFailure: 'Vägen kunde inte skapas',
      createEntranceSamfSuccess: 'Väg skapad',
      updateEntranceSamfFailure: 'Vägen kunde inte uppdateras',
      updateEntranceSamfSuccess: 'Väg uppdaterad',
      deleteEntranceSamfFailure: 'Vägen kunde inte tas bort',
      deleteEntranceSamfSuccess: 'Väg borttagen',
    },
  },
  preApproved: {
    title: 'Inbjudningar',
    subtitle: 'Här kan du se alla inbjudningar som ännu inte blivit besvarade',
    buttons: {
      remindAllTitle: 'Påminn samtliga',
    },
    confirmationDialog: {
      deleteTitle: 'Återkalla inbjudan',
      deleteDescription: 'Vill du återkalla inbjudan?',
      remindTitle: 'Skicka påminnelse',
      remindDescription: 'Vill du skicka en påminnelse?',
      remindAllTitle: 'Skicka påminnelse',
      remindAllDescription:
        'Vill du skicka en påminnelseinbjudan till samtliga?',
    },
    flashMessage: {
      sendReminderFailure: 'Påminnelsen kunde inte skickas',
      sendReminderSuccess: 'Påminnelse skickad',
      remindAllFailure: 'Påminnelserna kunde inte skickas',
      remindAllSuccess: 'Samtliga påminnelser skickade',
      deletePreApprovedFailure: 'Inbjudan kunde inte återkallas',
      deletePreApprovedSuccess: 'Inbjudan återkallad',
    },
  },
  registerUnit: {
    modal: {
      create: 'Lägg till lägenhetsnummer',
      createSamf: 'Lägg till fastighet',
      update: 'Uppdatera lägenhetsnummer',
      updateSamf: 'Uppdatera fastighet',
      residents: 'Boende',
    },
    confirmationDialog: {
      deleteTitle: 'Radera lägenhetsnummer',
      deleteDescription: 'Är du säker på att du vill radera lägenhetsnumret?',
      deleteSamfTitle: 'Radera fastighet',
      deleteSamfDescription: 'Är du säker på att du vill radera fastigheten?',
    },
    flashMessage: {
      createUnitFailure: 'Lägenhetsnumret kunde inte skapas',
      createUnitSuccess: 'Lägenhetsnummer skapat',
      updateUnitFailure: 'Lägenhetsnumret kunde inte uppdateras',
      updateUnitSuccess: 'Lägenhetsnummer uppdaterat',
      deleteUnitFailure: 'Lägenhetsnumret kunde inte tas bort',
      deleteUnitSuccess: 'Lägenhetsnummer borttaget',
      createUnitSamfFailure: 'Fastigheten kunde inte skapas',
      createUnitSamfSuccess: 'Fastighet skapad',
      updateUnitSamfFailure: 'Fastigheten kunde inte uppdateras',
      updateUnitSamfSuccess: 'Fastighet uppdaterad',
      deleteUnitSamfFailure: 'Fastigheten kunde inte tas bort',
      deleteUnitSamfSuccess: 'Fastighet borttagen',
    },
  },
  registerRequest: {
    modal: {
      title: 'Medlemsförfrågningar',
    },
    flashMessage: {
      approveRequestSuccess: 'Förfrågan godkänd',
      approveRequestFailure: 'Förfrågan kunde inte godkännas',
      denyRequestSuccess: 'Förfrågan nekad',
      denyRequestFailure: 'Förfrågan kunde inte nekas',
    },
  },
  adminWebinar: {
    options: {
      gettingStarted: 'Kom-igång',
      intro: 'Intro',
      newBoard: 'Ny styrelsemedlem',
    },
  },
  registerExcelImport: {
    modal: {
      entranceUnitTitle: 'Lägenhetsregister',
      entranceUnitTitleSamf: 'Fastighetsregister',
      memberTitle: 'Medlemsregister',
      entranceUnitSubtitle: 'Uppdatera lägenhetsregister',
      entranceUnitSubtitleSamf: 'Uppdatera Fastighetsregister',
      entranceUnitDescription:
        'Bästa sättet att ändra många lägenheter är att ladda ner registret till Excel, göra ändringar där och sedan ladda upp igen till Boappa.',
      entranceUnitDescriptionSamf:
        'Bästa sättet att ändra många fastigheter är att ladda ner registret till Excel, göra ändringar där och sedan ladda upp igen till Boappa.',
      memberSubtitle: 'Uppdatera medlemsregister',
      memberDescription:
        '1. Ladda ner och fyll i medlemmarnas namn och mejladresser\n\n2. Ladda upp excelfilen och klicka på Spara\n\n3. Era medlemmar blir automatiskt inbjudna och kopplade till sina respektive lägenheter. Medlemmar som redan är inbjudna till Boappa kommer ej få en till inbjudan',
    },
    flashMessage: {
      uploadRegisterSuccess: 'Register uppladdat',
      uploadRegisterFailure: 'Registret kunde inte laddas upp',
    },
  },
  registerInvite: {
    modal: {
      inviteMember: 'Bjud in medlem',
      inviteNonMember: 'Bjud in övrig användare',
    },
    form: {
      unit: 'Lägenhetsnummer',
      unitSamf: 'Fastighet',
    },
    flashMessage: {
      inviteSentFailure: 'Inbjudan kunde inte skickas',
      inviteSentSuccess: 'Inbjudan skickad',
    },
  },
  registerGroup: {
    modal: {
      create: 'Skapa grupp',
      update: 'Uppdatera grupp',
    },
    form: {
      labels: {
        name: 'Gruppnamn',
      },
      options: {
        type: {
          unit: 'Lägenhet',
          unitSamf: 'Fastighet',
          user: 'Användare',
        },
      },
    },
    confirmationDialog: {
      deleteTitle: 'Radera grupp',
      deleteDescription: 'Är du säker på att du vill radera gruppen?',
      changeTypeTitle: 'Uppdatera typ',
      changeTypeDescription:
        'Val i befintlig grupptyp kommer att raderas. Är du säker på att du vill ändra grupptypen?',
    },
    flashMessage: {
      deleteSuccess: 'Grupp raderad',
      deleteFailure: 'Gruppen kunde inte raderas',
      createSuccess: 'Grupp skapad',
      createFailure: 'Gruppen kunde inte skapas',
      updateSuccess: 'Grupp uppdaterad',
      updateFailure: 'Gruppen kunde inte uppdateras',
    },
  },
  gdprView: {
    title: 'GDPR',
    content:
      'På Boappa håller vi dataskydd och personlig integritet högt. Vi arbetar ständigt för att våra användares personuppgifter hanteras på ett korrekt sätt och vi hjälper er som förening att skydda era medlemmars personuppgifter. \n\nDet legala ramverket för personuppgiftsbehandling är GDPR (dataskyddsförordningen). \n\nNedan finner ni information, tips och kontaktuppgifter till Boappas dataskyddsansvarig.',
    moreAbout: {
      title: 'Mer om GDPR',
    },
    personalDataAdvice: {
      title: 'Tips för er personuppgiftsbehandling',
    },
    boappaVendor: {
      title: 'Underleverantörer',
      description:
        'Det här är en lista över de underleverantörer som Boappa AB använder för att leverera tjänsten Boappa. Samtliga underleverantörer har anpassat sig för att följa GDPR.',
      vendors: {
        exoscale: {
          name: 'Exoscale',
          description: 'molntjänst för lagring av data med server inom EU/EES.',
        },
        billogram: {
          name: 'Billogram',
          description: 'tjänst som används för att skicka fakturor.',
        },
        mailchimp: {
          name: 'Mailchimp',
          description: 'tjänst för utgående mejlutskick.',
        },
        mixpanel: {
          name: 'Mixpanel',
          description:
            'statistiktjänst som används för att mäta aktiviteten i appen och förbättra tjänsten.',
        },
        firebase: {
          name: 'Google Firebase',
          description:
            'tjänst som används för att skicka notiser i mobilappen.',
        },
        cloudflare: {
          name: 'Cloudfare',
          description:
            'tjänst som används leverera innehåller i tjänsten till appen.',
        },
      },
    },
    dataProtectionOfficer: {
      title: 'Dataskyddsansvarig',
    },
  },
  societyInformationView: {
    title: 'Om föreningen',
    formSections: {
      societyPicture: 'Föreningsbild',
      societyTitle: 'Förening',
      addressTitle: 'Adress',
    },
    labels: {
      updateSocietyPicture: 'Uppdatera bild',
    },
  },
  societyContractsView: {
    title: 'Avtal',
    emptyState: {
      title: 'Det finns inga avtal',
      subtitle: 'Inga avtal har lagts till i föreningen.',
      noSearchResultsTitle: 'Hittade inga avtal',
      noSearchResultsSubtitle: 'Ändra ditt sökord och försök igen.',
    },
  },
  societyContractDetailView: {
    emptyState: {
      title: 'Avtalet kunde inte hittas',
      subtitle: 'Du försökte gå in på ett avtal som inte finns.',
    },
  },
  societyStatisticsExportsView: {
    title: 'Statistik & utdrag',
    tabBar: {
      statistics: 'Statistik',
      exports: 'Utdrag',
    },
    statistics: {
      headerTitle: 'Aktivitet',
      numberLogins: 'Totalt antal inloggade',
      numberPosts: 'Totalt antal inlägg i flödet',
      numberLikes: 'Totalt antal gillanden',
      numberComments: 'Totalt antal kommentarer',
    },
    exports: {
      residentList: {
        headerTitle: 'Boende',
        residentListDownload: 'Boendelistan',
      },
      bookingsList: {
        headerTitle: 'Bokningar av faciliteter',
      },
    },
  },
  contactsView: {
    title: 'Kontakter',
    emptyState: {
      title: 'Det finns inga kontakter',
      subtitle: 'Inga kontakter har lagts till i föreningen.',
      noSearchResultsTitle: 'Hittade inga kontakter',
      noSearchResultsSubtitle: 'Ändra ditt sökord och försök igen.',
    },
  },
  societyView: {
    title: 'Förening',
    emptyState: {
      noSociety: {
        title: 'Inte medlem i en förening',
        subtitle:
          'Ansök om att gå med i din förening genom att klicka på knappen nedan.',
        buttonTitle: 'Hitta förening',
      },
      pendingRequest: {
        title: 'Inväntar bekräftelse från styrelsen',
        subtitle:
          'Här kommer du kunna ta del av samlad information om din förening',
        buttonTitle: 'Hitta förening',
      },
    },
  },
  residentListView: {
    title: 'Boendelista',
    joined: 'Gick med',
  },
  adminRightsView: {
    title: 'Adminrättigheter',
    makeMasterAdminTitle: 'Gör till huvudadministratör',
    flashMessage: {
      adminRemoveSuccess: 'Administratör borttagen',
      adminRemoveFailure: 'Administratör kunde inte tas bort',
      masterAdminChangedSuccess: 'Huvudadministratör ändrad',
      masterAdminChangedFailure: 'Huvudadministratör kunde inte ändras',
    },
    alert: {
      deleteAdminTitle: 'Radera administratör',
      deleteAdminText: 'Är du säker på att du vill radera administratören?',
      updateMasterAdminTitle: 'Uppdatera huvudadministratör',
      updateMasterAdminText:
        'Är du säker på att du vill ändra huvudadministratör?',
    },
    emptyState: {
      title: 'Inga admins',
      subtitle: 'Det finns för närvarande inga angivna admins',
      noSearchResultsTitle: 'Hittade inga admins',
      noSearchResultsSubtitle: 'Ändra ditt sökord och försök igen.',
    },
  },
  boardContactsView: {
    title: 'Styrelsekontakter',
    emptyState: {
      title: 'Det finns inga kontakter',
      subtitle: 'Inga kontakter har lagts till i föreningen.',
      noSearchResultsTitle: 'Hittade inga kontakter',
      noSearchResultsSubtitle: 'Ändra ditt sökord och försök igen.',
    },
  },
  boardCalendarView: {
    title: 'Styrelsekalender',
    emptyState: {
      title: 'Luta dig tillbaka, inget inbokat i kalendern.',
      subtitle: 'Just nu finns det inga inplanerade kalenderhändelser.',
    },
  },
  contactCard: {
    flashMessage: {
      deleteContactSuccess: 'Kontakt borttagen',
      deleteContactFailure: 'Kontakten kunde inte tas bort',
    },
    alert: {
      deleteContactTitle: 'Radera kontakt',
      deleteContactText: 'Är du säker på att du vill radera kontakten?',
    },
    call: 'Telefon:',
    email: 'Mejl:',
    website: 'Webbplats:',
  },
  marketplaceListView: {
    title: 'Köp & sälj',
    subtitle: 'Köp, sälj, hyr och låna ut',
    emptyState: {
      title: 'Ingen annons uppplagd ännu',
      subtitle:
        'Lägg upp en annons för att påbörja Köp & sälj-sidan. Du kan enkelt ta bort annonsen sen om du ångrar dig.',
      subtitleMissingUnit:
        'För att skapa en annons behöver du ha tillhöra ett boende i förening.',
    },
    noListingFound: {
      title: 'Ingen annons matchade din sökning',
      subtitle:
        'Pröva ett annat sökord eller ta bort din sökning för att åter se alla annonser.',
    },
    buttons: {
      createListing: 'Ny annons',
    },
    item: {
      free: 'Gratis',
    },
    flashMessage: {
      deleteResourceSuccess: 'Annons borttagen',
      deleteResourceFailure: 'Annonsen kunde inte tas bort',
    },
  },
  functionSettingsView: {
    title: 'Funktioner',
    subtitle:
      'Stäng av eller sätt på funktioner som boende skall se i vänstermenyn “För boende”.',
    table: {
      titles: {
        function: 'Funktion',
        visible: 'Visas för boende',
      },
    },
    form: {
      labels: {
        function: 'Funktion',
        title: 'Titel',
        link: 'Länk',
        content: 'Innehåll',
      },
      widgetTypeOptions: {
        link: 'Länk',
        text: 'Text',
      },
    },
    buttons: {
      addFunction: 'Lägg till funktion',
    },
    modal: {
      createTitle: 'Skapa funktion',
      editTitle: 'Redigera funktion',
    },
    confirmationModal: {
      deleteTitle: 'Radera funktion',
      deleteDescription: 'Är du säker på att du vill radera funktionen?',
    },
    flashMessage: {
      deleteWidgetSuccess: 'Funktion borttagen',
      deleteWidgetFailure: 'Funktion kunde inte tas bort',
      updateWidgetSuccess: 'Funktion uppdaterad',
      updateWidgetFailure: 'Funktion kunde inte uppdateras',
      createWidgetSuccess: 'Funktion skapad',
      createWidgetFailure: 'Funktion kunde inte skapas',
    },
  },
  societyBlockedUsersView: {
    title: 'Blockerade användare',
    subtitle: `När en användare blockeras begränsas deras åtkomst till endast läsåtkomst. Det innebär att de kan se innehållet men kan inte skapa nya inlägg, kommentera befintliga inlägg eller använda chatten.
      
Detta bör endast användas i undantagsfall och en dialog med användaren bör föregå beslutet.`,
    flashMessage: {
      userBlockRemoveSuccess: 'Blockering borttagen',
      userBlockRemoveFailure: 'Blockeringen kunde inte tas bort',
      userBlockAddSuccess: 'Blockering tillagd',
      userBlockAddFailure: 'Blockeringen kunde inte läggas till',
    },
    alert: {
      deleteBlockTitle: 'Ta bort blockering',
      deleteBlockText: 'Är du säker på att du vill ta bort blockeringen?',
    },
    emptyState: {
      title: 'Inga blockeringar',
      subtitle: 'Det finns för närvarande inga blockerade användare',
    },
  },
  societySettingsView: {
    title: 'Inställningar',
    feedSettings: {
      title: 'Alternativ för flödet',
      disablePostingTitle: 'Stäng av boendeinlägg och kommentarer',
      disablePostingDisclaimerText:
        'Detta alternativ bör endast väljas i undantagsfall eftersom det hindrar era boende från att skapa värdefulla relationer.',
      marketplaceDisablePostingTitle:
        'Stäng av notis och inlägg för köp och sälj',
    },
    chatSettings: {
      title: 'Inställningar för chatt',
      disableOneOnOneTitle: 'Stäng av chatt mellan grannar',
      disableInterestsTitle: 'Stäng av gruppchatt-funktion',
      disableAskBoardTitle: 'Stäng av fråga styrelsen',
      chatAskBoardDisabledResidentMessageTitle: 'Meddelande till boende',
      chatAskBoardDisabledResidentMessagePlaceholder:
        'Vänligen kontakta styrelsen via mejl på info@...',
    },
    updateSettingsSuccess: 'Uppdaterade inställningarna',
  },
  supportView: {
    title: 'Support',
  },
  societyWebsiteView: {
    title: 'Vår hemsida',
    subTitle:
      'Informationen ni anger här visas på er publika föreningshemsida. Det är ni som förening som ansvarar för innehållet. Läs mer ',
    subTitleLinkText: 'här',
    visitWebsite: 'Besök hemsidan',
    tabBar: {
      generalInformation: 'Namn och beskrivning',
      images: 'Bilder',
      documents: 'Dokument',
      questionsAndAnswers: 'Frågor och svar',
      contact: 'Kontakt',
    },
  },
  societyWebsiteGeneralInformation: {
    form: {
      displayName: 'Namn',
      description: 'Presentation',
    },
    flashMessage: {
      updateSuccess: 'Informationen om hemsidan uppdaterades',
      updateFailure: 'Informationen om hemsidan kunde inte uppdateras',
    },
  },
  societyWebsiteContact: {
    form: {
      recipientId: 'Styrelsemedlem',
      recipientLabel: 'Mottagare',
      addMore: 'Lägg till fler',
      copy: 'Kopia',
      copyTooltip:
        'Här lägger du till de mejladresser som ska få en kopia av meddelanden skickade från kontaktformuläret på hemsidan',
    },
    flashMessage: {
      updateSuccess: 'Kontakt uppdaterades',
      updateFailure: 'Kontakt kunde inte uppdateras',
    },
    confirmationModal: {
      removeEmailTitle: 'Radera mejladress',
      removeEmailText: 'Är du säker på att du vill radera mejladressen?',
    },
  },
  societyWebsiteQuestionsAndAnswers: {
    flashMessage: {
      updateSuccess: 'Frågor och svar uppdaterades',
      updateFailure: 'Frågor och svar kunde inte uppdateras',
    },
  },
  societyWebsiteDocuments: {
    addNewDocument: 'Ladda upp dokument',
    addNewFolder: 'Skapa ny mapp',
    emptyState: {
      title: 'Du har inget att läsa',
      emptyFolderSubtitle:
        'Just nu finns det inga dokument publicerade i "{{folderName}}".',
      noDocumentsSubtitle:
        'Just nu finns det inga mappar eller dokument publicerade.',
    },
  },
  societyWebsite: {
    showOnWebsite: 'Visa på hemsidan',
  },
  supportContact: {
    infoText: 'Har du en fråga eller synpunkt? Skicka ett meddelande!',
    form: {
      subject: 'Rubrik',
      subjectPlaceholder: 'Ange en kort rubrik för ditt ärende',
      message: 'Meddelande',
      messagePlaceholder: 'Beskriv ditt ärende',
      send: 'Skicka meddelande',
      whichPlatform: 'Vilken plattform använde du när problemet inträffade?',
    },
  },
  bostadsratterna: {
    bostadsratternaBenefitsView: {
      title: 'Medlemsförmåner',
      benefitsTitle: 'Medlemsförmåner',
      benefitsText:
        'För dig som medlem ingår en rad olika medlemsförmåner hos oss (inloggning krävs).',
    },
    bostadsratternaSchoolView: {
      title: 'Bostadsrättsskolan',
      coursesTitle: 'Kurser',
      coursesText: 'Utbildningar om allt ni behöver veta om styrelsearbetet.',
      seminarsTitle: 'Seminarier',
      seminarsText: 'Kortare utbildningar för ditt uppdrag i föreningen.',
      webAndVideoCoursesTitle: 'Webb- och videokurser',
      webAndVideoCoursesText:
        'Nya interaktiva webbkurser som gör det kul och enkelt att lära.',
    },
    bostadsratternaStatutesView: {
      title: 'Mönsterstadgar',
      statutesTitle: 'Stadgemall',
      statutesText:
        'Som medlem får du fri tillgång till våra mönsterstadgar (inloggning krävs).',
    },
    bostadsratternaGuidanceView: {
      title: 'Styrelserådgivning',
      guidanceText: 'Kostnadsfri rådgivning vardagar 09:00-16:30',
    },
    bostadsratternaFormsView: {
      title: 'Blanketter',
      formsTitle: 'Praktiska blanketter',
      formsText:
        'Blanketter för avtal, ansökningar och annat som behövs i föreningen (inloggning krävs).',
    },
  },
  samfalligheterna: {
    samfalligheternaSchoolView: {
      title: 'Utbildningar',
      description:
        'Det finns en mängd detaljerade regler för en samfällighetsförening: om medlemsavgifter, hur beslut ska fattas och vad en samfällighet får ägna sig åt. Har ni fått nya personer i styrelsen, eller är osäkra på vad som gäller, är det en bra idé att gå någon av våra utbildningar.',
      coursesTitle: 'Kurser',
      coursesText:
        'På våra utbildningar lär du dig allt du behöver veta för att sköta en samfällighetsförening (inloggning krävs).',
    },
    samfalligheternaBenefitsView: {
      title: 'Medlemsförmåner',
      description:
        'Vårt mål är att styrelsearbetet i din förening ska vara så smidigt och glädjefullt som möjligt. I kraft av vår storlek har vi fixat bra rabatter och förmåner hos några av Sveriges mest välkända företag. Så att ni kommer undan lite billigare när ni ska köpa något som hör föreningen till.',
      benefitsTitle: 'Förmåner och rabatter',
      benefitsText:
        'Exklusiva erbjudanden till föreningar med trygghetsavtal (inloggning krävs).',
    },
    samfalligheternaGuidanceView: {
      title: 'Styrelserådgivning',
      guidanceDescription:
        'Få kostnadsfri rådgivning hos våra jurister, lantmätare, byggnadstekniker, geotekniker, energi-, vvs- och trädgårdsrådgivare.',
      guidanceText:
        'Kostnadsfri rådgivning 08:30-16:30 måndag-torsdag, 08:30-14:00 fredag',
    },
  },
  managementPartnerContactForm: {
    writeQuestionTitle: 'Skriv din fråga',
    writeQuestionPlaceholder: 'Meddelande',
    sendQuestionTitle: 'Skicka fråga',
    shareInformationTitle: 'Dela information',
    shareInformationTooltipBostadsratterna:
      'Kontaktuppgifter till dig och din förening delas med bostadsrätterna',
    shareInformationTooltipSamfalligheterna:
      'Kontaktuppgifter till dig och din förening delas med samfälligheterna',
    helperText: 'Vår målsättning är att svara inom 24 timmar.',
    messageSendFailure: 'Meddelandet kunde inte skickas',
    messageSendSuccess: 'Meddelandet har skickats',
  },
  feedView: {
    title: 'Flöde',
    newPost: 'Skriv ett inlägg...',
    modalTitle: 'Nytt inlägg',
    create: 'Skapa',
    filterFeed: {
      title: 'Filtrera inlägg',
      subtitle: 'Välj vad du vill se i ditt flöde',
      primaryButtonTitle: 'Bekräfta',
      postsFilterTitle: 'Inlägg',
      postsFilterAllOptionValue: 'Visa alla',
      postsFilterBoardOptionValue: 'Inlägg från styrelsen',
      postsFilterNeighboursOptionValue: 'Inlägg från grannar',
      postsFilterImportantOptionValue: 'Viktiga meddelanden',
      societiesFilterTitle: 'Filtrera föreningar',
      societiesFilterAllOptionValue: 'Visa alla',
    },
    emptyFilter: {
      title: 'Inga inlägg hittades',
      subtitle: 'Ändra dina filter eller skapa ett nytt inlägg',
    },
    emptyState: {
      noSociety: {
        title: 'Inte medlem i en förening',
        subtitle:
          'Ansök om att gå med i din förening genom att klicka på knappen nedan.',
        buttonTitle: 'Hitta förening',
      },
      pendingRequest: {
        title: 'Inväntar bekräftelse från styrelsen',
        subtitle:
          'Här kommer du kunna gilla, kommentera och skapa inlägg samt ta del av viktig information från styrelsen.',
        buttonTitle: 'Hitta förening',
      },
      emptyFeed: {
        title: 'Det finns inga inlägg i flödet',
        subtitle:
          'Här kommer du kunna gilla, kommentera och skapa inlägg samt ta del av viktig information från styrelsen.',
        buttonTitle: 'Skapa inlägg',
      },
    },
    formatText: {
      title: 'Ange nedanstående för att formatera text',
      label: 'Textformatering',
      bold: 'Fetstil',
      italic: 'Kursiv',
      header: 'Rubrik',
      bulletPoints: 'Punktlista',
      list: 'Lista',
      horizontalLine: 'Horisontell linje',
    },
  },
  userView: {
    dateTitle: 'Medlem sedan',
    interestsTitle: 'Intressen',
    educationTitle: 'Utbildning',
    occupationTitle: 'Arbete',
    presentationTitle: 'Om {{name}}',
  },
  postView: {
    errorView: {
      removed: {
        title: 'Inlägget är borttaget',
        subtitle:
          'Inlägget har raderats det. Klicka på knappen ovan för att återgå till flödet.',
      },
    },
  },
  post: {
    important: 'Viktigt meddelande',
    importantMobile: 'Viktigt',
    showMoreComments: 'Visa fler kommentarer',
    commentInputPlaceholder: 'Skriv en kommentar...',
    editedPrefix: 'Redigerat',
    event: {
      prefix: 'Händelse',
      allDay: 'Heldag',
      goToEvent: 'Gå till händelse',
      addToCalendar: 'Lägg till i kalender',
    },
    alert: {
      postDeleteTitle: 'Radera inlägg',
      postDeleteText:
        'Är du säker på att du vill radera inlägget? Denna handling går ej att ångra och inlägget försvinner för samtliga användare i föreningen.',
      commentDeleteTitle: 'Radera kommentar',
      commentDeleteText: 'Är du säker på att du vill radera kommentaren?',
    },
    flashMessage: {
      deletePostSuccess: 'Inlägg raderat',
      deletePostFailure: 'Inlägget kunde inte raderas',
      deleteCommentSuccess: 'Kommentar raderad',
      deleteCommentFailure: 'Kommentaren kunde inte raderas',
    },
    newMarketPlaceItemText: 'Ny annons i köp & sälj',
    newFacilityText: 'En ny facilitet har lagts upp',
    newContactText: 'En ny kontakt har lagts till',
    newLocalOfferText: 'En ny förmån har lagts till',
    tooltip: {
      visibility: {
        base: 'Inlägget visas för',
        admins: 'administratörer',
        board: 'styrelsen',
        groups: 'grupperna',
      },
    },
    seenBy: 'Läst av',
  },
  previewPost: {
    title: 'Förhandsgranska inlägg',
    preview: 'Förhandsgranska',
  },
  pendingRequest: {
    title: 'Toppen!',
    subtitle:
      'Du får tillgång till föreningen så snart styrelsen godkänner uppgifterna.',
    titleAdvice: 'Tips: Du kan redan nu kolla in webbappen i förväg.',
    whatHappensNextTitle: 'Vad sker härnäst?',
    notificationText:
      'När du blir godkänd får du både en notis i webbappen och ett mail. \nFölj instruktionerna i mailet eller öppna webbappen och börja Boappa!',
    assignmentText: 'Om du inte blivit godkänd - kontakta din styrelse.',
    checkOutTheApp: 'Kolla in webbappen!',
  },
  requestJoinSocietyForm: {
    sendRequest: 'Skicka förfrågan',
    titleBrf: 'Välj din lägenhet',
    titleSamf: 'Välj din fastighet',
    flashMessage: {
      sendRequestSuccess: 'Förfrågan skickas',
      sendRequestFailure: 'Förfrågan kunde inte skickas',
    },
    tooltip: {
      unit: 'De 4 första siffrorna i ditt lägenhetsnummer är angett av Lantmäteriet \n\nStår siffrorna inom parentes ( ) har din förening angett numret',
      role: 'Medlem: Du som äger bostadsrätten och är därmed medlem i föreningen \n\nInneboende: Om du är exempelvis sambo, partner, vän, inneboende, barn till ägaren av lägenheten. \n\nHyresgäst: Du hyr bostadsrätten i andra hand. \n\nAnhörig: Person som representerar medlemmen.',
    },
  },
  inactiveSociety: {
    title: 'Föreningen är inte aktiverad',
    subtitle:
      'För att aktivera föreningen behöver du vara styrelseledamot. Du kan gå med i föreningen som medlem för att testa men du kommer då inte ha tillgång till all funktionalitet i appen.',
    tabs: {
      board: 'Styrelsemedlem',
      member: 'Medlem',
    },
    board: {
      boardTitle: 'Alternativ 2: Gå med som styrelsemedlem',
      boardSubtitle:
        'Sitter du i styrelsen men önskar att ej bli administratör kan du välja att enbart gå med i föreningen. Du kommer inte ha tillgång till all funktionalitet i appen.',
      adminTitle: 'Alternativ 1: Gå med som administratör',
      adminSubtitle:
        'Aktivera din förening och få full funktionalitet i appen. Du tilldelas då rollen huvudadministratör för din förening och du kan därefter utse fler administratörer.',
    },
    member: {
      title: 'Gå med i förening',
      subtitle:
        'Föreningen är ännu inte aktiverad men det är fritt fram att gå med i den oavsett om du är boende eller styrelsemedlem.',
    },
    flashMessage: {
      joinSocietySuccess: 'Gick med i förening',
      joinSocietyFailure: 'Kunde inte gå med i förening',
      activateSocietyFailure: 'Kunde inte aktivera förening',
      activateSocietySuccess: 'Förening aktiverades',
    },
  },
  findSociety: {
    labels: {
      newSociety: 'Ny förening',
      userMemberOfSociety: 'Inloggad',
      hasPendingRequest: 'Förfrågan skickad',
      search: 'Sök på namn, adress eller org.nr',
      brfDeveloper: 'Bostadsutvecklare?',
      cannotFindSociety:
        'Hittar du inte din förening eller vill du lägga till ett nytt grannskap?',
    },
  },
  createSocietyView: {
    title: 'Skapa ny förening',
    tabs: {
      housingCooperative: 'Styrelsen i min brf',
      homeownersAssociation: 'Styrelsen i min samfällighet',
      realEstateDeveloper: 'Bostadsutvecklare',
    },
  },
  createSocietyForm: {
    createSocietyTitle: 'Skapa förening',
    realEstateDeveloperTitle: 'Skapa ny förening',
    neighbourhoodTitle: 'Grannskap',
    samfTitle: 'Skapa er samfällighetsförening',
    brfTitle: 'Skapa er bostadsrättsförening',
    societyOrProjectTitle: 'Förening/projekt',
    societyNameTooltipTitle: 'Standardförkortningar',
    societyNameTooltipText:
      'Anläggningssamfällighet = As\nBostadsförening = Bf\nBostadsrättsförening = Brf\nEkonomisk förening = Ek för\nGemensamhetsanläggning = Ga\nSamfällighetsförening = Samf\nUtan personligt ansvar = Upa\nVägsamfällighet = Vägsam\nVägförening = Vägf',
    headers: {
      realEstateDeveloper: 'Bostadsutvecklare',
      society: 'Förening/projekt',
      societyAlreadyExists: 'Din förening finns redan i Boappa',
    },
    labels: {
      numberOfBillingUnits: 'Antal lägenheter',
      numberOfBillingUnitsSamf: 'Antal fastigheter',
      societyName: 'Föreningens namn',
    },
    errors: {
      societyAlreadyActivated: 'Föreningen är redan aktiverad',
    },
    modal: {
      title: 'Din förening finns redan i Boappa',
      organisationNumberShort: 'Org nr',
      alreadyMemberOfSociety: 'Du är redan medlem i denna förening.',
      societyExistsButInactive: 'Föreningen finns redan men är inaktiv',
    },
    flashMessage: {
      createSocietySuccess: 'Föreningen har skapats',
      createSocietyFailure: 'Föreningen kunde inte skapas',
    },
  },
  findSocietyConfirmActivationForm: {
    roleTitle: 'Vad är din roll i styrelsen?',
    inviteBoardMembersTitle:
      'Bjud in dina styrelsekollegor för att testa Boappa tillsammans (valfritt)',
    numberOfApartmentsTitle: 'Totala antalet lägenheter',
    numberOfApartmentsTitleSamf: 'Totala antalet fastigheter',
    numberOfApartmentsDescription:
      'Uppge det totala antalet lägenheter som ingår i föreningen. Inkludera även kommersiella och uthyrda lägenheter.',
    numberOfApartmentsDescriptionSamf:
      'Uppge det totala antalet fastigheter som ingår i föreningen. Inkludera även kommersiella och uthyrda fastigheter.',
    enterEmail: 'Ange mejladress',
    flashMessage: {
      invitesSentSuccess: 'Inbjudningar skickade',
    },
  },
  findSocietyConfirmActivationView: {
    title: 'Snart klart!',
    subtitle:
      'Vi behöver kompletterande information från dig innan du kan börja Boappa.',
  },
  activatedSocietyConfirmationView: {
    title: 'Föreningen är aktiverad!',
    subtitle:
      'Du är nu registrerad som huvudaministratör och kan bjuda in styrelsemedlemmarna för att testa Boappa.',
    buttonTitle: 'Börja Boappa!',
  },
  findSocietyView: {
    title: 'Lägg till ny förening',
  },
  findFriendsView: {
    title: 'Lär känna dina grannar',
    description:
      'Hitta en löparkompis i porten bredvid eller få sällskap på barnvagnspromenaderna av grannen längre ned på gatan.',
    extra:
      'Lägg in dina intressen i din profil och se vilka grannar som delar dem.',
    updateYourProfile: 'Uppdatera din profil',
    errorView: {
      noMatches: {
        title: 'Hittade inga matchingar',
        subtitle:
          'Uppdatera din profil och ta reda på vem som delar samma intressen som dig',
      },
    },
  },
  questionsAndAnswersView: {
    title: 'Frågor & Svar',
    emptyState: {
      title: 'Det finns inga frågor och svar',
      subtitle: 'Styrelsen har inte lagt upp några frågor och svar ännu.',
      noQuestionsAndAnswersText:
        'Det finns inga frågor och svar för denna sektion.',
    },
  },
  societyCalendarListView: {
    title: 'Kalender',
    buttons: {
      create: 'Ny kalenderhändelse',
    },
    activeTabBar: {
      previousTitle: 'Tidigare',
      upcomingTitle: 'Kommande',
    },
    noCalendarEvents: {
      title: 'Luta dig tillbaka, inget inbokat i kalendern.',
      subtitle: 'I dagsläget finns inga kalenderhändelser att visa.',
    },
  },
  eventDetailView: {
    errors: {
      notFound: {
        title: 'Kalenderhändelsen kunde inte hittas',
        subtitle: 'Du försökte gå in på en kalenderhändelse som inte finns.',
      },
    },
    tabBar: {
      information: 'Information',
      attendance: 'Deltagarlista',
    },
    headers: {
      date: 'Datum',
      attendants: 'Deltagare',
    },
    attendance: {
      titles: {
        attending: 'Kommer',
        notAttending: 'Kan inte komma',
        noReply: 'Inget svar',
      },
      list: 'Deltagarlista',
    },
    buttons: {
      seeAllAttendants: 'Se alla deltagare',
    },
  },
  createUpdateEvent: {
    addToCalendar: 'Lägg till i kalender',
    modal: {
      createTitle: 'Skapa händelse',
      editTitle: 'Redigera händelse',
    },
    confirmationModal: {
      deleteTitle: 'Radera händelse',
      deleteDescription: 'Är du säker på att du vill radera händelsen?',
    },
    form: {
      labels: {
        title: 'Aktivitet',
        location: 'Plats',
        isAllDay: 'Heldag',
        startDate: 'Starttid',
        endDate: 'Sluttid',
        startDay: 'Startdag',
        endDay: 'Slutdag',
        description: 'Beskrivning',
        attendanceEnabled: 'Deltagarlista',
      },
    },
    flashMessage: {
      updateEventSuccess: 'Händelse uppdaterad',
      updateEventFailure: 'Händelsen kunde inte uppdateras',
      createEventSuccess: 'Händelse skapad',
      createEventFailure: 'Händelsen kunde inte skapas',
      deleteEventSuccess: 'Händelse raderad',
      deleteEventFailure: 'Händelsen kunde inte raderas',
    },
  },
  facilitiesView: {
    title: 'Bokningar & Faciliteter',
    tabBar: {
      newBooking: 'Ny bokning',
      myBookings: 'Mina bokningar',
    },
    emptyState: {
      noFacilitiesTitle: 'Det finns inget att boka',
      noFacilitiesSubtitle:
        'Styrelsen har inte lagt in några gemensamma faciliteter för digital bokning. Har du förslag på ytor eller föremål som kan bokas får du gärna tipsa styrelsen via chatten och fliken “Fråga styrelsen”.',
      noBookingsTitle: 'Du har inga bokningar',
      noBookingsSubtitle:
        'Gå till “Ny bokning” och välj den facilitet du vill boka. Därefter kommer din bokning dyka upp här.',
    },
    buttons: {
      create: 'Skapa facilitet',
    },
  },
  createUpdateFacility: {
    editFacilityTitle: 'Redigera facilitet',
    createFacilityTitle: 'Skapa facilitet',
    form: {
      labels: {
        booking: 'Bokningsbar',
        coverPhoto: 'Omslagsbild',
        name: 'Titel',
        description: 'Beskrivning',
        bookingGranularity: 'Bokningsintervall',
        rules: 'Bokningsregler',
        bookableFrom: 'Bokningsbar från',
        bookableTo: 'Bokningsbar till',
        bookableUntilFurtherNotice: 'Tillsvidare',
        visibility: 'Synlighet',
        bookingMessage: 'Bokningsmeddelande',
        notificationEmails: 'Notifiering vid bokning och avbokning',
        blockedDates: 'Blockerade datum',
        weeklySchedule: 'Veckoschema',
      },
      helperText: {
        coverPhoto: 'Rekommenderad minsta bildstorlek: 1280x800 pixlar',
      },
      headers: {
        general: 'Allmänt',
        details: 'Bokningsdetaljer',
        notificationSettings: 'Notifikationsinställningar',
        recipient: 'Mottagare',
        cc: 'Kopia',
        day: 'Dag',
        startTime: 'Start',
        endTime: 'Slut',
        active: 'Aktiv',
      },
      warnings: {
        bookingGranularity: {
          cantEdit:
            'Du kan inte ändra schemat när faciliteten har aktiva bokningar.',
        },
      },
      options: {
        visibility: {
          all: 'Alla',
          adminOnly: 'Endast administratörer',
        },
        bookingGranularity: {
          oneHour: 'En timme',
          twoHours: 'Två timmar',
          threeHours: 'Tre timmar',
          fourHours: 'Fyra timmar',
          fiveHours: 'Fem timmar',
          fullDay: 'Heldag',
        },
        rules: {
          oneActive: 'En aktiv bokning',
          twoActive: 'Två aktiva bokningar',
          threeActive: 'Tre aktiva bokningar',
          fourActive: 'Fyra aktiva bokningar',
          fiveActive: 'Fem aktiva bokningar',
          unlimited: 'Fritt antal bokningar',
        },
      },
    },
    flashMessage: {
      updateFacilityFailure: 'Faciliteten kunde inte uppdateras',
      updateFacilitySuccess: 'Faciliteten har uppdaterats',
      createFacilityFailure: 'Faciliteten kunde inte skapas',
      createFacilitySuccess: 'Faciliteten har skapats',
      deleteFacilitySuccess: 'Facilitet borttagen',
      deleteFacilityFailure: 'Facilitet kunde inte tas bort',
    },
    confirmationModal: {
      deleteTitle: 'Radera facilitet',
      deleteDescription: 'Är du säker på att du vill radera faciliteten?',
    },
    weeklyScheduleDescription:
      'Tryck på siffrorna i schemat, för att ändra start- eller sluttid. För att ställa in heldagsbokning, sätt upp tiderna 00:00 - 00:00.',
  },
  facilityView: {
    tabBar: {
      calendar: 'Kalender',
      information: 'Information',
      bookings: 'Bokningar',
    },
    calendar: {
      sections: {
        chooseUnit: {
          title: 'Vilken lägenhet vill boka?',
          titleSamf: 'Vilken fastighet vill boka?',
        },
        chooseDate: {
          title: 'Välj datum',
        },
        chooseSlot: {
          title: 'Välj tid',
        },
        bookingMessage: {
          title: 'Bokningsmeddelande',
        },
        yourBooking: {
          title: 'Dina bokningar',
        },
      },
      fullDay: 'Heldag',
      booked: 'Bokad',
      alert: {
        unitMaxNumberBookings:
          'Du har uppnått maximalt antal aktiva bokningar för facilititen.',
        noTimeSlots: 'Det finns inga lediga tider för den valda dagen.',
        noUnit: 'För att göra en bokning måste du vara tilldelad en lägenhet.',
        noUnitSamf:
          'För att göra en bokning måste du vara tilldelad en fastighet.',
        bookingDisabledText: 'Faciliteten går inte att boka.',
      },
      flashMessage: {
        createBookingSuccess: 'Bokad',
        createBookingFailure: 'Bokningen kunde inte skapas',
      },
    },
    information: {
      sections: {
        description: {
          title: 'Beskrivning',
          noDescriptionText: 'Faciliteten har ingen beskrivning.',
        },
      },
    },
    bookings: {
      buttons: {
        previousMonth: 'Föregående månad',
        nextMonth: 'Nästa månad',
        deleteBooking: 'Avboka tid',
      },
      errorView: {
        noBookings: {
          title: 'Det finns inga bokningar',
          subtitle: 'Gå till fliken "Kalender" för att boka denna facilitet.',
        },
      },
      alert: {
        deleteTitle: 'Avboka tid',
        deleteDescription: 'Är du säker på att du vill ta bort bokningen?',
        deleteOtherUserBookingDescription:
          'Är du säker på att du vill ta bort bokningen gjord av {{ name }}?',
        deleteBookingButtonLabel: 'Avboka',
      },
      flashMessage: {
        deleteBookingFailure: 'Tiden kunde inte avbokas. Tiden kan ha passerat',
        deleteBookingSuccess: 'Avbokad',
      },
    },
  },
  houseRulesView: {
    title: 'Trivselregler',
    emptyState: {
      title: 'Det finns inga trivselregler',
      subtitle: 'När styrelsen skapar nya regler så dyker de upp här.',
    },
  },
  societyInviteModal: {
    title: 'Vi har hittat ditt hem!',
    buttons: {
      accept: 'Ja, det stämmer!',
      deny: 'Neka inbjudan',
    },
    flashMessage: {
      acceptPreApprovedSuccess: 'Föreningen har lagts till',
      acceptPreApprovedFailure: 'Föreningen kunde inte läggas till',
      denyPreApprovedSuccess: 'Inbjudan har nekats',
      denyPreApprovedFailure: 'Inbjudan kunde inte nekas',
    },
  },
  reportProblemView: {
    title: 'Felanmälan',
    form: {
      labels: {
        space: 'Var har felet uppstått?',
        message: 'Beskriv ditt ärende',
        images: 'Bilder',
      },
    },
    flashMessage: {
      createReportSuccess: 'Felanmälan skickad',
      createReportFailure: 'Felanmälan kunde inte skickas',
    },
    helperTexts: {
      contactInformation: 'Dina kontaktuppgifter bifogas automatiskt.',
    },
    errorView: {
      title: 'Epost saknas för felanmälan',
      subtitle: 'Ange en epost för att aktivera felanmälan',
    },
  },
  reportProblemSettings: {
    modal: {
      title: 'Inställningar för felanmälan',
    },
    form: {
      headers: {
        recipient: 'Mottagare',
        cc: 'Kopia',
      },
    },
  },
  localOffersView: {
    title: 'Förmåner',
    emptyState: {
      title: 'Inga förmåner',
      subtitle: 'Föreningen har ännu inte lagt till några förmåner.',
    },
    modal: {
      create: 'Skapa ny förmån',
      update: 'Uppdatera förmån',
    },
    form: {
      labels: {
        benefit: 'Förmån',
        body: 'Beskrivning',
        companyName: 'Företagsnamn',
      },
      placeholders: {
        discount: '20% rabatt',
      },
      headers: {
        contactDetails: 'Kontaktuppgifter',
      },
    },
    flashMessage: {
      createLocalOfferSuccess: 'Förmån skapad',
      createLocalOfferFailure: 'Förmånen kunde inte skapas',
    },
  },
  localOfferView: {
    title: 'Förmån',
    confirmationModal: {
      deleteTitle: 'Radera förmån',
      deleteDescription: 'Är du säker på att du vill radera förmånen?',
    },
    errors: {
      notFound: {
        title: 'Förmånen kunde inte hittas',
        subtitle: 'Du försökte gå in på en förmån som inte finns.',
      },
    },
    flashMessage: {
      updateLocalOfferSuccess: 'Förmån uppdaterad',
      updateLocalOfferFailure: 'Förmånen kunde inte uppdateras',
      deleteLocalOfferSuccess: 'Förmån raderad',
      deleteLocalOfferFailure: 'Förmånen kunde inte raderas',
    },
  },
  marketplaceDetailView: {
    labels: {
      published: 'Publicerad',
      createdBy: 'Upplagd av',
    },
    confirmationModal: {
      deleteTitle: 'Radera annons',
      deleteDescription: 'Är du säker på att du vill radera annonsen?',
    },
    errors: {
      notFound: {
        title: 'Annonsen kunde inte hittas',
        subtitle: 'Du försökte gå in på en annons som inte finns.',
      },
    },
    flashMessage: {
      updateListingSuccess: 'Annons uppdaterad',
      updateListingFailure: 'Annonsen kunde inte uppdateras',
      deleteListingSuccess: 'Annons raderad',
      deleteListingFailure: 'Annonsen kunde inte raderas',
    },
  },
  createUpdateHouseRuleForm: {
    labels: {
      subject: 'Ämne',
      rule: 'Regel',
    },
  },
  invoiceView: {
    title: 'Fakturering',
    subtitle: 'Hantera dina fakturor och betalningsalternativ',
    tabBar: {
      payment: 'Betalning',
      details: 'Uppgifter',
    },
    neighbourhood: {
      title: 'Grannskap använder Boappa gratis!',
    },
    details: {
      title: 'Fakturainformation',
      alert: 'Fakturor skickas via mejl',
      form: {
        labels: {
          recipient: 'Fakturamottagare',
          billingStreet: 'Fakturaadress',
        },
      },
    },
    basic: {
      remember: 'Kom ihåg!',
      payYourAnnualFee: 'Betala årsavgiften.',
      locked: {
        title: 'Föreningen är låst.',
        subtitle: 'Betala årsavgiften för att fortsätta använda Boappa.',
      },
    },
    premium: {
      great: 'Toppen!',
      annualFeePayed: 'Ert abonnemang är aktivt.',
      paymentPlan: 'Betalplan',
      invoiceSentTo: 'Fakturan skickas till',
      untilNextPayment: 'till nästa betalning',
    },
    commonCard: {
      annualFee: 'Årsavgift',
      excludingVat: 'Exklusive moms',
      buttons: {
        useCode: 'Använd kod',
      },
      missingEmail: {
        title: 'Mejladress saknas.',
        description: 'Innan du betalar måste du ',
        link: 'uppdatera din mejladress',
      },
    },
    accordions: {
      title: 'Relaterade frågor',
      premium: {
        sections: {
          discountCode: {
            title: 'Har du använt en rabattkod?',
            text: 'Rabattkoden gäller tillsvidare och så länge samarbetet med partnern är aktivt.',
          },
        },
      },
      basic: {
        sections: {
          bostadsratterna: {
            title: 'Medlem hos Bostadsrätterna',
            text: 'Aktuell rabattkod finns i inloggat läge på Bostadsrätternas hemsida. Erbjudandet gäller så länge medlemskapet är aktivt.',
          },

          samfalligheterna: {
            title: 'Samfälligheterna',
            text: 'Mejla samfalligheter@villaagarna.se för att få er rabattkod.',
          },
        },
      },
      common: {
        sections: {
          paymentInstructions: {
            title: 'Betalningsinstruktioner',
            preLinkText: 'Läs guiden med',
          },
          termsOfService: {
            title: 'Tjänstevillkor',
            preTermsText: 'Tjänsten levereras i enlighet med våra',
            preDataProcessingText: 'och',
          },
          cancelSubscription: {
            title: 'Avsluta medlemskap',
            text: 'Kontakta support@boappa.se ifall ni vill avsluta tjänsten.',
          },
        },
      },
    },
    modals: {
      payment: {
        title: 'Betalning',
      },
      discountCode: {
        title: 'Rabattkod',
        informationSection: {
          title: 'Var hittar jag en rabattkod?',
          textBrf:
            'För att få använda Boappa med rabatt krävs det att föreningen är medlem hos Bostadsrätterna. Du hittar koden i inloggat läge på Bostadsrätternas hemsida.',
          textSamf:
            'För att erhålla 50% rabatt i Boappa krävs det att er förening har tecknat ett Trygghetsavtal hos Samfälligheterna (en tjänst från Villaägarna). Mejla samfalligheter@villaagarna.se för att få er rabattkod.',
        },
      },
    },
    forms: {
      payment: {
        sections: {
          invoiceDetails: 'Faktureringsuppgifter',
          orderInformation: 'Orderinformation',
        },
        labels: {
          yearlyCost: 'Kostnad för årsavgift',
          validity: 'Validitet',
        },
        cost: {
          totalCost: 'Total kostnad inkl. moms',
          vat: 'Varav moms',
        },
        agreement: {
          preUserAgreement: 'Jag har rätt att binda min förening och godkänner',
          postUserAgreement: 'inklusive bilaga',
          userAgreement: 'Användarvillkoren',
          termsOfService: 'Tjänstevillkoren',
          personalDataAgreement: 'Personuppgiftsbiträdesavtal',
        },
        errors: {
          upgradeErrored:
            'Det gick inte att beställa. Vänligen kontakta support@boappa.se',
        },
      },
      discountCode: {
        labels: {
          discountCode: 'Uppge din rabattkod',
        },
        errors: {
          discountCode: 'Ogiltig rabattkod',
        },
        placeholders: {
          discountCode: 'Skriv koden här...',
        },
      },
    },
    flashMessage: {
      discountCodeApplied: 'Rabattkoden har applicerats',
      upgradeSuccess: 'Föreningen har uppgraderats',
    },
  },
  createUpdateHouseRule: {
    addNewHouseRuleTitle: 'Skapa ny regel',
    editHouseRuleTitle: 'Redigera regel',
    flashMessage: {
      updateHouseRuleSuccess: 'Regel uppdaterad',
      updateHouseRuleFailure: 'Regel kunde inte uppdateras',
      createHouseRuleSuccess: 'Regel skapad',
      createHouseRuleFailure: 'Regel kunde inte skapas',
      deleteHouseRuleSuccess: 'Regel raderad',
      deleteHouseRuleFailure: 'Regeln kunde inte raderas',
    },
    confirmationModal: {
      title: 'Radera trivselregel',
      description: 'Är du säker på att du vill radera regeln?',
    },
  },
  createUpdateQuestionsAndAnswers: {
    addNewQuestionTitle: 'Ny fråga',
    editQuestionTitle: 'Redigera fråga',
    addNewSectionTitle: 'Skapa ny sektion',
    editSectionTitle: 'Redigera sektion',
    flashMessage: {
      updateQuestionSuccess: 'Fråga uppdaterad',
      updateQuestionFailure: 'Fråga kunde inte uppdateras',
      createQuestionSuccess: 'Fråga skapad',
      createQuestionFailure: 'Fråga kunde inte skapas',
      deleteQuestionSuccess: 'Fråga raderad',
      deleteQuestionFailure: 'Frågan kunde inte raderas',
      updateSectionSuccess: 'Sektion uppdaterad',
      updateSectionFailure: 'Sektion kunde inte uppdateras',
      createSectionSuccess: 'Sektion skapad',
      createSectionFailure: 'Sektion kunde inte skapas',
      deleteSectionSuccess: 'Sektion raderad',
      deleteSectionFailure: 'Sektionen kunde inte raderas',
    },
    confirmationModal: {
      title: 'Radera fråga',
      description: 'Är du säker på att du vill radera frågan?',
    },
    removeSectionConfirmationModal: {
      title: 'Radera sektion',
      description: 'Är du säker på att du vill radera sektionen?',
    },
  },
  createUpdateQuestionsAndAnswersItemForm: {
    labels: {
      question: 'Fråga',
      answer: 'Svar',
    },
  },
  createUpdateQuestionsAndAnswersSectionForm: {
    labels: {
      section: 'Titel',
    },
  },
  notifications: {
    title: 'Notiser',
    emptyState: 'Här visas notiser om vad som händer i appen.',
    newGroupChat:
      'Gruppchatten {% roomName %} har skapats. Kolla vad som diskuteras.',
    newPost: `{% userName %} skapade ett inlägg: "{% message %}"`,
    newComment: '{% userName %} kommenterade ett inlägg: "{% comment %}"',
    newCommentYourPost:
      '{% userName %} kommenterade ditt inlägg: "{% comment %}"',
    newLike: '{% userName %} gillade ditt inlägg: "{% message %}"',
    newProblemReport: 'En felanmälan har skickats till din mejladress',
    societyActivated: '{% societyName %} aktiverades i Boappa!',
    societyInvite: 'Du har blivit inbjuden till {% societyName %}',
    societyRequest:
      '{% userName %} begär att få tillgång till {% societyName %}',
    societyRequestAccepted: 'Du har fått tillgång till {% societyName %}',
    societyRequestAcceptedAdmin:
      '{% userName %} har fått tillgång till {% societyName %}',
    newInspiration: 'Läs om månadens inspiration från andra Boappare.',
    chatNewChannel:
      'Gruppchatten {% roomName %} har skapats. Kolla vad som diskuteras.',
    contractMonitoring:
      'Bevakningsdatumet har infallit för avtalet {% contractTitle %}',
  },
  searchBar: {
    placeholder: {
      search: 'Sök...',
      filter: 'Filtrera...',
    },
  },
  dropZone: {
    dragFileHere: 'Dra filen hit eller',
    clickToSelect: 'klicka för att välja fil',
    acceptedFormats: 'Accepterade filformat',
  },
  createUpdateContactModal: {
    addNewContactTitle: 'Skapa ny kontakt',
    editContactTitle: 'Redigera kontakt',
    flashMessage: {
      updateContactSuccess: 'Kontakt uppdaterad',
      updateContactFailure: 'Kontakten kunde inte uppdateras',
      createContactSuccess: 'Kontakt skapad',
      createContactFailure: 'Kontakten kunde inte skapas',
    },
  },
  createUpdateContractModal: {
    addNewContractTitle: 'Skapa nytt avtal',
    editContractTitle: 'Redigera avtal',
    flashMessage: {
      updateContractSuccess: 'Avtal uppdaterat',
      updateContractFailure: 'Avtalet kunde inte uppdateras',
      createContractSuccess: 'Avtal skapat',
      createContractFailure: 'Avtalet kunde inte skapas',
    },
  },
  createUpdateContractForm: {
    labels: {
      title: 'Titel',
      company: 'Företag',
      description: 'Beskrivning',
      running: 'Löpande',
      startDate: 'Startdatum',
      endDate: 'Slutdatum',
      notificationDate: 'Bevakningsdatum',
      status: 'Status',
      documents: 'Dokument',
      contactName: 'Namn',
      contactEmail: 'Mejladress',
      contactPhone: 'Telefonnummer',
      contactWebsite: 'Hemsida',
    },
    headers: {
      contactDetails: 'Kontaktuppgifter',
      documents: 'Dokument',
    },
    status: {
      active: 'Aktivt',
      terminated: 'Avslutat',
    },
  },
  createUpdateDocument: {
    addNewDocumentTitle: 'Ladda upp dokument',
    editDocumentTitle: 'Redigera dokument',
    flashMessage: {
      updateDocumentSuccess: 'Dokument uppdaterat',
      updateDocumentFailure: 'Dokumentet kunde inte uppdateras',
      createDocumentPluralSuccess: 'Dokumenten skapades',
      createDocumentPluralFailure: 'Dokumenten kunde inte skapas',
      createDocumentSingularSuccess: 'Dokument skapat',
      createDocumentSingularFailure: 'Dokument kunde inte skapas',
      deleteDocumentSuccess: 'Dokument raderat',
      deleteDocumentFailure: 'Dokumentet kunde inte raderas',
    },
    removeDocumentConfirmationModal: {
      title: 'Radera dokument',
      description: 'Är du säker på att du vill radera dokumentet?',
    },
  },
  createUpdateDocumentForm: {
    labels: {
      name: 'Namn',
    },
    uploadedFiles: 'Uppladdade filer',
  },
  documentsList: {
    allDocuments: 'Alla dokument',
    emptyState: {
      noSearchResultSubtitle:
        'Inga sökresultat hittades. Försök igen eller ändra ditt sökord.',
      title: 'Du har inget att läsa',
      emptyFolderSubtitle:
        'Just nu finns det inga dokument publicerade i "{{folderName}}". När styrelsen delar rapporter, anteckningar och relevant dokumentation kommer det dyka upp här.',
      noDocumentsSubtitle:
        'Just nu finns det inga mappar eller dokument publicerade. När styrelsen delar rapporter, anteckningar och relevant dokumentation kommer det dyka upp här.',
      emptyFolderSubtitleBoard:
        'Just nu finns det inga dokument publicerade i "{{folderName}}".',
      noDocumentsSubtitleBoard:
        'Just nu finns det inga mappar eller dokument publicerade.',
    },
    document: {
      folderSingular: 'mapp',
      folderPlural: 'mappar',
      documentSingular: 'dokument',
      documentPlural: 'dokument',
    },
  },
  createUpdateFolder: {
    addNewFolderTitle: 'Skapa ny mapp',
    editFolderTitle: 'Redigera mapp',
    flashMessage: {
      updateFolderSuccess: 'Mapp uppdaterad',
      updateFolderFailure: 'Mappen kunde inte uppdateras',
      createFolderSuccess: 'Mapp skapad',
      createFolderFailure: 'Mappen kunde inte skapas',
      deleteFolderSuccess: 'Mapp raderad',
      deleteFolderFailure: 'Mappen kunde inte raderas',
    },
    removeFolderConfirmationModal: {
      title: 'Radera mapp',
      description: 'Är du säker på att du vill radera mappen?',
    },
  },
  createUpdateFolderForm: {
    labels: {
      name: 'Namn',
    },
  },
  deleteContract: {
    flashMessage: {
      deleteContractSuccess: 'Avtal raderat',
      deleteContractFailure: 'Avtalet kunde inte raderas',
    },
    removeContractConfirmationModal: {
      title: 'Radera avtal',
      description: 'Är du säker på att du vill radera avtalet?',
    },
  },
  widgetView: {
    emptyState: {
      title: 'Funktionen kunde inte hittas',
      subtitle: 'Du försökte gå in på en funktion som inte finns.',
    },
  },
  createUpdateAdminModal: {
    addNewAdminTitle: 'Lägg till administratör',
    flashMessage: {
      adminAddSuccessSingular: 'Administratör tillagd',
      adminAddFailureSingular: 'Administratör kunde inte läggas till',
      adminAddSuccessPlural: 'Administratörer tillagda',
      adminAddFailurePlural: 'Administratörer kunde inte läggas till',
    },
  },
  createUpdateBoardMemberModal: {
    addNewBoardMemberTitle: 'Lägg till styrelsemedlem',
    editBoardMemberTitle: 'Redigera styrelsemedlem',
    flashMessage: {
      createRoleSuccess: 'Rollen har skapats',
      createRoleFailure: 'Rollen kunde inte skapas',
      updateRoleSuccess: 'Rollen har uppdaterats',
      updateRoleFailure: 'Rollen kunde inte uppdateras',
    },
  },
  createUpdateAdminForm: {
    labels: {
      users: 'Användare',
    },
    error: {
      minItems: 'Måste välja minst en användare',
    },
  },
  createUpdateBlockedUserModal: {
    title: 'Lägg till blockering',
  },
  createUpdateBlockedUserForm: {
    labels: {
      users: 'Användare',
    },
    error: {
      minItems: 'Välj minst en användare',
    },
  },
  createUpdateBoardMemberForm: {
    labels: {
      role: 'Roll',
      user: 'Användare',
    },
  },
  boardListView: {
    title: 'Styrelsemedlemmar',
    addBoardMember: 'Lägg till styrelsemedlem',
    alert: {
      deleteBoardMemberTitle: 'Radera styrelsemedlem',
      deleteBoardMemberText:
        'Är du säker på att du vill radera styrelsemedlemmen?',
    },
    emptyState: {
      title: 'Det finns inga styrelsemedlemmar',
      subtitle: 'Inga styrelsemedlemmar har lagts till i föreningen.',
    },
    flashMessage: {
      deleteBoardMemberSuccess: 'Styrelsemedlemmen raderad',
      deleteBoardMemberFailure: 'Styrelsemedlemmen kunde inte raderas',
    },
  },
  boardListItem: {
    dropdown: {
      editRole: 'Redigera roll',
    },
  },
  createUpdateContactForm: {
    labels: {
      coverPhoto: 'Omslagsbild',
      name: 'Namn',
      title: 'Titel',
      description: 'Beskrivning',
      phoneNumber: 'Telefonnummer',
      emailAddress: 'Mejladress',
      website: 'Hemsida',
    },
    headers: {
      contactDetails: 'Kontaktuppgifter',
    },
  },
  adminView: {
    title: 'Admin',
    tabBar: {
      users: 'Användare',
      societies: 'Föreningar',
      actions: 'Actions',
      discountCodes: 'Rabattkoder',
      logInAsUser: 'Logga in som användare',
      pendingInvoices: 'Väntande fakturor',
      extractData: 'Ladda ner utdrag',
      auditLogs: 'Audit logs',
      webinars: 'Webinars',
    },
    searchBar: {
      placeholders: {
        users: 'Sök på mejladress eller id',
        societies: 'Sök på namn eller id',
        auditLogs: 'Sök på föreningsid',
      },
    },
    searchResult: {
      emptyResult: 'Inget resultat.',
    },
  },
  manageHousehold: {
    modalTitle: 'Bjud in personer',
    title: 'Hushåll',
    subtitle:
      'Här du kan bjuda in familjemedlemmar, inneboende och hyresgäster som ska ha tillgång till appen men som inte är medlemmar i föreningen.',
    form: {
      headers: {
        household: 'Personer i hushållet',
        pendingInvites: 'Obesvarade inbjudningar',
      },
    },
    buttons: {
      addPerson: 'Lägg till person',
    },
    reminder: {
      confirmationModal: {
        title: 'Skicka påminnelse',
        description: 'Vill du skicka en påminnelseinbjudan till {{ email }}?',
      },
    },
    errorViews: {
      noSociety: {
        title: 'Du är inte med i en förening',
        subtitle:
          'För att hantera ditt hushåll måste du vara med i en förening och ha en tilldelad enhet.',
      },
    },
    flashMessage: {
      sendInviteSuccess: 'Person inbjuden',
      sendInviteFailure: 'Inbjudan kunde inte skickas',
      userReminderFailure: 'Påminnelsen kunde inte skickas',
      userReminderSuccess: 'Påminnelse skickad',
    },
  },
  postCreateUpdateBase: {
    form: {
      placeholders: {
        message: 'Hej härliga förening....',
        society: 'Välj förening',
      },
    },
    media: {
      attach: 'Bifoga',
      images: 'Bilder',
      documents: 'Dokument',
    },
    attachments: {
      pictures: 'Dina bilder',
      gallery: 'Bildkatalog',
      document: 'Dokument',
      event: 'Händelse',
      poll: 'Omröstning',
    },
    roleSheet: {
      admin: 'Admin',
      board: 'Styrelse',
    },
    visibilitySheet: {
      all: 'Alla',
      board: 'Styrelsen',
      admin: 'Administratörer',
    },
    navigationHeaders: {
      polls: 'Omröstningar',
      documents: 'Dokument',
      events: 'Händelser',
      images: 'Bilder',
    },
    alert: {
      imageDeleteTitle: 'Radera bild',
      imageDeleteText: 'Är du säker på att du vill radera bilden?',
      documentDeleteTitle: 'Radera dokument',
      documentDeleteText: 'Är du säker på att du vill radera dokumentet?',
      eventDeleteTitle: 'Radera händelse',
      eventDeleteText: 'Är du säker på att du vill radera händelsen?',
      pollDeleteTitle: 'Radera omröstning',
      pollDeleteText: 'Är du säker på att du vill radera omröstningen?',
    },
    flashMessage: {
      updatePostSuccess: 'Inlägg uppdaterat',
      updatePostFailure: 'Inlägget kunde inte uppdateras',
      createPostSuccess: 'Inlägg skapat',
      createPostFailure: 'Inlägget kunde inte skapas',
    },
  },
  society: {
    roles: {
      admin: 'Admin',
      board: 'Styrelsen',
    },
    boardRoles: {
      advisor: 'Adjungerad',
      alternate: 'Suppleant',
      chairman: 'Ordförande',
      member: 'Styrelseledamot',
      nominatingCommittee: 'Valberedning',
      secretary: 'Sekreterare',
      treasury: 'Kassör',
      viceChairman: 'Vice ordförande',
    },
    postedBy: 'via',
  },
  authenticationSignInView: {
    title: 'Inloggning',
    welcome: 'Välkommen till Boappa',
    form: {
      labels: {
        email: 'Mejladress',
        password: 'Lösenord',
      },
      placeholders: {
        email: 'Ange din mejladress',
      },
    },
    signInButtonTitle: 'Logga in',
    createAccountButtonTitle: 'Skapa konto',
    forgotPasswordButtonTitle: 'Jag har glömt mitt lösenord',
    flashMessage: {
      signInFailure: 'Fel lösenord eller mejladress',
    },
  },
  authenticationSignUpView: {
    title: 'Skapa konto',
    subTitle: 'Skapa ditt konto för att komma igång',
    form: {
      labels: {
        name: 'Förnamn',
        surname: 'Efternamn',
        email: 'Mejladress',
        password: 'Lösenord',
        confirmPassword: 'Bekräfta lösenord',
      },
      placeholders: {
        name: 'Ange ditt förnamn',
        surname: 'Ange ditt efternamn',
        email: 'Ange din privata mejladress',
      },
      checkboxText: {
        legalAge: 'Jag har fyllt 18 år eller har målmans godkännande',
        termsOfService:
          'Jag godkänner Boappas {{termsOfUse}} och har läst Boappas {{privacyPolicy}}',
        termsOfUseTitle: 'Användarvillkor',
        privacyPolicyTitle: 'Integritetspolicy',
      },
    },
    signUpButtonTitle: 'Skapa konto',
    loginButtonTitle: 'Har du redan ett konto?',
    loginCTAButtonTitle: 'Logga in',
    forgotPasswordButtonTitle: 'Jag har glömt mitt lösenord',
  },
  authenticationResetPasswordView: {
    title: 'Återställ lösenord',
    form: {
      labels: {
        email: 'Mejladress',
        emailConfirmation: 'Upprepa mejladress',
      },
    },
    resetPasswordButtonTitle: 'Återställ lösenord',
    confirmationScreen: {
      title: 'Mejl skickat',
      subtitle:
        'Följ instruktionerna i mejlet för att återställa ditt lösenord',
    },
    flashMessage: {
      accountDoesNotExist: 'Konto saknas för mejladressen',
      passwordResetSuccess: 'Mejl för att återställa lösenord har skickats',
      passwordResetFailure: 'Lösenordet kunde inte återställas',
    },
  },
  authenticationRetrievePasswordView: {
    title: 'Återställ lösenord',
    form: {
      labels: {
        password: 'Nytt lösenord',
        passwordConfirmation: 'Upprepa lösenord',
      },
    },
    retrievePasswordButtonTitle: 'Ändra lösenord',
    problemsWithLink: 'Har du problem med att ändra lösenordet?',
    sendNewLink: 'Skicka en ny länk',
    flashMessage: {
      invalidToken: 'Ogiltig återställningslänk',
      passwordResetSuccess: 'Lösenordet har ändrats',
      passwordResetFailure: 'Något gick fel, vänligen försök igen',
    },
  },
  societyCreateUpdateContactScreens: {
    flashMessage: {
      updateContactSuccess: 'Kontakt uppdaterad',
      updateContactFailure: 'Kontakten kunde inte uppdateras',
      createContactSuccess: 'Kontakt skapad',
      createContactFailure: 'Kontakten kunde inte skapas',
    },
  },
  societyCreateUpdateResourceScreens: {
    flashMessage: {
      updateResourceSuccess: 'Annons uppdaterad',
      updateResourceFailure: 'Annonsen kunde inte uppdateras',
      createResourceSuccess: 'Annons skapad',
      createResourceFailure: 'Annonsen kunde inte skapas',
    },
  },
  createUpdateResourceModal: {
    title: 'Skapa ny annons',
    labels: {
      media: 'Bild',
      title: 'Titel',
      description: 'Beskrivning',
      cost: 'Pris',
    },
    placeholders: {
      description: 'Beskriv objektet i annonsen',
    },
  },
  settingsView: {
    title: 'Mitt konto',
    tabBar: {
      tabs: {
        myInfo: 'Min info',
        changePassword: 'Ändra lösenord',
        notificationSettings: 'Notiser',
      },
    },
  },
  changeLanguage: {
    title: 'Språk',
  },
  profileSettings: {
    title: 'Konto',
    profilePictureTitle: 'Profilbild',
    labels: {
      updateProfilePicture: 'Ändra',
    },
    confirmationModal: {
      title: 'Radera profilbild',
      description: 'Är du säker på att du vill radera profilbilden?',
    },
    flashMessage: {
      updateProfilePictureSuccess: 'Profilbild uppdaterad',
      updateProfilePictureFailure: 'Profilbilden kunde inte uppdateras',
      deleteProfilePictureSuccess: 'Profilbild borttagen',
      deleteProfilePictureFailure: 'Profilbilden kunde inte tas bort',
    },
    deleteAccount: {
      title: 'Radera konto',
      text: 'Följ nästkommande steg för att permanent radera ditt konto',
    },
  },
  changeEmail: {
    title: 'Byt mejladress',
    labels: {
      currentEmail: 'Nuvarande mejladress',
      newEmail: 'Ny mejladress',
      repeatNewEmail: 'Upprepa mejladress',
    },
    flashMessage: {
      updateEmailFailure: 'Mejladressen kunde inte uppdateras',
      updateEmailSuccess: 'Mejladress uppdaterad',
    },
  },
  changePassword: {
    title: 'Ändra lösenord',
    labels: {
      currentPassword: 'Nuvarande lösenord',
      newPassword: 'Nytt lösenord',
      newPasswordConfirmation: 'Upprepa nytt lösenord',
    },
    flashMessage: {
      updatePasswordFailure: 'Lösenordet kunde inte uppdateras',
      updatePasswordSuccess: 'Lösenordet uppdaterat',
    },
  },
  myInfo: {
    sections: {
      titles: {
        general: 'Generell information',
        work: 'Arbete',
        education: 'Utbildning',
        about: 'Om mig',
        interests: 'Intressen',
      },
    },
  },
  boappaSuperadmin: {
    title: 'Supportverktyg',
    userTitle: 'Användare',
    logout: 'Logga ut som användare',
    signin: 'Logga in som användare',
    labels: {
      search: 'Sök på mejladress eller id',
      searchButton: 'Sök',
      matches: 'Matchningar',
      firstname: 'Förnamn:',
      lastname: 'Efternamn:',
      verified: 'Verifierad mejladress:',
      email: 'Mejladress:',
    },
  },
  notificationSettings: {
    options: {
      mobile: 'Mobil',
      email: 'Mejl',
    },
    sections: {
      all: {
        title: 'Alla notiser',
        allNotificationsTitle: 'Stäng av/slå på samtliga notiser',
      },
      admin: {
        title: 'Administratör',
        membershipRequestTitle: 'Förfrågningar om medlemskap',
      },
      feed: {
        title: 'Flöde',
        boardNewPostTitle: 'Inlägg från styrelsen',
        boardNewCommentTitle: 'Kommentarer på inlägg från styrelsen',
        neighbourNewPostTitle: 'Inlägg från grannar',
        neighbourNewCommentTitle: 'Kommentarer på grannars inlägg',
      },
      chat: {
        title: 'Chatt',
        neighbourNewMessageTitle: 'Grannchatt',
        groupNewMessageTitle: 'Gruppchatt',
        boardNewMessageTitle: 'Styrelsechatt',
        questionsNewMessageTitle: 'Fråga styrelsen chatt',
      },
    },
  },
  findFriends: {
    numberMatchesSingular: 'Träff',
    numberMatchesPlural: 'Träffar',
    categories: {
      interests: 'Intressen',
      occupationAndEducation: 'Arbete & Utbildning',
    },
  },
  form: {
    requiredNote: '*Obligatoriskt',
    lockedNote: 'Låst',
    errors: {
      required: 'Fältet är obligatoriskt',
      unknown: 'Felaktigt värde',
      invalidEmail: 'Felaktig mejladress',
      invalidWebsite: 'Felaktigt webbadressformat',
      passwordConfirmation: 'Lösenorden måste vara lika',
      emailConfirmation: 'Mejladresserna måste vara lika',
      minLength: 'Måste vara minst {{minLength}} tecken',
      minNumber: 'Måste vara minst {{minNumber}}',
      emailAlreadyInUse: 'Mejladressen används redan.',
      matches: 'Felaktigt format',
      organisationNumber:
        'Organisationsnumret måste bestå av 10 siffror. Exempel: 123456-7890',
    },
  },
  flashMessage: {
    changesSaved: 'Ändringar sparade',
    changesNotSavedError: 'Ändringarna kunde inte sparas',
    somethingWentWrongError: 'Något gick fel',
    cannotOpenEmail: 'Kan inte öppna mejlklienten. Mejla till: {% email %}',
    chatSocketError:
      'Något gick fel när meddelandet skulle skickas. Testa att ladda om sidan eller försök igen senare.',
  },
  errorBoundary: {
    errorFallbackTitle: 'Hoppsan, något gick fel!',
    errorFallbackSubtitle:
      'Felet har rapporteras till våra utvecklare och kommer åtgärdas snarast.\n\nFör att underlätta felsökningen skulle vi uppskatta om du rapporterar buggen.',
    reportBugButtonTitle: 'Rapportera bugg',
  },
  user: {
    name: 'Namn',
    surname: 'Efternamn',
    gender: 'Kön',
    phoneNumber: 'Telefonnummer',
    birthDate: 'Födelsedatum',
    aboutInfo: {
      presentation: 'Presentation',
      presentationPlaceholder: 'Beskriv dig själv...',
      interestCategories: 'Kategorier',
      occupation: {
        company: 'Företag',
        title: 'Titel',
        industry: 'Industri',
      },
      education: {
        level: 'Nivå',
        country: 'Land',
        institute: 'Institut',
        field: 'Utbildning',
        degree: 'Examen',
      },
    },
  },
  education: {
    level: {
      highSchool: 'Gymnasium',
      professionalSchool: 'Yrkesskola',
      university: 'Universitet',
    },
    degree: {
      bachelor: 'Kandidat/Högskola',
      master: 'Master/Magister',
      phd: 'Licentiat/Doktor',
    },
    field: {
      airPilot: 'Pilot',
      animalKeeper: 'Djursjukskötare',
      architecture: 'Arkitektur',
      bachelorOfEngineeering: 'Högskoleingenjör',
      bioMedicine: 'Biomedicinsk analytiker',
      business: 'Civilekonom',
      civilEngineering: 'Byggingenjör',
      dentalHygiene: 'Tandhygienist',
      dentist: 'Tandläkare',
      dietetics: 'Dietist',
      engineering: 'Civilingenjör',
      environmentalScience: 'Miljövetare',
      finance: 'Ekonomi',
      fireScience: 'Brandman- och brandingenjör',
      forestry: 'Jägmästare',
      forestEngineering: 'Skogsmästare',
      healthScience: 'Hälsovetenskap',
      hippology: 'Hippolog',
      humanities: 'Humaniora',
      humanResources: 'Personalvetare',
      journalism: 'Journalist',
      law: 'Jurist',
      libraryAndInformationScience: 'Biblioteks- och informationsvetenskap',
      logistics: 'Logistik',
      marineScience: 'Sjökapten',
      mediaAndCommunicationScience: 'Medie- och kommunikationsvetenskap',
      medicalDoctor: 'Läkare',
      natureScience: 'Naturvetare',
      nursing: 'Sjuksköterska',
      occupationalTherapy: 'Arbetsterapeut',
      optometry: 'Optiker',
      pharmacist: 'Apotekare',
      physiotherapy: 'Sjukgymnastik',
      police: 'Polis',
      psychology: 'Psykolog',
      realEstate: 'Fastighetsmäklare',
      socialAndBehaviouralScience: 'Beteendevetare',
      socialScience: 'Samhällsvetare',
      socialWorkEducation: 'Socionom',
      softwareDeveloper: 'Webbutveckling och programmering',
      statistics: 'Statistik',
      systemsEngineering: 'Systemvetare',
      teacher: 'Lärare',
      theology: 'Teologi',
      veterinary: 'Veterinär',
    },
  },
  occupation: {
    industry: {
      marketing: 'Försäljning, marknad, reklam',
      adminOfficeManagement: 'Kontor, Administration',
      healthcare: 'Hälso-, Sjukvård, Omsorg',
      customerSupport: 'Kundtjänstarbeten',
      humanResourcesLegal: 'HR, juridik',
      serviceRetailTourism: 'Service, Handel, Butik',
      financeBankingInsurance: 'Ekonomi, Finans, Försäkring',
      education: 'Utbildning',
      industryProduction: 'Industri, produktion',
      itData: 'IT, data',
      engineering: 'Civilingenjör',
      logisticsPurchasing: 'Inköp, Transport, Logistik',
      management: 'Management',
      environment: 'Miljövård',
      construction: 'Bygg, Anläggning',
      cultureMedia: 'Kultur, Media',
    },
  },
  interests: {
    actionSports: 'Extremsport',
    animals: 'Djur',
    art: 'Konst',
    boardGames: 'Sällskapsspel',
    cars: 'Bilar',
    cooking: 'Matlagning',
    dancing: 'Dans',
    design: 'Design',
    electronicsSmartHomes: 'Elektronik/smarta hem',
    entrepreneurship: 'Entreprenörskap',
    family: 'Familj',
    film: 'Film',
    fitness: 'Fitness',
    gardening: 'Trädgård/odling',
    handicraft: 'Hantverk',
    literature: 'Litteratur',
    motorcycles: 'Motorcyklar',
    music: 'Musik',
    nature: 'Natur',
    outdoors: 'Friluftsliv',
    painting: 'Måla',
    politics: 'Politik',
    reading: 'Böcker',
    religion: 'Religion',
    sports: 'Sport',
    startups: 'Startups',
    stockTrading: 'Aktiehandel',
    tech: 'Teknologi',
    toddlers: 'Småbarnsliv',
    travel: 'Resa',
  },
  genders: {
    male: 'Man',
    female: 'Kvinna',
    other: 'Annat',
  },
  societySideBar: {
    sections: {
      resident: {
        title: 'Boende',
        neighborhoodTitle: 'Grannskap',
        residentFunctionsTitle: 'För dig som boende',
      },
      board: {
        title: 'Styrelseportalen',
        managementTitle: 'Styrelsearbete',
        adminTitle: 'Admin',
      },
    },
    searchBar: {
      placeholder: 'Sök i menyn...',
    },
    items: {
      labels: {
        leaveSociety: 'Lämna förening',
      },
    },
    callouts: {
      incompleteRegister: {
        title: 'Ofullständigt lägenhetsregister',
        titleSamf: 'Ofullständigt fastighetsregister',
      },
      societyLocked: {
        title: 'Föreningen är låst',
      },
      societyTrial: {
        titleDays: '{{ days }} dag kvar av provperioden',
        titleDaysPlural: '{{ days }} dagar kvar av provperioden',
      },
    },
  },
  chatRoomsSidebar: {
    startNewChat: 'Starta ny',
    startNewInterestsChat: 'Starta ny',
    startNewAskBoardChat: 'Ställ fråga',
    startNewBoardChat: 'Starta ny',
    joinGroupChat: 'Gå med',
    findQuestionsChat: 'Arkiv',
    activeTabBar: {
      resident: 'Boende',
      board: 'Styrelse',
    },
    tabs: {
      oneOnOneAndInterestsTitle: 'Chatt',
      questionsTitle: 'Fråga styrelsen',
      boardTitle: 'Styrelsechatt',
      answerResidentsTitle: 'Svara boende',
    },
    createNewMessage: {
      title: 'Nytt meddelande',
    },
    createNewMessageForm: {
      labels: {
        sendTo: 'Till:',
      },
      search: {
        placeholder: 'Ange namn',
      },
      error: {
        minItems: 'Måste välja minst en användare',
      },
    },
  },
  chatRooms: {
    chatDisabled: {
      title: 'Chatten avstängd',
      subtitle: 'Styrelsen har stängt av chatten mellan grannar',
    },
    userHasNoChats: {
      title: 'Ingen chatt startad ännu',
      subtitle:
        'Tips: Har mjölken tagit slut till kaffet eller har du glömt bort tiden till grillfesten? Passa på att chatta och fråga grannen om hjälp.',
    },
    userHasNoGroupChats: {
      subtitle:
        'Starta en öppen eller stängd gruppchatt med dina grannar, eller kolla under "Gå med" om det redan finns en chatt du är intresserad av.',
    },
    noBoardQuestions: {
      title: 'Ingen fråga ställd ännu',
      subtitle:
        'Tips: Har du en fråga om hur man ställer sig i kön till en garageplats i föreningen? Ställ då din fråga till styrelsen här.',
    },
    noBoardChats: {
      title: 'Ingen styrelsechatt startad ännu',
      subtitle:
        'Tips: Här kan ni exempelvis diskutera pågående projekt och kommande styrelsemöten.',
    },
    noBoardAnswerResidentChats: {
      title: 'Ingen fråga ställd ännu',
      subtitle: 'Här kan ni som styrelse besvara de boendes frågor.',
    },
    pendingRequest: {
      subtitle:
        'Här kommer du kunna chatta med dina grannar så fort din förfrågan är godkänd.',
    },
  },
  chatSettings: {
    actions: {
      add: 'Lägg till:',
    },
    actionSheet: {
      changeChatRoomImage: 'Ändra chattbild',
      deleteChatRoomImage: 'Ta bort chattbild',
    },
    form: {
      title: 'Gruppmedlemmar',
      remove: 'Radera chatt',
      groupChatName: 'Namn',
      chatCreator: 'Skapare',
      society: 'Förening',
      type: 'Typ',
      closedGroupChat: 'Stängd gruppchatt',
      openGroupChat: 'Öppen gruppchatt',
      members: 'Medlemmar',
      showMembers: 'Visa gruppmedlemmar',
      leave: 'Lämna chatt',
      archive: 'Arkivera',
    },
    tables: {
      titles: {
        groupChatInfo: 'Gruppinformation',
        addMembers: 'Lägg till medlemmar',
        actions: 'Åtgärder',
      },
    },
    alert: {
      chatRoomDeleteTitle: 'Ta bort chatt',
      chatRoomDeleteText: 'Är du säker på att du vill ta bort chatten?',
      leaveChatRoomTitle: 'Lämna gruppchatt',
      leaveChatRoomText: 'Är du säker på att du vill lämna gruppchatten?',
      archiveChatRoomTitle: 'Arkivera chatt',
      archiveChatRoomText: 'Är du säker på att du vill arkivera chatten?',
      chatRoomMemberDeleteTitle: 'Ta bort från gruppchatt',
      chatRoomMemberDeleteText:
        'Är du säker på att vill ta bort denna person från gruppchatten?',
    },
    flashMessage: {
      deleteChatRoomSuccess: 'Chatt borttagen',
      deleteChatRoomFailure: 'Chatten kunde inte tas bort',
      leaveChatRoomSuccess: 'Lämnade gruppchatten',
      leaveChatRoomFailure: 'Kunde inte lämna gruppchatten',
      archiveChatRoomSuccess: 'Chatt arkiverad',
      archiveChatRoomFailure: 'Kunde inte arkivera chatten',
      deleteChatRoomMemberSuccess: 'Personen borttagen',
      deleteChatRoomMemberFailure: 'Personen kunde inte tas bort',
    },
  },
  startNewChat: {
    openGroupChat: 'Öppen gruppchatt',
    closedGroupChat: 'Stängd gruppchatt',
    changeToGroupChat: 'Ändra till gruppchatt',
    groupChatInputLabel: 'Gruppnamn:',
    boardChatInputLabel: 'Ämne:',
  },
  managementDocumentsView: {
    title: 'Styrelsedokument',
    addNewFolder: 'Skapa ny mapp',
    addNewDocument: 'Ladda upp dokument',
    bostadsratternaFolderStructure: 'Mappstruktur',
    flashMessage: {
      createBostadsratternaFolderStructureSuccess: 'Mappstruktur skapad',
      createBostadsratternaFolderStructureFailure:
        'Mappstrukturen kunde inte skapas',
    },
  },
  documentsView: {
    title: 'Dokument',
    addNewFolder: 'Skapa ny mapp',
    addNewDocument: 'Ladda upp dokument',
  },
  unitRoles: {
    member: 'Boende (ägare)',
    resident: 'Boende',
    tentant: 'Hyresgäst',
    relative: 'Släkting/vän',
    propertyManager: 'Fastighetsskötare',
    customerRepresentative: 'Kundansvarig',
    other: 'Annat',
  },
  joinGroupChat: {
    joinHeader: 'Gå med i gruppchatt',
    join: 'Gå med',
    details: 'Detaljer',
    backToGroupChats: 'Tillbaka till gruppchattar',
    errorViews: {
      noChatRooms: {
        title: 'Det finns inga chattar att gå med i',
        subtitle:
          'Här kommer du kunna gå med i gruppchattar som du ännu inte är med i.',
      },
    },
    flashMessage: {
      joinGroupChatSuccess: 'Gick med i gruppchatt',
      joinGroupChatFailure: 'Kunde inte gå med i gruppchatt',
    },
  },
  findArchivedChats: {
    header: 'Aktivera arkiverad chatt',
    errorViews: {
      noChatRooms: {
        title: 'Det finns inga arkiverade chattar',
        subtitle: 'Här kan du aktivera dina arkiverade chattar igen.',
      },
    },
    flashMessage: {
      activateArchivedChatSuccess: 'Arkiverad chatt aktiverad',
      activateArchivedChatFailure: 'Kunde inte aktivera chatt',
    },
  },
  chatAskBoardNewMessage: {
    title: 'Fråga styrelsen',
    form: {
      labels: {
        subject: 'Ämne',
        message: 'Meddelande',
      },
    },
    media: {
      attachImageButtonLabel: 'Bifoga bild',
      imageHeaderTitle: 'Bilder',
    },
    flashMessage: {
      sendQuestionSuccess: 'Fråga skickad',
      sendQuestionFailure: 'Kunde inte skicka fråga',
    },
  },
  chatUpload: {
    flashMessage: {
      uploadImageLimitReached:
        'Du kan endast ladda upp 10 bilder per chattmeddelande',
      uploadDocumentLimitReached:
        'Du kan endast ladda upp 10 dokument per chattmeddelande',
    },
  },
  societyManagementWebsiteImages: {
    title: 'Bilder',
    headers: {
      primaryImageTitle: 'Huvudbild',
      societyImagesTitle: 'Bilder som visas på hemsidan',
    },
    addImagesButton: 'Lägg till bild',
    addImageDescription: 'Lägg till beskrivning',
    showImagesLabel: 'Visa',
    recommendedPrimaryImageSizeText:
      'Rekommenderad minsta bildstorlek: 1280x800 pixlar',
  },
  mediaGallery: {
    imageGalleryPositionLabel: 'Bild',
    imageDownloadSuccessText: 'Bild nedladdad',
    downloadImageButtonText: 'Ladda ner bild',
  },
  leaveSociety: {
    confirmationModal: {
      title: 'Lämna förening',
      description: 'Är du säker på att du vill lämna föreningen?',
    },
    unableToLeaveModal: {
      title: 'Du kan inte lämna föreningen',
      description:
        'För att lämna föreningen måste du första lämna över rollen som huvudadministratör till någon annan.',
    },
    flashMessage: {
      leaveSocietySuccess: 'Förening lämnad',
      leaveSocietyFailure: 'Kunde inte lämna föreningen',
    },
  },
  reportProblemSpace: {
    apartment: 'Lägenheten',
    building: 'Byggnaden',
    surroundings: 'Området',
    commonAreas: 'Gemensamma utrymmen',
    other: 'Annat',
  },
  pollEditor: {
    form: {
      labels: {
        title: 'Fråga',
        type: 'Omröstningstyp',
      },
      placeholders: {
        option: 'Alternativ',
      },
      headers: {
        options: 'Svarsalternativ',
      },
      buttons: {
        addMoreOptionsButtonTitle: 'Lägg till svarsalternativ',
      },
    },
    label: {
      perUser: 'Per användare',
      perHousehold: 'Per hushåll',
    },
    flashMessage: {
      updatePollSuccess: 'Omröstning uppdaterad',
      updatePollFailure: 'Omröstningen kunde inte uppdateras',
      createPollSuccess: 'Omröstning skapad',
      createPollFailure: 'Omröstningen kunde inte skapas',
    },
    pollDescription: 'Omröstning',
  },
  poll: {
    votesSingular: 'röst',
    votesPlural: 'röster',
    showResults: 'Visa resultat',
    vote: 'Rösta',
    regretVote: 'Ångra röst',
    householdAlreadyVoted: 'Ditt hushåll har redan röstat',
    userAlreadyVoted: 'Du har redan röstat',
  },
  createSocietyTypeSelectionView: {
    title: 'Skapa förening',
    labels: {
      housingCooperative: 'Bostadsrättsförening',
      homeownersAssociation: 'Samfällighet',
      realEstateDeveloper: 'Bostadsutvecklarprojekt',
      neighbourhood: 'Grannskap',
    },
  },
  societyInviteBanner: {
    invited: 'Du är inbjuden till {{societyName}}',
    details: 'Se inbjudan',
  },
  reportPost: {
    title: 'Rapportera inlägg',
    subtitle:
      'Boappas mål är att hjälpa till att förbättra grannsämjan. Inga trakasserier tillåts. Rapportera inlägg som inte följer detta till administratören.',
    form: {
      labels: {
        message: 'Meddelande',
      },
    },
    flashMessage: {
      reportSuccess: 'Inlägget har rapporterats',
      reportFailure: 'Inlägget kunde inte rapporteras',
    },
    buttons: {
      submitTitle: 'Rapportera',
    },
  },
  societyRoot: {
    subtitle: 'Här hittar du genvägar till olika funktioner i din förening',
    shortcutTitle: 'Genvägar',
    offersTitle: 'Erbjudanden',
    titles: {
      residentList: 'Boendelista',
      boardDocuments: 'Styrelsedokument',
      register: 'Register',
      societyWebsite: 'Föreningshemsida',
    },
    subtitles: {
      residentList: 'Här kan du se vilka som bor i din förening',
      boardDocuments: 'Skapa mappar och ladda upp dokument',
      register: 'Bjud in era grannar, hantera adresser och lägenhetsnummer',
      societyWebsite: 'Redigera er nya hemsida efter just era önskemål',
    },
    onboardingButtonTitle: 'Du kan enkelt hitta tillbaka till guiden här',
  },
  onboardingView: {
    title: 'Välkommen till {{societyName}}!',
    subtitle: 'Kom igång med din förening',
    explanationTitle:
      'Denna guide ger dig inblick hur navigeringen fungerar på fliken Förening',
    explanationText:
      'Vi går igenom Dokument, Register, Frågor & Svar och föreningshemsidan. Du kan enkelt hitta tillbaka till guiden genom att klicka på knappen på föreningsstartsidan.',
    skipText: 'Klar? Klicka för att hoppa över guiden',
    onboardingSections: {
      documents: {
        title: 'Styrelsedokument',
        subtitle:
          'Ladda upp dokument som delas i din förening. För att skapa er egna första mapp klicka på “skapa mapp” eller “ladda upp dokument” för att komma igång.',
      },
      register: {
        title: 'Register',
        subtitle:
          'Bjud in era styrelsekollegor och grannar. Klicka på knappen “Bjud in medlemmar” inne på register för att påbörja inbjudningsprocessen. Ni kan även använda en excel-fil för att ladda upp flera adresser åt gången.',
      },
      faq: {
        title: 'Frågor och svar',
        subtitle:
          'Lägg upp bra och nyttig information som era grannar i föreningen kan ta del av. Här kan ni till exempel ange information kring balkong, yttre- och inre utrymmen som är bra att veta.',
      },
      societyWebsite: {
        title: 'Föreningshemsida',
        subtitle:
          'Ange information och bilder till er helt egna föreningshemsida. Här kan ni ladda upp årsredovisningar, dokument och bilder som är relevant för just er förening. Detta kan även underlätta för mäklare när de behöver hitta information om just er förening.',
      },
    },
  },
  onboardingSection: {
    step: 'Steg {{step}}',
    nextStep: 'Nästa steg',
    previousStep: 'Föregående steg',
    lastStep: 'Klar! Stäng guide',
  },
  verifyEmailView: {
    title: 'Verifiera din mejladress',
    subtitle:
      'För att kunna börja Boappa behöver du verifiera din mejladress. En länk är skickad till din mejl:',
    cantFindEmail:
      'Hittar du inget mejl i inkorgen? Kika först i skräpposten, annars klicka på knappen nedan för att skicka ett nytt mejl.',
    sendAgain: 'Skicka länken igen',
    preTimeLeftUntilResend: 'Du kan skicka ett nytt mejl om',
    invalidEmail: 'Skrev du in fel mejladress?',
    flashMessage: {
      resendVerifyEmailSuccess: 'Mejlet har skickats',
      resendVerifyEmailFailure: 'Mejlet kunde inte skickas',
    },
    startOverButtonTitle: 'Börja om här',
  },
  deleteAccountModal: {
    title: 'Radera konto',
    warningTitle: 'Är du säker?',
    warningSubtitle: 'Ditt konto kommer att raderas permanent',
    secondWarningTitle: 'Radera konto',
    secondWarningText:
      'Kontot kommer raderas permanent och går inte att återskapa. Bekräfta ditt val genom att klicka på Fortsätt.',
    lastWarningTitle:
      'Skriv in ditt lösenord och klicka sedan på "Radera konto" för att radera ditt konto permanent',
    warningText:
      'Om du väljer att radera ditt konto går det inte att återskapa. Har du flyttat kan du istället välja att "Lämna förening".',
    canNotDeleteTitle: 'Ditt konto kan inte raderas',
    canNotDeleteText:
      'Du måste först lämna över rollen som huvudadministratör till någon annan i föreningarna listade nedan innan du kan radera ditt konto.',
    alert: {
      deleteAccountTitle: 'Radera konto',
      deleteAccountText:
        'Kontot kommer raderas permanent och går inte att återskapa. Bekräfta ditt val.',
    },
    flashMessage: {
      confirmPasswordFailure: 'Felaktigt lösenord',
      deleteAccountSuccess: 'Ditt konto har raderats',
    },
  },
  uploadImage: {
    invalidImageType:
      'Felaktigt bildformat, prova en annan bild eller ändra format',
  },
}

export default sv
