import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, useForm } from 'react-hook-form'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SnapshotOut } from 'mobx-state-tree'
import { addHours } from 'date-fns'
import { Button, ButtonVariant } from '../../../common/Button'
import { useCurrentSociety } from '../../../../hooks/useCurrentSociety'
import { useAppTranslation } from '../../../../hooks/useAppTranslation'
import { useFormErrorMessage } from '../../../../hooks/useFormErrorMessage'
import { FormControl } from '../../../common/FormControl'
import { useToastNotifications } from '../../../../hooks/useToastNotification'
import { ToastType } from '../../../common/Toast/toast-type'
import { useStores } from '../../../../hooks/useStores'
import {
  isUserAdminInSociety,
  isUserBoardMemberInSociety,
} from '../../../../helpers/society'
import { SelectDropdown } from '../../../common/SelectDropdown'
import { TextInput } from '../../../common/TextInput'
import {
  notificationSettingsOptions,
  NOTIFICATION_SETTINGS_VALUE,
} from '../../../../helpers/notification_settings_options'
import { EventModel } from '../../../../state/models/event'
import { societyEventCreateSchema } from '../../../../forms/schemas/society_event_create'
import { useAuthenticatedUserId } from '../../../../hooks/useAuthenticatedUserId'
import { TextArea } from '../../../common/TextArea'
import { nearestHourDate, parsedOrNewDate } from '../../../../helpers/date'
import { DateTimeInput } from '../../../common/DateTimeInput'
import { Toggle } from '../../../common/Toggle'

interface CreateUpdateEventFormProps {
  onError: (error: unknown) => void
  onSubmit: (data: InferType<typeof societyEventCreateSchema>) => Promise<void>
  onClose: () => void
  loading: boolean
  event?: SnapshotOut<typeof EventModel>
  boardRoomEvent?: boolean
}

export const CreateUpdateEventForm = observer(
  ({
    loading,
    onError,
    onSubmit,
    onClose,
    event,
    boardRoomEvent,
  }: CreateUpdateEventFormProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { authenticationStore } = useStores()
    const { society } = useCurrentSociety()
    if (society === undefined) {
      throw new Error('useCurrentSociety returned undefined')
    }
    const { setToastNotification } = useToastNotifications()
    const { getErrorMessage } = useFormErrorMessage()
    const userId = useAuthenticatedUserId() as string

    const updateMode = !!event

    const isAdmin = isUserAdminInSociety(
      society,
      authenticationStore.userId as string
    )
    const isBoard = isUserBoardMemberInSociety(
      society,
      authenticationStore.userId as string
    )

    const canEdit = isAdmin || isBoard

    if ((!boardRoomEvent && !canEdit) || (updateMode && event === undefined)) {
      onClose()
      setToastNotification(
        ToastType.DANGER,
        translate('flashMessage.somethingWentWrongError')
      )
    }

    const getDefaultValues = useCallback((): InferType<
      typeof societyEventCreateSchema
    > => {
      return {
        title: updateMode ? event?.title || '' : '',
        location: updateMode ? event?.location || '' : '',
        description: updateMode ? event?.description || '' : '',
        isAllDay: updateMode ? event?.isAllDay || false : false,
        attendanceEnabled: updateMode
          ? event?.attendanceEnabled || false
          : true,
        startDate: updateMode
          ? parsedOrNewDate(event?.startDate)
          : addHours(nearestHourDate(), 2),
        endDate: updateMode
          ? parsedOrNewDate(event?.endDate)
          : addHours(nearestHourDate(), 3),
        notificationSettings: updateMode
          ? (event?.notificationSettings as NOTIFICATION_SETTINGS_VALUE) ||
            NOTIFICATION_SETTINGS_VALUE.NONE
          : NOTIFICATION_SETTINGS_VALUE.NOTIFICATION_AND_FEED,
        societyId: updateMode ? event?.societyId || society._id : society._id,
        userId: updateMode ? event?.userId || userId : userId,
      }
    }, [event, updateMode, userId, society._id])

    const {
      control,
      handleSubmit,
      watch,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(societyEventCreateSchema),
      defaultValues: getDefaultValues(),
    })

    const watchIsAllDay = watch('isAllDay')
    const watchStartDate = watch('startDate')
    const watchEndDate = watch('endDate')

    return (
      <>
        {!updateMode && (
          <Controller
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <FormControl
                label={
                  boardRoomEvent
                    ? translate('common.notificationSettings.labelBoard')
                    : translate('common.notificationSettings.label')
                }
                name={name}
                error={
                  errors.notificationSettings &&
                  getErrorMessage(errors.notificationSettings)
                }
              >
                <SelectDropdown
                  value={value}
                  onChange={onChange}
                  options={notificationSettingsOptions}
                  error={!!errors.notificationSettings}
                />
              </FormControl>
            )}
            name="notificationSettings"
          />
        )}
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateEvent.form.labels.title')}
              error={errors.title && getErrorMessage(errors.title)}
              name={name}
            >
              <TextInput
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={!!errors.title}
              />
            </FormControl>
          )}
          name="title"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateEvent.form.labels.location')}
              error={errors.location && getErrorMessage(errors.location)}
              name={name}
            >
              <TextInput
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={!!errors.location}
              />
            </FormControl>
          )}
          name="location"
        />
        <Controller
          control={control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              label={translate('createUpdateEvent.form.labels.description')}
              error={errors.description && getErrorMessage(errors.description)}
              name={name}
            >
              <TextArea
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={!!errors.description}
              />
            </FormControl>
          )}
          name="description"
        />
        <Controller
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Toggle
              label={translate('createUpdateEvent.form.labels.isAllDay')}
              name={name}
              enabled={value || false}
              onChange={onChange}
            />
          )}
          name="isAllDay"
        />
        <Controller
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormControl
              label={
                watchIsAllDay
                  ? translate('createUpdateEvent.form.labels.startDay')
                  : translate('createUpdateEvent.form.labels.startDate')
              }
              error={errors.description && getErrorMessage(errors.description)}
              name={name}
            >
              <DateTimeInput
                onChange={(date) => {
                  onChange(date)
                  // startDate should always be before endDate
                  if (watchEndDate && date >= watchEndDate) {
                    setValue('endDate', addHours(date, 1))
                  }
                }}
                minDate={new Date()}
                value={value}
                showTimeSelect={!watchIsAllDay}
              />
            </FormControl>
          )}
          name="startDate"
        />
        <Controller
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormControl
              label={
                watchIsAllDay
                  ? translate('createUpdateEvent.form.labels.endDay')
                  : translate('createUpdateEvent.form.labels.endDate')
              }
              error={errors.description && getErrorMessage(errors.description)}
              name={name}
            >
              <DateTimeInput
                onChange={(date) => {
                  onChange(date)
                  // endDate should always be after startDate
                  if (watchStartDate && date <= watchStartDate) {
                    setValue('startDate', addHours(date, -1))
                  }
                }}
                minDate={new Date()}
                value={value}
                showTimeSelect={!watchIsAllDay}
              />
            </FormControl>
          )}
          name="endDate"
        />
        <Controller
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Toggle
              label={translate(
                'createUpdateEvent.form.labels.attendanceEnabled'
              )}
              name={name}
              enabled={value || false}
              onChange={onChange}
            />
          )}
          name="attendanceEnabled"
        />

        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-3">
          <Button
            label={translate('common.actions.cancel')}
            onClick={onClose}
            variant={ButtonVariant.TEXT}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            disabled={!isValid || loading}
            label={translate('common.actions.save')}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </>
    )
  }
)
