import React, { useCallback, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { EventModel } from '../../../state/models/event'
import { BoardRoomEventModel } from '../../../state/models/boardroom-event'
import { Section, SectionList } from '../../../components/common/SectionList'
import { useEventUserAttendance } from './useEventUserAttendance'
import { UserModel } from '../../../state/models/user'
import { useStores } from '../../../hooks/useStores'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { ResidentListItem } from '../../../components/society/ResidentList/ResidentListItem'
import { SocietyModel } from '../../../state/models/society'

interface EventDetailAttendantsProps {
  event:
    | SnapshotOut<typeof EventModel>
    | SnapshotOut<typeof BoardRoomEventModel>
  boardRoomEvent?: boolean
}

export const EventDetailAttendants = observer(
  ({ event, boardRoomEvent }: EventDetailAttendantsProps): JSX.Element => {
    const { translate } = useAppTranslation()
    const { societyStore, unitStore, userStore } = useStores()
    const { attending, notAttending } = useEventUserAttendance(
      event?.attendance
    )
    const society = societyStore.societies.get(event.societyId)

    const noReplyUsers = useMemo((): {
      user: SnapshotOut<typeof UserModel>
      status: 'no_reply'
    }[] => {
      const getUsers = (): SnapshotOut<typeof UserModel>[] => {
        let users: (SnapshotOut<typeof UserModel> | undefined)[] = []
        if (boardRoomEvent) {
          const boardMemberList = society?.boardMembersList || []
          users = boardMemberList.map((boardMember) =>
            userStore.users.get(boardMember.userId)
          )
        } else {
          users = unitStore.residentUsersForSociety(event.societyId)
        }

        return users.filter((u) => u !== undefined) as SnapshotOut<
          typeof UserModel
        >[]
      }
      const allUsers = getUsers()

      const userIdsWithReply = new Set([
        ...attending.map((a) => a.user._id),
        ...notAttending.map((a) => a.user._id),
      ])

      return allUsers
        .filter((user) => user !== undefined && !userIdsWithReply.has(user._id))
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
        .map((user) => ({ user, status: 'no_reply' }))
    }, [
      attending,
      notAttending,
      event.societyId,
      unitStore,
      boardRoomEvent,
      society,
      userStore.users,
    ])

    const getNonEmptySections = useCallback((): Section[] => {
      if (!society) return []
      const _sections: (Section | null)[] = [
        attending.length > 0
          ? {
              title: `${translate(
                'eventDetailView.attendance.titles.attending'
              )} (${attending.length})`,
              item: (
                <div>
                  {attending.map((attendance) => (
                    <>
                      <ResidentListItem
                        key={attendance.user._id}
                        user={attendance.user}
                        society={society as SnapshotOut<typeof SocietyModel>}
                        showUserRoles={false}
                        surnameFirst={false}
                      />
                      <div>{attendance.comment}</div>
                    </>
                  ))}
                </div>
              ),
            }
          : null,
        notAttending.length > 0
          ? {
              title: `${translate(
                'eventDetailView.attendance.titles.notAttending'
              )} (${notAttending.length})`,
              item: (
                <div>
                  {notAttending.map((attendance) => (
                    <>
                      <ResidentListItem
                        key={attendance.user._id}
                        user={attendance.user}
                        society={society as SnapshotOut<typeof SocietyModel>}
                        showUserRoles={false}
                        surnameFirst={false}
                      />
                      <div>{attendance.comment}</div>
                    </>
                  ))}
                </div>
              ),
            }
          : null,
        noReplyUsers.length > 0
          ? {
              title: `${translate(
                'eventDetailView.attendance.titles.noReply'
              )} (${noReplyUsers.length})`,
              item: (
                <div>
                  {noReplyUsers.map((attendance) => (
                    <ResidentListItem
                      key={attendance.user._id}
                      user={attendance.user}
                      society={society as SnapshotOut<typeof SocietyModel>}
                      showUserRoles={false}
                      surnameFirst={false}
                    />
                  ))}
                </div>
              ),
            }
          : null,
      ]
      return _sections.filter((s) => s !== null) as Section[]
    }, [translate, attending, society, noReplyUsers, notAttending])

    return <SectionList sections={getNonEmptySections()} />
  }
)
