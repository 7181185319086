import React from 'react'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { Controller, useForm } from 'react-hook-form'
import { captureException } from '@sentry/react'
import { EventModel } from '../../../state/models/event'
import { BoardRoomEventModel } from '../../../state/models/boardroom-event'
import { useAuthenticatedUserId } from '../../../hooks/useAuthenticatedUserId'
import { Button } from '../../../components/common/Button'
import { useStores } from '../../../hooks/useStores'
import { IconChoices } from '../../../components/common/Icon'
import { theme } from '../../../theme/theme'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { TextInput } from '../../../components/common/TextInput'
import { FormControl } from '../../../components/common/FormControl'

interface EventAttendanceButtonProps {
  label: string
  active: boolean
  backgroundColorClassName: string
  onClick: () => void
  icon?: IconChoices
  iconColor?: string
  loading?: boolean
  disabled?: boolean
}

export const EventAttendanceButton = ({
  label,
  active,
  backgroundColorClassName,
  onClick,
  icon,
  iconColor,
  loading,
  disabled,
}: EventAttendanceButtonProps): JSX.Element => {
  return (
    <Button
      iconColor={iconColor || theme.colors.white}
      icon={icon}
      label={label}
      className={`
      bg-transparent border-${backgroundColorClassName} border-1
      hover:bg-${backgroundColorClassName}
      hover:bg-opacity-70
      ${active ? `bg-${backgroundColorClassName}` : ''}`}
      loading={loading}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

interface EventAttendanceButtonsProps {
  event:
    | SnapshotOut<typeof EventModel>
    | SnapshotOut<typeof BoardRoomEventModel>
  boardRoomEvent: boolean | undefined
}

export const EventAttendanceButtons = observer(
  ({ event, boardRoomEvent }: EventAttendanceButtonsProps): JSX.Element => {
    const userId = useAuthenticatedUserId() as string
    const { translate } = useAppTranslation()
    const { eventStore, boardRoomEventStore } = useStores()

    const userAttendance = event?.attendance?.find(
      (attendance) => attendance.userId === userId
    )
    const userAttending = userAttendance?.status === 'attending'
    const userNotAttending = userAttendance?.status === 'not_attending'

    const loading = boardRoomEvent
      ? boardRoomEventStore.updatingEventAttendance === 'pending'
      : eventStore.updatingEventAttendance === 'pending'

    const { control, watch, formState, reset } = useForm({
      mode: 'all',
      defaultValues: { comment: userAttendance?.comment || '' },
    })

    const isUpdatingAttending =
      userAttendance?.comment && formState.isDirty && userAttending
    const isUpdatingNotAttending =
      userAttendance?.comment && formState.isDirty && userNotAttending
    const disableAttendingButton = (): boolean => {
      if (loading) return true
      if (isUpdatingAttending) return false
      if (userAttending) return true
      return false
    }
    const disableNotAttendingButton = (): boolean => {
      if (loading) return true
      if (isUpdatingNotAttending) return false
      if (userNotAttending) return true
      return false
    }

    return (
      <div>
        <Controller
          control={control}
          name="comment"
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControl
              name={name}
              className="grow"
              label={translate('common.eventActions.attendance.comment')}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={loading}
                className="max-w-120 mb-2"
              />
            </FormControl>
          )}
        />
        <div className="flex gap-2">
          <EventAttendanceButton
            label={
              isUpdatingAttending
                ? translate('common.eventActions.attendance.updateComment')
                : translate('common.eventActions.attendance.attending')
            }
            icon={IconChoices.CHECKMARK}
            iconColor={theme.colors.brandGreen}
            active={userAttending}
            backgroundColorClassName="brandGreen"
            loading={loading}
            disabled={disableAttendingButton()}
            onClick={async () => {
              try {
                if (boardRoomEvent) {
                  await boardRoomEventStore.updateEventAttendance(
                    event._id,
                    'attending',
                    watch('comment')
                  )
                } else {
                  await eventStore.updateEventAttendance(
                    event._id,
                    'attending',
                    watch('comment')
                  )
                }
                reset({ comment: watch('comment') })
              } catch (error) {
                captureException(error)
              }
            }}
          />
          <EventAttendanceButton
            label={
              isUpdatingNotAttending
                ? translate('common.eventActions.attendance.updateComment')
                : translate('common.eventActions.attendance.notAttending')
            }
            icon={IconChoices.CROSS}
            iconColor={theme.colors.red}
            active={userNotAttending}
            backgroundColorClassName="red"
            loading={loading}
            disabled={disableNotAttendingButton()}
            onClick={async () => {
              try {
                if (boardRoomEvent) {
                  await boardRoomEventStore.updateEventAttendance(
                    event._id,
                    'not_attending',
                    watch('comment')
                  )
                } else {
                  await eventStore.updateEventAttendance(
                    event._id,
                    'not_attending',
                    watch('comment')
                  )
                }
                reset({ comment: watch('comment') })
              } catch (error) {
                captureException(error)
              }
            }}
          />
        </div>
      </div>
    )
  }
)
